<template>
  <div>
    <iframe
      class="bilibili"
      src="https://player.bilibili.com/player.html?aid=927816456&cid=255392170&page=1&danmaku=0"
      allowfullscreen="allowfullscreen"
      width="100%"    
      scrolling="no"
      frameborder="0"
      sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bilibili {
  width: 100vw;
  height: 100vh;
  margin-top: 50px;
}
</style>