<template>
  <div class="register_wrap">
    <div>用户名</div>
    <el-input v-model="user_name"></el-input>
    <div style="margin-top: 20px">密码</div>
    <el-input v-model="password" type="password"></el-input>
    <div style="margin-top: 20px">确认密码</div>
    <el-input v-model="password_confirm" type="password"></el-input>
    <div style="margin-top: 20px">验证码</div>
    <el-input v-model="captcha"></el-input>
    <img
      class="captcha"
      :src="captchaImg"
      @click="getCaptcha()"
      alt="验证码加载中.."
    />
    <div class="controls_wrap">
      <el-button type="primary" style="width: 100%" @click="register"
        >注册</el-button
      >
    </div>
  </div>
</template>

<script>
import API from "../../API";
export default {
  data() {
    return {
      user_name: "",
      password: "",
      captchaImg: "",
      captcha: "",
      password_confirm: "",
    };
  },
  methods: {
    getCaptcha() {
      this.$GET(API.user.validation, {})
        .then((res) => {
          if (res.data.status == "ok") {
            this.captchaImg = res.data.base64Img;
          } else {
            throw new Error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    async register() {
      try {
        if (this.password == '') {
            throw new Error('密码未填写')
        }
        if (this.user_name == '') {
            throw new Error('用户名未填写')
        }
        if (this.captcha == '') {
            throw new Error('验证码未填写')
        }
        if (this.password_confirm != this.password) {
            throw new Error('两次输入的密码不一致')
        }
        let _this = this;
        let res = await this.$POST(API.user.register, {
          user_name: this.user_name,
          password: this.password,
          captcha: this.captcha,
        });
        if (res.data.status == "ok") {
            this.$message({
            type: 'success',
             message: '注册成功！',
            });
          this.$cookies.set("token", res.data.token);               
          setTimeout(() => {
              _this.$window.location.reload();
          }, 1000);
        } else {
          throw new Error(res.data.msg);
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: error.message,
        });
      }
    },
  },
  mounted() {
    this.getCaptcha();
  },
};
</script>

<style lang="less" scoped>
.register_wrap {
  width: 100%;
  .captcha {
    margin-top: 10px;
    height: 25px;
  }
  .controls_wrap {
    margin-top: 20px;
    width: 100%;
  }
}
</style>