<template>
  <div v-loading="loading" class="pay_wrap">
    <div class="section_title">订单信息</div>
    <PayOrderSingleDetail v-if="orderInfo" :orderInfo="orderInfo">
    </PayOrderSingleDetail>
    <PayOrderSubscribeDetail
      v-if="subscribeInfo"
      :subscribeInfo="subscribeInfo"
    >
    </PayOrderSubscribeDetail>
    <div class="price">合计：¥{{ price }}</div>
    <div class="section_title">支付方式</div>
    <div class="pay_method_wrap">
      <el-radio
        class="pay_method_item"
        size="medium"
        v-model="payMethod"
        :label="D.ORDER.PAY_METHOD.ALIPAY.ID"
        border
        ><img class="pay_method_logo" src="/images/alipay.png" alt=""
      /></el-radio>
      <el-radio
        class="pay_method_item"
        size="medium"
        v-model="payMethod"
        :label="D.ORDER.PAY_METHOD.WECHAT.ID"
        border
        ><img class="pay_method_logo" src="/images/wechatpay.png" alt=""
      /></el-radio>
    </div>
    <!-- <div class="qrcode_wrap" v-if="!isMobileSafari && payMethod == D.ORDER.PAY_METHOD.WECHAT.ID">
      <img class="qrcode" src="/images/personal/udid.png" alt="">
   </div> -->
    <div v-if="isWechatNative" style="text-align: center">
      <img
        :src="qrcode"
        alt=""
      />
    </div>
    <div class="pay_section">
      <a v-if="!isWechatNative" :href="payHref"
        ><el-button class="pay_button" type="success">支付</el-button></a
      >
      <el-button v-if="isWechatNative" class="pay_button" type="success" @click="wechatNativePay">支付</el-button>
    </div>
    <span>提示：付款完成后请刷新页面。</span>
  </div>
</template>

<script>
import API from "../../../API";
import PayOrderSingleDetail from "./PayOrderSigleDetail";
import PayOrderSubscribeDetail from "./PayOrderSubscribeDetail";
export default {
  name: "PayOrder",
  props: ["info_id", "type"],
  inject: ["D", "C", "isMobileSafari"],
  components: {
    PayOrderSingleDetail,
    PayOrderSubscribeDetail,
  },
  data() {
    return {
      loading: true,
      payMethod: this.D.ORDER.PAY_METHOD.ALIPAY.ID,
      price: 0,
      subscribeInfo: false,
      orderInfo: false,
      updateInfo: false,
      qrcode: "",
    };
  },
  computed: {
    payHref() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        return `${this.C.API_DOMAIN}${API.order.payOrder.fullPath}?method=${
          this.payMethod
        }&order_id=${this.info_id}&tradeType=${
          this.D.ORDER.WECHAT_TRADE_TYPE.MWEB
        }&token=${this.$cookies.get("token")}`;
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        return `${this.C.API_DOMAIN}${
          API.subscribe.paySubscribe.fullPath
        }?method=${this.payMethod}&subscribe_id=${this.info_id}&tradeType=${
          this.D.ORDER.WECHAT_TRADE_TYPE.MWEB
        }&token=${this.$cookies.get("token")}`;
      }
      return "/";
    },
    isWechatNative() {
      if (this.payMethod != this.D.ORDER.PAY_METHOD.WECHAT.ID) {
        return false
      }
      let userAgent = navigator.userAgent;
      if (
        userAgent.indexOf("MicroMessenger") >= 0 ||
        userAgent.indexOf("QQ") >= 0 ||
        userAgent.indexOf("Mobile") < 0
      ) {
        let size = document.body.clientWidth;
        if (size > 550) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    wechatNativePay() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.payOrderWechat();
      } else {
        this.paySubscribeWechat();
      }
    },
    payOrderWechat() {
      this.$GET(API.order.payOrder, {
        method: this.payMethod,
        order_id: this.info_id,
        tradeType:  this.D.ORDER.WECHAT_TRADE_TYPE.NATIVE,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.qrcode = res.data.qr_code;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    paySubscribeWechat() {
      this.$GET(API.subscribe.paySubscribe, {
        method: this.payMethod,
        subscribe_id: this.info_id,
        tradeType: this.D.ORDER.WECHAT_TRADE_TYPE.NATIVE,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.qrcode = res.data.qr_code;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getOrderInfo() {
      this.$GET(API.order.orderDetailUser, { order_id: this.info_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.orderInfo = res.data.data;
          this.price = this.orderInfo.price;
          this.loading = false;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getSubscribeInfo() {
      this.$GET(API.subscribe.getSubscribeInfo, { subscribe_id: this.info_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.subscribeInfo = res.data.data;
          if (
            this.subscribeInfo.status_list[0].status !=
            this.D.ORDER.STATUS.WAIT_FOR_PAY.ID
          ) {
            throw new Error("订单状态不正确");
          }
          this.price = this.subscribeInfo.status_list[0].info.price;
          this.loading = false;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
      this.getOrderInfo();
    } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
      this.getSubscribeInfo();
    }
  },
};
</script>

<style lang="less" scoped>
.pay_wrap {
  .section_title {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 10px;
  }

  .price {
    margin-top: 10px;
    width: 100%;
    text-align: right;
    font-size: 16px;
  }

  .pay_method_wrap {
    display: flex;
    flex-direction: row;
    position: relative;
    .pay_method_item {
      height: 53px;
      width: 160px;
      margin: 0px 10px 0 0;
      padding: 10px !important;
      display: flex;
      align-items: center;
    }
    .pay_method_logo {
      height: 30px;
    }
  }
  .qrcode_wrap {
    text-align: center;
    padding: 20px;
    .qrcode {
      width: 150px;
    }
  }

  .pay_section {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 550px) {
  .pay_wrap {
    .pay_method_wrap {
      display: flex;
      flex-direction: column;
      .pay_method_item {
        height: 53px;
        width: 100%;
        margin: 10px 0 0 0;
      }
      .pay_method_logo {
        height: 30px;
      }
    }

    .pay_section {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
</style>
