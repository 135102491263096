<template>
  <div v-if="orderInfo || subscribeInfo" class="order_detail_wrap">
    <div class="option_panel">
      <el-button
        class="option_item"
        type="success"
        size="normal"
        slot="reference"
        v-if="buttonVisible('DONE')"
        @click="confirmOrderDialogVisible = true"
        ><i class="el-icon-goods"></i>确认收货</el-button
      >

      <a
        class="option_item"
        :href="installPlistPath"
        v-if="buttonVisible('INSTALL') || isAdmin"
      >
        <el-button style="width: 100%" type="success" size="normal"
          ><i class="el-icon-check"></i> 本机安装</el-button
        >
      </a>

      <el-button
        class="option_item"
        type="primary"
        size="normal"
        v-if="buttonVisible('DOWNLOAD_SIGNED') || isAdmin"
        @click="handleDownload"
        ><i class="el-icon-download"></i> 下载已签名IPA</el-button
      >

      <el-button
        class="option_item"
        type="normal"
        size="normal"
        slot="reference"
        v-if="buttonVisible('REQUEST_FOR_REFUND')"
        @click="reportProblemVisible = true"
        ><i class="el-icon-chat-dot-round"></i> 报告问题</el-button
      >

      <el-button
        class="option_item"
        type="danger"
        size="normal"
        @click="cancelDialogVisible = true"
        v-if="buttonVisible('CANCELD')"
        ><i class="el-icon-close"></i> 取消订单</el-button
      >

      <el-button
        class="option_item"
        type="primary"
        size="normal"
        @click="handleCancelRefund"
        v-if="buttonVisible('CANCEL_REFUND')"
        >取消退款</el-button
      >

      <el-button
        class="option_item"
        type="success"
        size="normal"
        @click="handlePay"
        v-if="buttonVisible('WAIT_FOR_PICK')"
        >支付</el-button
      >
    </div>
    <el-dialog
      :append-to-body="true"
      title="支付订单"
      :visible.sync="payDialogVisible"
      custom-class="pay_dialog"
      @exit="bindEmailVisible = false"
    >
      <PayOrder
        v-if="payDialogVisible"
        :info_id="pay_id"
        :type="type"
      ></PayOrder>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="confirmOrderDialogVisible"
      custom-class="confirm_order_dialog"
      :modal="false"
    >
      <div class="confirm_order_wrap">
        <i style="color: #67c23a; font-size: 30px" class="el-icon-success"></i>
        <span style="font-size: 14px; line-height: 14px"
          >确认收货后签名费用将直接打给开发者</span
        >
      </div>
      <div class="confirm_button_wrap">
        <el-button
          type="normal"
          size="small"
          @click="confirmOrderDialogVisible = false"
          >取消</el-button
        >
        <el-button type="success" size="small" @click="handleConfirm"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="我取消订单的原因是.."
      :visible.sync="cancelDialogVisible"
      custom-class="order_cancel_dialog"
      :modal="false"
    >
      <div class="cancel_reason_option_wrap">
        <el-radio v-model="reason" label="选错IPA">选错IPA</el-radio>
        <el-radio v-model="reason" label="选错开发者">选错开发者</el-radio>
        <el-radio v-model="reason" label="不想等了">不想等了</el-radio>
      </div>
      <el-input type="text" placeholder="取消原因" v-model="reason"></el-input>
      <div class="cancel_option_wrap">
        <el-button type="normal" @click="cancelDialogVisible = false"
          >关闭</el-button
        >
        <el-button type="danger" @click="handleCancel">取消订单</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="我想.."
      :visible.sync="reportProblemVisible"
      custom-class="report_problem_dialog"
      :modal="false"
    >
      <el-radio
        v-if="status != D.ORDER.STATUS.WAIT_FOR_PICK.ID"
        v-model="problem"
        label="resign"
        >重新签名（我无法安装）</el-radio
      >
      <el-radio style="margin-top: 10px" v-model="problem" label="refund"
        >退款（管理员将审核您的请求）</el-radio
      >
      <div v-if="problem == 'refund'" class="refund_reason_wrap">
        退款原因
        <el-input
          type="text"
          :placeholder="placeHolderRefund"
          v-model="reason"
        ></el-input>
      </div>

      <div class="help-wrap" v-if="status != D.ORDER.STATUS.WAIT_FOR_PICK.ID">
        <b>开发者已签名，您是否遇到安装问题？</b>
        <br>
        <br>
        <a href="https://support.isign.ren/index.php/archives/99/">
          <el-button type="success">为我解决安装问题</el-button>
        </a>
      </div>

      <div class="cancel_option_wrap">
        <el-button type="normal" @click="reportProblemVisible = false"
          >关闭</el-button
        >
        <el-button type="primary" @click="handleReportProblem">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="请处理以下订单"
      :visible.sync="refundErrorVisible"
      custom-class="handle_refund_dialog"
      :modal="false"
    >
      <div>
        此订单为此设备的开发者支付了“有效期费用”，以下订单的签名优惠依赖本订单。若要继续申请本订单的退款，请先处理以下订单的退款。
      </div>
      <div class="handle_refund_container">
        <div class="order_container" v-if="refundSubscribeList.length > 0">
          <div class="title">应用商店订单</div>
          <div
            class="item"
            v-for="(subscribeInfo, index) in refundSubscribeList"
            :key="index"
          >
            <img :src="subscribeInfo.app_info.logo" alt="" />
            <span>{{ subscribeInfo.app_info.name }}</span>
            <el-button
              size="small"
              type="danger"
              @click="refundSubscribeDepend(subscribeInfo._id)"
              >退款</el-button
            >
          </div>
        </div>
        <div class="order_container" v-if="refundOrderList.length > 0">
          <div class="title">定制签名订单</div>
          <div
            class="item"
            v-for="(orderInfo, index) in refundOrderList"
            :key="index"
          >
            <img src="/images/logo.png" alt="" />
            <span v-if="orderInfo.resource_info.file_name">{{
              orderInfo.resource_info.file_name
            }}</span>
            <span v-else>失效资源</span>
            <el-button
              size="small"
              type="danger"
              @click="refundOrderDepend(orderInfo._id)"
              >退款</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import PayOrder from "../general/Pay/PayOrder";
export default {
  name: "UserOrderControls",
  props: ["orderInfo", "subscribeInfo", "type", "isAdmin"],
  components: { PayOrder },
  inject: ["D"],
  data() {
    return {
      payDialogVisible: false,
      cancelDialogVisible: false,
      confirmOrderDialogVisible: false,
      reportProblemVisible: false,
      refundErrorVisible: false,
      reason: "",
      problem: "要求开发者重新签名",
      pay_id: "",
      refundOrderList: [],
      refundSubscribeList: [],
    };
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    buttonVisible(next) {
      let status = "",
        statusList = [];
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        status = this.orderInfo.status;
        statusList = this.orderInfo.status_list;
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        status = this.subscribeInfo.status;
        statusList = this.subscribeInfo.status_list;
      } else {
        return false;
      }

      if (
        status == this.D.ORDER.STATUS.REQUEST_FOR_REFUND.ID &&
        next == "CANCEL_REFUND"
      ) {
        if (statusList[statusList.length - 1].info.reason == "开发者无法接单") {
          return false;
        }
        return true;
      }

      let list = this.D.ORDER.STATUS_ALLOW_LIST[status];
      if (!list || list.length == 0) {
        return false;
      }
      for (let allow of list) {
        if (allow == next) {
          return true;
        }
      }
      return false;
    },
    refundOrderDepend(orderId) {
      this.$POST(API.order.userRequestRefund, {
        order_id: orderId,
        reason: "退款优惠订单",
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          if (res.data.canRefund) {
            this.handleRefund();
          } else {
            this.$message({
              type: "error",
              message: "请在用户群联系站长",
            });
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    refundSubscribeDepend(subscribeId) {
      this.$POST(API.subscribe.userRequestRefund, {
        subscribe_id: subscribeId,
        reason: "退款优惠订单",
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          if (res.data.canRefund) {
            this.handleRefund();
          } else {
            this.$message({
              type: "error",
              message: "请在用户群联系站长",
            });
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    //general
    handlePay() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.pay_id = this.orderInfo._id;
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.pay_id = this.subscribeInfo._id;
      }
      this.payDialogVisible = true;
    },
    handleDownload() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.$downloadIpa(
          this.orderInfo.signed_resource_id,
          `[isign]${this.orderInfo.resource_info.file_name}`
        );
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.$downloadIpa(
          this.subscribeInfo.signed_resource_id,
          `[isign]${this.subscribeInfo.version_info.version}.ipa`
        );
      }
    },
    //install
    handleConfirm() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.confirmOrder();
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.confirmSubscribe();
      }
    },
    handleReportProblem() {
      if (this.problem == "refund") {
        this.handleRefund();
      } else if (this.problem == "resign") {
        this.handleResign();
      }
    },
    handleRefund() {
      if (this.reason == "") {
        this.reason = this.placeHolderRefund;
      }
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.refundOrder();
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.refundSubscribe();
      }
    },
    handleCancelRefund() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.cancelRefundOrder();
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.cancelRefundSubscribe();
      }
    },
    handleResign() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.resignOrder();
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.resignSubscribe();
      }
    },
    handleCancel() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.cancelOrder();
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.cancelSubscribe();
      }
    },

    //subscribe
    confirmSubscribe() {
      this.$POST(API.subscribe.userConfirmSubscribe, {
        subscribe_id: this.subscribeInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "已确认收货",
          });
          this.confirmOrderDialogVisible = false;
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    refundSubscribe() {
      this.$POST(API.subscribe.userRequestRefund, {
        subscribe_id: this.subscribeInfo._id,
        reason: this.reason,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.reportProblemVisible = false;
          if (res.data.canRefund) {
            this.reportProblemVisible = false;
            this.refundErrorVisible = false;
            this.$message({
              type: "success",
              message: "已提交退款申请",
            });
            this.refresh();
            return;
          }
          this.refundOrderList = res.data.orderList;
          this.refundSubscribeList = res.data.subscribeList;
          this.refundErrorVisible = true;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    cancelRefundSubscribe() {
      this.$POST(API.subscribe.userRequestCancelRefund, {
        subscribe_id: this.subscribeInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.refresh();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    resignSubscribe() {
      this.$POST(API.subscribe.userRequestResign, {
        subscribe_id: this.subscribeInfo._id,
        reason: this.problem,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "已通知开发者重新签名",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    cancelSubscribe() {
      this.$POST(API.subscribe.userCancelSubscribe, {
        subscribe_id: this.subscribeInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "已取消",
          });
          this.$emit("refresh");
          this.cancelDialogVisible = false;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    //order
    confirmOrder() {
      this.$POST(API.order.userConfirm, {
        order_id: this.orderInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "已确认收货",
          });
          this.confirmOrderDialogVisible = false;
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    resignOrder() {
      this.$POST(API.order.userRequestResign, { order_id: this.orderInfo._id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "已通知开发者重新签名",
          });
          this.reportProblemVisible = false;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    refundOrder() {
      this.$POST(API.order.userRequestRefund, {
        order_id: this.orderInfo._id,
        reason: this.reason,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }

          if (res.data.canRefund) {
            this.reportProblemVisible = false;
            this.refundErrorVisible = false;
            this.$message({
              type: "success",
              message: "管理员将处理您的退款申请",
            });
            this.refresh();
            return;
          }
          this.refundOrderList = res.data.orderList;
          this.refundSubscribeList = res.data.subscribeList;
          this.refundErrorVisible = true;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    cancelRefundOrder() {
      this.$POST(API.order.userRequestCancelRefund, {
        order_id: this.orderInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.refresh();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    cancelOrder() {
      if (this.reason == "") {
        this.reason = "无";
      }
      this.$POST(API.order.cancelOrder, {
        order_id: this.orderInfo._id,
        reason: this.reason,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "取消订单成功",
          });
          this.cancelDialogVisible = false;
          this.refresh();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },

  computed: {
    installPlistPath() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        return `itms-services://?action=download-manifest&url=https://www.isign.ren/plist/${this.orderInfo.signed_resource_id}.plist`;
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        return `itms-services://?action=download-manifest&url=https://www.isign.ren/plist/${this.subscribeInfo.signed_resource_id}.plist`;
      }
      return "";
    },
    status() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        return this.orderInfo.status;
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        return this.subscribeInfo.status;
      }
      return "UNKNOWN";
    },
    placeHolderRefund() {
      return this.D.ORDER.STATUS.WAIT_FOR_PICK.ID
        ? "我不想签了"
        : "App 无法安装";
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.help-wrap {
  margin: 20px 0 20px 0px;
  color: red;
}
.confirm_order_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirm_button_wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.cancel_reason_option_wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cancel_option_wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.refund_reason_wrap {
  margin-top: 20px;
}

.handle_refund_container {
  margin-top: 20px;
  .order_container {
    width: 300px;
    .title {
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        box-shadow: 0px 0px 2px gray;
      }
      span {
        width: 160px;
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
}
</style>
