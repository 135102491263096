<template>
  <div class="bind_wechat_wrap">
    <div class="title_wrap">
      <img src="/images/icon64_wx_logo.png" alt="" />
      <div style="margin-top: 20px">登录iSign账号后即可完成绑定</div>
    </div>
    <div class="account_wrap">
      <div>用户名</div>
      <el-input v-model="user_name"></el-input>
      <div style="margin-top: 20px">密码</div>
      <el-input v-model="password" type="password"></el-input>

      <el-button
        style="width: 100%; margin-top: 50px"
        type="success"
        @click="bind"
        >绑定微信</el-button
      >
      <div style="color: red; font-size: 12px;">*请不要使用自动填充，需手动输入用户名和密码（还没找到原因</div>
    </div>
  </div>
</template>
<script>
import API from "../API";
export default {
  name: "BindWechatRedirect",
  computed: {
    CODE() {
      let url = document.URL;
      let urlObj = new URL(url);
      const [a] = urlObj.searchParams.values();
      return a;
    },
  },
  data() {
    return {
      user_name: "",
      password: "",
    };
  },
  methods: {
    bind() {
      this.$POST(API.user.wechatBind, {
        user_name: this.user_name,
        password: this.password,
        code: this.CODE,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$cookies.set("token", res.data.token);
          document.location.href = "/#/user?page=MyInfo";
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.bind_wechat_wrap {
  padding-top: 50px;
  background-color: rgba(255, 255, 255, 0.9);
  .title_wrap {
    text-align: center;
    padding: 50px;
  }
  .account_wrap {
    width: 300px;
    height: 70vh;
    margin: auto;
    font-size: 16px;
  }
}
@media screen and (max-width: 550px) {
}
</style>