<template>
  <div v-if="subscribeInfo" class="subscribe_detail_developer_wrap">
    <SubscribeDetail :subscribeInfo="subscribeInfo" />
    <DeveloperOrderControls
      :subscribeInfo="subscribeInfo"
      :type="'SUBSCRIBE'"
      @refresh="getSubscribeInfo"
    />
  </div>
</template>
<script>
import API from "../../API";
import SubscribeDetail from "../general/SubscribeDetail";
import DeveloperOrderControls from "../general/DeveloperOrderControls";
export default {
  name: "SubscribeDetailDeveloper",
  props: ["subscribe_id"],
  inject: ["D"],
  components: {
    SubscribeDetail,
    DeveloperOrderControls,
  },
  data() {
    return {
      subscribeInfo: false,
    };
  },
  methods: {
    getSubscribeInfo() {
      this.$GET(API.subscribe.getSubscribeInfo, {
        subscribe_id: this.subscribe_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.subscribeInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getSubscribeInfo();
  },
};
</script>
<style lang="less" scoped>
.subscribe_detail_developer_wrap {
}
@media screen and (max-width: 550px) {
}
</style>