<template>
  <div class="refund_list_wrap">
    <div class="title-1">退款列表</div>
    <el-tabs v-model="active" @tab-click="refreshData">
      <el-tab-pane label="应用商店" name="subscribe"></el-tab-pane>
      <el-tab-pane label="单次" name="single"></el-tab-pane>
    </el-tabs>
    <div class="order-search-wrap">
      <el-input v-model="searchOrderId" placeholder="order Id"></el-input>
      <el-button type="primary" size="mini" @click="search">搜索</el-button>
      <el-button type="normal" size="mini" @click="getSubscribeRefundList"
        >清除搜索</el-button
      >
    </div>
    <el-table :data="list" style="width: 950px" max-height="400px">
      <el-table-column label="订单号" width="100">
        <template slot-scope="scope">
          {{ scope.row._id }}
        </template>
      </el-table-column>
      <el-table-column v-if="active == 'subscribe'" label="App" width="100">
        <template slot-scope="scope">
          {{ scope.row.appInfo.name }}
        </template>
      </el-table-column>

      <el-table-column label="退款金额" width="80">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>
      <el-table-column label="有效期金额" width="80">
        <template slot-scope="scope">
          {{ getDeveloperTimePrice(scope.row.busInfo) }}
        </template>
      </el-table-column>
      <el-table-column label="申请时间" width="120">
        <template slot-scope="scope">
          {{ scope.row.time | timeFilter }}
        </template>
      </el-table-column>
      <el-table-column label="用户" width="120">
        <template slot-scope="scope">
          {{ scope.row.userInfo.user_name }}
          ({{ scope.row.userInfo._id }})
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="openOrderDetail(scope.row)"
            >详情</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="handleOrderRefund(scope.row, 'true')"
            >退款完成</el-button
          >
          <el-button size="mini" type="success" @click="autoRefund(scope.row)">
            自动退款
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleOrderRefund(scope.row, 'false')"
            >拒绝</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="count"
      :page-size="size"
      :current-page="page"
      @current-change="pageChanged"
    >
    </el-pagination>
    <el-dialog
      title="订单详情"
      :visible.sync="singleOrderDetailVisible"
      custom-class="order_detail_dialog"
      :modal="false"
    >
      <OrderDetailAdmin v-if="singleOrderDetailVisible" :order_id="order_id">
      </OrderDetailAdmin>
    </el-dialog>
    <el-dialog
      title="订单详情"
      :visible.sync="subscribeDetailVisible"
      custom-class="order_detail_dialog"
      :modal="false"
    >
      <SubscribeDetailAdmin
        v-if="subscribeDetailVisible"
        :subscribeInfo="subscribeInfo"
      />
    </el-dialog>
  </div>
</template>
<script>
import API from "../../API";
import OrderDetailAdmin from "../AdminPage/OrderDetailAdmin";
import SubscribeDetailAdmin from "../AdminPage/SubscribeDetailAdmin";
export default {
  name: "RefundList",
  components: {
    OrderDetailAdmin,
    SubscribeDetailAdmin,
  },
  data() {
    return {
      page: 1,
      size: 20,
      count: 0,
      list: [],
      active: "subscribe",
      singleOrderDetailVisible: false,
      subscribeDetailVisible: false,
      order_id: false,
      subscribeInfo: false,
      searchOrderId: "",
      now: Date.parse(new Date()),
    };
  },
  methods: {
    getDeveloperTimePrice(busInfo) {
      let time = (busInfo.expire_time - this.now) / 86400000;
      return (time * 0.1).toFixed(2);
    },
    getOrderList() {
      this.$GET(API.order.adminGetRefundList, {
        page: this.page,
        size: this.size,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
          this.count = res.data.count;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getSubscribeRefundList() {
      this.searchOrderId = "";
      this.$GET(API.subscribe.adminGetSubscribeRefundList, {
        page: this.page,
        size: this.size,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
          this.count = res.data.count;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    search() {
      this.$GET(API.subscribe.adminSearchSubscribe, {
        order_id: this.searchOrderId,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
          this.count = res.data.count;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    subscribeRefund(subscribe_id, confirm, reason) {
      this.$POST(API.subscribe.adminHandelRefund, {
        subscribe_id: subscribe_id,
        confirm: confirm,
        reason: reason,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
          this.getSubscribeRefundList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    orderRefund(order_id, confirm, reason) {
      this.$POST(API.order.adminHandelRefund, {
        order_id: order_id,
        confirm: confirm,
        reason: reason,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
          this.getOrderList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    autoRefund(row) {
      let subscribe_id = row._id;
      this.$POST(API.subscribe.refund, { subscribe_id: subscribe_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
          this.refreshData();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    handleOrderRefund(row, confirm) {
      let reason = "";
      if (confirm == "true") {
        reason = "确认退款";
        if (this.active == "single") {
          this.orderRefund(row._id, confirm, reason);
        } else {
          this.subscribeRefund(row._id, confirm, reason);
        }
      } else {
        this.$prompt("不通过理由", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then((action) => {
            if (this.active == "single") {
              this.orderRefund(row._id, confirm, action.value);
            } else {
              this.subscribeRefund(row._id, confirm, action.value);
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      }
    },

    openOrderDetail(row) {
      this.order_id = row._id;
      if (this.active == "single") {
        this.singleOrderDetailVisible = true;
      } else {
        this.subscribeDetailVisible = true;
        this.subscribeInfo = row;
      }
    },
    refreshData() {
      if (this.active == "single") {
        this.getOrderList();
      } else {
        this.getSubscribeRefundList();
      }
    },
    pageChanged(page) {
      this.page = page;
      this.refreshData();
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>
<style lang="less" scoped>
.refund_list_wrap {
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  .order-search-wrap {
    margin: 10px 0;
    .el-input {
      width: 200px;
      margin-right: 20px;
    }
  }
}
@media screen and (max-width: 550px) {
}
</style>
