<template>
  <div class="my_bus_wrap">
    <div class="title-1">应用商店订单</div>
    <el-button type="success" size="mini" @click="pickAll()"
      >一键接单</el-button
    >
    <el-button
      class="download_udidlist_button"
      type="text"
      @click="getAllUdid()"
      ><i class="el-icon-download"></i> 下载全部 UDID</el-button
    >
    <el-table
      style="margin-top: 10px"
      :data="list"
      :max-height="tableHeight"
      @row-click="busDetail"
    >
      <el-table-column label="" width="50px">
        <template slot-scope="scope">
          <img
            class="app_logo"
            :src="appMap[scope.row.busInfo.app_id].logo"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column label="App" width="120px">
        <template slot-scope="scope">
          {{ appMap[scope.row.busInfo.app_id].name }}
        </template>
      </el-table-column>
      <el-table-column label="等待接单" width="80px">
        <template slot-scope="scope">
          {{ scope.row.waitForPickCount }}
        </template>
      </el-table-column>
      <el-table-column label="待签" width="80px">
        <template slot-scope="scope">
          <span
            :style="{
              color: scope.row.signingCount > 0 ? 'red' : 'black',
              fontWeight: 600,
            }"
          >
            {{ scope.row.signingCount }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="到期时间" width="130p">
        <template slot-scope="scope">
          {{ scope.row.busInfo.expire_time | timeFilter }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import API from "../../../API";
export default {
  name: "MyBus",
  components: {},
  inject: ["userInfo", "appMap"],
  data() {
    return {
      addBusVisible: false,
      list: [],
      detail: {},
    };
  },
  computed: {
    tableHeight() {
      let width = document.body.clientWidth;
      if (width > 550) {
        return 500;
      } else {
        return document.body.clientHeight - 315;
      }
    },
  },
  methods: {
    busDetail(row) {
      this.$router.push(`/bus?bus_id=${row.busInfo._id}`);
    },
    getBusList() {
      this.$GET(API.subscribe.getDeveloperBusList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    createBusComplete() {
      this.addBusVisible = false;
      this.getBusList();
    },
    pickAll() {
      this.$GET(API.subscribe.developerPickAll, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: `已接 ${res.data.count} 单`,
          });
          this.getBusList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getAllUdid() {
      this.$GET(API.subscribe.developerDownloadAllUdid, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          let udidList = res.data.udidList;
          this.downloadUdidList(udidList);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    downloadUdidList(udidList) {
      var element = document.createElement("a");
      var message = "Device ID	Device Name	Device Platform\n";
      for (let udid of udidList) {
        let platform = "ios";
        message += `${udid}	${udid}	${platform}\n`;
      }
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(message)
      );
      element.setAttribute("download", "UDID列表");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
  mounted() {
    this.getBusList();
  },
};
</script>

<style lang="less" scoped>
.my_bus_wrap {
  height: 600px;
  width: 600px;
  padding: 20px;
  text-align: left;
  box-sizing: border-box;
  //   background-color: #000;
  .title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .app_logo {
    width: 30px;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px #dddddd;
  }
}

@media screen and (max-width: 550px) {
  .my_bus_wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
