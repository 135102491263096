<template>
  <div class="subscribe_list_wrap">
    <div class="title-1">订阅订单列表</div>
    <el-table :data="list" style="width: 850px" height="500px">
      <el-table-column label="用户名" width="200">
        <template slot-scope="scope">
          <i class="el-icon-user"></i>
          <span style="margin-left: 5px"
            >{{ scope.row.userInfo.user_name }} <br />
            ({{ scope.row.userInfo._id }})</span
          >
        </template>
      </el-table-column>
      <el-table-column label="当前状态" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            getCurrentStatus(scope.row)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.subscribeInfo.status_list[0].time | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="App（开发者）" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.busInfo.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="openDetail(scope.row)">查看详情</el-button>
          <el-button
            size="mini"
            type="success"
            @click="handleFakePay(scope.row)"
            >Fake Pay</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="count"
      :page-size="size"
      :current-page="page"
      @current-change="pageChanged"
    >
    </el-pagination>
    <el-dialog
      title="订单详情"
      :visible.sync="subscribeDetailVisible"
      custom-class="order_detail_dialog"
      :modal="false"
    >
      <SubscribeDetailAdmin
        v-if="subscribeDetailVisible"
        :subscribeInfo="subscribeInfo"
      />
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import SubscribeDetailAdmin from "../AdminPage/SubscribeDetailAdmin";
export default {
  name: "SubscribeList",
  components: {
    SubscribeDetailAdmin,
  },
  data() {
    return {
      page: 1,
      size: 20,
      count: 0,
      list: [],
      subscribeDetailVisible: false,
      subscribeInfo: false
    };
  },
  methods: {
    openDetail(row) {
      this.subscribeInfo = row.subscribeInfo;
      this.subscribeDetailVisible = true;
    },
    getCurrentStatus(row) {
      let status_list = row.subscribeInfo.status_list;
      return status_list[status_list.length - 1].status;
    },
    pageChanged(page) {
      this.page = page;
      this.getSubscribeList();
    },
    handleFakePay(row) {
      this.$POST(API.subscribe.adminFakePaySubscribe, {
        subscribe_id: row.subscribeInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
          this.getSubscribeList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getSubscribeList() {
      this.$GET(API.subscribe.adminGetAllSubscribeList, {
        page: this.page,
        size: this.size,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
          this.count = res.data.count;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getSubscribeList();
  },
};
</script>

<style lang="less" scoped>
.subscribe_list_wrap {
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
}
</style>
