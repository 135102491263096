<template>
  <div class="my_device_wrap">
    <div class="title-1">我的设备</div>
    <el-button
      style="margin-bottom: 10px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="addDevice"
      >新增</el-button
    >
    <el-table
      :data="device_list"
      :max-height="tableHeight"
      @row-click="editDevice"
    >
      <el-table-column label="设备名称" width="180px">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="型号" width="200px">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.product_id | productName
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="addDeviceDialogTitle"
      :visible.sync="addDeviceVisible"
      custom-class="add_device_dialog"
    >
      <AddDevice :deviceInfo="editingDeviceInfo" @exit="handleAddComplete" />
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import AddDevice from "./AddDevice";
export default {
  name: "MyDevices",
  components: {
    AddDevice,
  },
  computed: {
    tableHeight() {
      let width = document.body.clientWidth
      if (width > 550) {
        return 500
      } else {
        return document.body.clientHeight - 280
      }
    }
  },
  data() {
    return {
      addDeviceDialogTitle: "新增设备",
      addDeviceVisible: false,
      device_list: [],
      product_list: [],
      editingDeviceInfo: null,
    };
  },
  methods: {
    addDevice() {
      this.addDeviceDialogTitle = "新增设备";
      this.editingDeviceInfo = null;
      this.addDeviceVisible = true;
    },
    editDevice(row) {
      this.editingDeviceInfo = {
        udid: row.udid,
        name: row.name,
        product_id: row.product_id,
      };
      this.addDeviceDialogTitle = "编辑设备";
      this.addDeviceVisible = true;
    },
    handleAddComplete() {
      this.getDeviceList();
      this.addDeviceVisible = false;
      this.$message({
        type: "success",
        message: "更新成功",
      });
    },
    getDeviceList() {
      this.$GET(API.user.userGetDeviceList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.device_list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    handleDelete(index) {
      this.$confirm("将删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "rename_dialog",
      })
        .then(() => {
          this.udid_list.splice(index, 1);
          this.$POST(API.user.updateUdidList, {
            udid_list: this.udid_list,
          }).then((res) => {
            console.log(res.data);
          });
        })
        .catch((error) => {
          if (error != "cancel") {
            this.$message({
              type: "error",
              message: error.message,
            });
          }
        });
    },
  },
  mounted() {
    this.getDeviceList();
    if (this.$route.query.subwindow == "addDevice") {
      this.addDeviceVisible = true;
    }
  },
};
</script>

<style lang="less" scoped>
.my_device_wrap {
  width: 600px;
  height: 600px;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
}

@media screen and (max-width: 550px) {
  .my_device_wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>