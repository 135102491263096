<template>
  <div v-if="appInfo" class="outter_app_info_wrap">
    <div class="title-1">{{ appInfo.appName }}</div>
    <div class="content_wrap">
      <div class="base_info_wrap">
        <div class="title-2">基本信息</div>
        <div class="info_wrap">
          <div class="info_title">AppId</div>
          <div class="under_line">
            <div>{{ appInfo._id }}</div>
          </div>
        </div>
        <div class="info_wrap">
          <div class="info_title">AppSecret</div>
          <el-input
            v-model="appInfo.appSecret"
            placeholder="appSecret"
          ></el-input>
        </div>
        <div class="info_wrap">
          <div class="info_title">上线</div>
          <el-switch
            v-model="appInfo.online"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </div>
        <div class="info_wrap">
          <div class="info_title">当前IPA</div>
          <el-button
            class="option_item"
            type="primary"
            size="normal"
            :disabled="!appInfo.resourceId"
            @click="handleDownload"
            ><i class="el-icon-download"></i> 下载已签名IPA</el-button
          >
        </div>
        <div class="info_wrap">
          <div class="info_title">更新IPA</div>
          <AddIPA @uploadComplete="fileUploadComplete"></AddIPA>
        </div>
        <div class="option_panel">
          <el-button class="option_item" type="danger" @click="deleteApp"
            >删除App</el-button
          >
          <el-button class="option_item" type="success" @click="updateAppInfo"
            >更新信息</el-button
          >
        </div>
      </div>
      <div class="stats_wrap">
        <div class="title-2">订单概览</div>
        <el-table style="margin-top: 10px" :data="statsList" max-height="450px">
          <el-table-column label="状态" width="100px">
            <template slot-scope="scope">
              {{ scope.row._id.status }}
            </template>
          </el-table-column>
          <el-table-column label="数量" width="100px">
            <template slot-scope="scope">
              {{ scope.row.count }}
            </template>
          </el-table-column>
          <el-table-column label="金额" width="100px">
            <template slot-scope="scope">
              {{ (scope.row.price / 100).toFixed(2) }}
            </template>
          </el-table-column>
        </el-table>
        <el-divider></el-divider>
        <div class="title-2">月度账单</div>
        <div class="info_wrap">
          <div class="info_title">等待结算</div>
          <div class="under_line bill_item">
            <span>¥ {{ outterUnpaid }}</span>
            <el-button
              type="primary"
              size="small"
              style="float: right"
              @click="payDeveloper()"
              >结算</el-button
            >
            <el-button
              type="success"
              size="small"
              style="float: right; margin-right: 20px"
              @click="billDialogVisible = true"
              >生成账单</el-button
            >
          </div>
        </div>
        <div class="info_wrap">
          <div class="info_title">已收款</div>
          <div class="under_line bill_item">¥ {{ paid.total }}</div>
        </div>
        <div class="info_wrap">
          <div class="info_title">已支付开发者</div>
          <div class="under_line bill_item">¥ {{ paid.developer }}</div>
        </div>
        <div class="info_wrap">
          <div class="info_title">盈利</div>
          <div class="under_line bill_item">¥ {{ paid.isign }}</div>
        </div>
      </div>
    </div>
    <el-dialog
      title="生成账单"
      :visible.sync="billDialogVisible"
      :modal="false"
    >
      <div>开始日期</div>
      <el-date-picker
        v-model="billStart"
        type="datetime"
        placeholder="选择日期时间"
        value-format="timestamp"
      >
      </el-date-picker>
      <div>结束日期</div>
      <el-date-picker
        v-model="billEnd"
        type="datetime"
        placeholder="选择日期时间"
        value-format="timestamp"
      >
      </el-date-picker>
      <div>
        <el-button type="primary" @click="genBill()">下载账单</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "../../API";
import AddIPA from "../general/AddIPA.vue";
export default {
  name: "OutterAppInfo",
  components: {
    AddIPA,
  },
  computed: {
    appId() {
      return this.$route.query.appId;
    },
    outterUnpaid() {
      let sum = 0;
      for (let statsInfo of this.statsList) {
        if (statsInfo._id.status == "SIGNED") {
          sum += statsInfo.price;
        }
      }
      return (sum / 100).toFixed(2);
    },
    paid() {
      let sum = 0;
      for (let statsInfo of this.statsList) {
        if (statsInfo._id.status == "DONE") {
          sum += statsInfo.price;
        }
      }
      return {
        total: (sum / 100).toFixed(2),
        developer: ((sum * 0.7) / 100).toFixed(2),
        isign: ((sum * 0.3) / 100).toFixed(2),
      };
    },
  },
  data() {
    return {
      appInfo: false,
      statsList: [],
      billDialogVisible: false,
      billStart: 0,
      billEnd: 0,
    };
  },
  methods: {
    payDeveloper() {
      this.$POST(API.outter.adminPayOutterOrder, { appId: this.appId })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.getStats();
          this.$message({
            type: "success",
            message: "已结算",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    fileUploadComplete(resource_id) {
      this.appInfo.resourceId = resource_id;
    },
    deleteApp() {
      this.$POST(API.outter.adminDeleteApp, { appId: this.appId })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$router.go(-1);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    updateAppInfo() {
      this.$POST(API.outter.adminUpdateAppInfo, {
        appId: this.appId,
        appName: this.appInfo.appName,
        appSecret: this.appInfo.appSecret,
        resourceId: this.appInfo.resourceId,
        online: this.appInfo.online,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          document.location.reload();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getStats() {
      this.$GET(API.outter.adminGetAppStats, { appId: this.appId })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.statsList = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getAppInfo() {
      this.$GET(API.outter.adminGetAppInfo, { appId: this.appId })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.appInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    handleDownload() {
      this.$downloadIpa(
        this.appInfo.resourceId,
        `[isign]${this.appInfo.appName}-未签名.ipa`
      );
    },
    genBill() {
      this.$POST(API.outter.genBill, {
        appId: this.appId,
        billStart: this.billStart,
        billEnd: this.billEnd,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.downloadBill(res.data.data)
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    downloadBill(bill) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(bill)
      );
      element.setAttribute("download", "账单.csv");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    

  },
  mounted() {
    this.getAppInfo();
    this.getStats();
    let now = Date.parse(new Date());
    this.billStart = now;
    this.billEnd = now;
  },
};
</script>
<style lang="less" scoped>
.outter_app_info_wrap {
  padding: 20px;
  .content_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .base_info_wrap {
      width: 400px;
    }
    .stats_wrap {
      width: 500px;
      margin-left: 60px;
      .bill_item {
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .outter_app_info_wrap {
    padding: 20px;
    .content_wrap {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      .base_info_wrap {
        width: 100%;
      }
      .stats_wrap {
        width: 100%;
        margin-left: 0px;
        margin-top: 20px;
        .bill_item {
          font-size: 20px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>