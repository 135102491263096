<template>
  <div class="outter_app_order_wrap">
    <div class="title-1">订单列表</div>
    <div>过滤</div>
    <el-select v-model="filter" placeholder="请选择">
      <el-option
        v-for="item in filterList"
        :key="item.value"
        :label="item.option"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-button type="success" style="margin-left: 10px" @click="getList"
      >搜索</el-button
    >
    <el-table style="margin-top: 10px" :data="orderList">
      <el-table-column label="创建时间" width="150px">
        <template slot-scope="scope">
          {{ scope.row.statusList[0].time | timeFilter }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80px">
        <template slot-scope="scope">
          {{ scope.row.status }}
        </template>
      </el-table-column>
      <el-table-column label="订单号" width="180px">
        <template slot-scope="scope">
          {{ scope.row._id }}
        </template>
      </el-table-column>
      <el-table-column label="外部订单号" width="180px">
        <template slot-scope="scope">
          {{ scope.row.outterId }}
        </template>
      </el-table-column>
      <el-table-column label="设备/UDID" width="180px">
        <template slot-scope="scope">
          <div>{{ scope.row.deviceType }}</div>
          <div>
            {{ scope.row.udid }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="金额" width="80px">
        <template slot-scope="scope">
          {{ (scope.row.price / 100).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column label="开发者" width="200px">
        <template slot-scope="scope">
          <el-select v-model="scope.row.developerId" placeholder="请选择">
            <el-option
              v-for="item in developerList"
              :key="item.user_id"
              :label="item.user_name"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="已经过去" width="100px">
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.status == 'SIGNING' || scope.row.status == 'TIMEOUT'
            "
            :style="{
              color:
                (Date.parse(new Date()) - scope.row.statusList[0].time >
                  43200000 &&
                  scope.row.status == 'SIGNING') ||
                scope.row.status == 'TIMEOUT'
                  ? 'red'
                  : 'green',
            }"
          >
            {{
              (Date.parse(new Date()) - scope.row.statusList[0].time)
                | durationFilter
            }}
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="transferDeveloper(scope.row)"
            >转单</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="refund(scope.row)"
            >退款</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="size"
      :current-page.sync="page"
      @current-change="getList"
    >
    </el-pagination>
  </div>
</template>
<script>
import API from "../../API";
export default {
  name: "OutterAppOrder",
  data() {
    return {
      developerList: [],
      orderList: [],
      typeFilter: "",
      page: 1,
      size: 50,
      total: 0,
      filter: "ALL",
      filterList: [
        {
          option: "等待支付",
          value: "PENDING",
        },
        {
          option: "签名中",
          value: "SIGNING",
        },
        {
          option: "超时未签名",
          value: "TIMEOUT",
        },
        {
          option: "已签名",
          value: "SIGNED",
        },
        {
          option: "已完成",
          value: "DONE",
        },
        {
          option: "取消",
          value: "CANCEL",
        },
        {
          option: "退款",
          value: "REFUND",
        },
        {
          option: "全部",
          value: "ALL",
        },
      ],
    };
  },
  computed: {
    appId() {
      return this.$route.query.appId;
    },
  },
  methods: {
    refund(row) {
      this.$POST(API.outter.adminRefund, { orderId: row._id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "已退款",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    transferDeveloper(row) {
      this.$POST(API.outter.adminTransferOrder, {
        orderId: row._id,
        developerId: row.developerId,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "转单成功",
          });
          this.getList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getDeveloperList() {
      this.$GET(API.outter.adminGetDeveloperList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          console.log(res.data.list);
          this.developerList = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getList() {
      this.$GET(API.outter.adminGetAppOrderList, {
        appId: this.appId,
        page: this.page,
        size: this.size,
        filter: this.filter,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.orderList = res.data.list;
          this.total = res.data.total;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getList();
    this.getDeveloperList();
  },
};
</script>
<style lang="less" scoped>
.outter_app_order_wrap {
  padding: 20px;
}
@media screen and (max-width: 550px) {
}
</style>