<template>
  <div class="DetailNavBar">
    <el-page-header @back="goBack" :content="content"> </el-page-header>
  </div>
</template>
<script>
export default {
  name: "DetailNavBar",
  props: ['content'],
  data() {
    return {};
  },
  methods: {
      goBack() {
          this.$router.back();
      }
  }
};
</script>
<style lang="less" scoped>
.DetailNavBar {
    height: 50px;
}
@media screen and (max-width: 550px) {
}
</style>