<template>
  <div class="wrap">
    <div class="info_wrap">
      <div class="info_title">邮箱地址</div>
      <div class="info_content_wrap">
        <div class="left_item">
          <el-input
            type="text"
            v-model="address"
          ></el-input>
        </div>
      </div>
    </div>

    <div class="info_wrap">
      <div class="info_title">图形验证码</div>
      <div class="info_content_wrap">
        <el-input
          type="text"
          v-model="captcha1"
        ></el-input>
        <div class="right_item">
          <img :src="captchaImg" @click="getCaptcha()" alt="" />
        </div>
      </div>
    </div>

    <el-button
      type="text"
      @click="sendEmailClicked()"
      :disabled="buttonDisabled"
      >{{ buttonTitle }}</el-button
    >
    <div v-if="buttonDisabled">
      <div style="margin-top: 10px">验证码</div>
      <el-input
        v-if="buttonDisabled"
        type="text"
        placeholder="请输入邮箱验证码"
        v-model="captcha2"
      ></el-input>
      <el-button type="text" @click="verifyButtonCliced()">绑定邮箱</el-button>
    </div>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "BindEmail",
  data() {
    return {
      address: "",
      captcha1: "",
      captcha2: "",
      buttonDisabled: false,
      buttonTitle: "发送验证码",
      resendCD: 60,
      timer: null,
      captchaImg: "",
    };
  },
  methods: {
    getCaptcha() {
      this.$GET(API.user.validation, {})
        .then((res) => {
          if (res.data.status == "ok") {
            this.captchaImg = res.data.base64Img;
          } else {
            throw new Error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    async sendEmailClicked() {
      try {
        this.address = this.address.trim();
        if (this.captcha1 == "") {
          throw new Error("请输入图形验证码");
        }
        if (!this.$IsValidEmailAddress(this.address)) {
          throw new Error('请填写正确的邮箱地址')
        }
        let res = await this.$POST(API.user.bindEmail, {
          email: this.address,
          captcha: this.captcha1,
        });
        if (res.data.status != "ok") {
          throw new Error("邮件发送失败，" + res.data.msg);
        }
        this.buttonDisabled = true;
        if (this.timer != null) {
          clearInterval(this.timer);
          this.timer = null;
        }
        let that = this;
        this.timer = setInterval(() => {
          that.buttonTitle = `重发${that.resendCD}s`;
          that.resendCD -= 1;
          if (that.resendCD <= 0) {
            clearInterval(that.timer);
            that.timer = null;
            that.buttonDisabled = false;
            that.buttonTitle = "发送激活邮件";
          }
        }, 1000);
        this.$message({
          type: "success",
          message: "邮件已发送，请注意查收。",
        });
      } catch (error) {
        this.$message({
          type: "error",
          message: error.message,
        });
      }
    },
    verifyButtonCliced() {
      this.$POST(API.user.mailActivation, {
        captcha: this.captcha2,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "邮箱绑定成功！",
          });
          this.$emit('exit');
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
  beforeDestroy() {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  },
  mounted() {
    this.getCaptcha();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
}
</style>