<template>
  <div class="app_version_list_wrap">
    {{ appInfo.name }}
    <div class="new_version_wrap">
      <div>
        <div>新增版本</div>
        <el-input
          style="width: 300px; margin: 15px 20px 20px 0"
          type="text"
          v-model="version"
          placeholder="先填版本号"
        ></el-input>
      </div>
      <div class="add_ipa_wrap">
        <div>上传文件</div>
        <AddIPA size="small" @uploadComplete="uploadComplete"></AddIPA>
      </div>
    </div>
    <el-table :data="list" style="width: 850px" max-height="400px">
      <el-table-column label="版本" width="100">
        <template slot-scope="scope">
          {{ scope.row.version }}
        </template>
      </el-table-column>
      <el-table-column label="resourceId" width="140">
        <template slot-scope="scope">
          {{ scope.row.resource_id }}
        </template>
      </el-table-column>
      <el-table-column label="可见" width="60">
        <template slot-scope="scope">
          {{ scope.row.is_visible }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            @click="deleteVersion(scope.row._id)"
            style="margin-right: 20px"
            >删除</el-button
          >
          <el-button type="success" @click="setVisible(scope.row._id, true)" size="mini"
            >设置可见</el-button
          >
          <el-button type="danger" @click="setVisible(scope.row._id, false)" size="mini"
            >设置不可见</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import AddIPA from "../general/AddIPA";
import API from "../../API";
export default {
  name: "AppVersionList",
  props: ["appInfo"],
  components: {
    AddIPA,
  },
  data() {
    return {
      version: "",
      resource_id: "",
      list: [],
    };
  },
  methods: {
    uploadComplete(resource_id) {
      this.$POST(API.general.adminCreateAppVersion, {
        app_id: this.appInfo._id,
        version: this.version,
        resource_id: resource_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.getAppVersionList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    setVisible(version_id, is_visible) {
      this.$POST(API.general.adminSetVersionVisible, {
        version_id: version_id,
        is_visible: is_visible,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.getAppVersionList()
          this.$message({
          type: 'success',
           message: 'ok',
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getAppVersionList() {
      this.$GET(API.general.developerGetAppVersionList, {
        app_id: this.appInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    deleteVersion(version_id) {
      this.$POST(API.general.adminDeleteAppVersion, { version_id: version_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
          this.getAppVersionList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getAppVersionList();
  },
};
</script>

<style lang="less" scoped>
.app_version_list_wrap {
  .new_version_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    .add_ipa_wrap {
      width: 300px;
    }
  }
}
</style>