<template>
  <div class="subscribe_detail_wrap">
    <div v-if="busInfo" class="info_wrap">
      <div class="info_title">App</div>
      <div class="info_content">{{ busInfo.name }}</div>
    </div>
    <div class="info_wrap">
      <div class="info_title">签名设备</div>
      <div class="info_content">
        {{ deviceMap[subscribeInfo.device_id].name }}({{
          deviceMap[subscribeInfo.device_id].udid
        }})
      </div>
    </div>
    <div class="info_wrap">
      <div class="info_title">创建时间</div>
      <div class="info_content">
        {{ subscribeInfo.status_list[0].time | timeFilter }}
      </div>
    </div>
    <div v-if="subscribeInfo.is_init" class="info_wrap">
      <div class="info_title">有效期至</div>
      <div class="info_content">
        {{ subscribeInfo.bus_info.expire_time | timeFilter }}
      </div>
    </div>
    <div v-if="subscribeInfo.is_init" class="info_wrap">
      <div class="info_title">有效期费用</div>
      <div class="info_content">
        ¥{{ subscribeInfo.status_list[0].info.account_fee }}
      </div>
    </div>
    <div v-if="subscribeInfo.is_init" class="info_wrap">
      <div class="info_title">签名费用</div>
      <div class="info_content">
        ¥{{ subscribeInfo.status_list[0].info.sign_fee }}
      </div>
    </div>
    <div v-if="subscribeInfo.is_init" class="info_wrap">
      <div class="info_title">App费用</div>
      <div class="info_content">
        ¥{{ subscribeInfo.status_list[0].info.app_fee }}
      </div>
    </div>
    <div v-if="!subscribeInfo.is_init" class="info_wrap">
      <div class="info_title">更新费用</div>
      <div class="info_content">
        ¥{{ subscribeInfo.status_list[0].info.price }}
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../API";
export default {
  name: "PayOrderSubscribeDetail",
  props: ["subscribeInfo"],
  inject: ["appMap", "deviceMap"],
  data() {
    return {
      busInfo: false,
    };
  },
  methods: {
    getBusInfo() {
      this.$GET(API.subscribe.getBusInfo, { bus_id: this.subscribeInfo.bus_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.busInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    console.log(this.subscribeInfo);
    this.getBusInfo();
  },
};
</script>

<style lang="less" scoped>
.subscribe_detail_wrap {
  border-top: 1px solid #666666;
  border-bottom: 1px solid #666666;
  padding: 10px;
  .info_wrap {
    margin-bottom: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    .info_title {
      font-weight: 600;
      margin-left: 10px;
      width: 100px;
    }
    .info_content {
      text-align: left;
    }
  }
}

@media screen and (max-width: 550px) {
  .subscribe_detail_wrap {
    border-top: 1px solid #666666;
    border-bottom: 1px solid #666666;
    padding: 10px 0 10px 0;
    .info_wrap {
      margin-bottom: 5px;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      text-align: left;
      .info_title {
        font-weight: 600;
        margin: 0 0 5px 0;
        width: 80px;
        
      }
      .info_content {
        text-align: left;
        width: 190px;
      }
    }
  }
}
</style>
