<template>
  <div class="select_developer_wrap">
    <el-table
      style="margin-top: 10px"
      :data="list"
      max-height="450px"
      :loading="loading"
      @row-click="selectDeveloper"
    >
      <el-table-column label="" width="40px">
        <template slot-scope="scope">
          <el-checkbox v-if="scope.row.selected" checked></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="开发者" width="100px">
        <template slot-scope="scope">
          {{ scope.row.user_name }}
        </template>
      </el-table-column>
      <el-table-column label="介绍" width="170px">
        <template slot-scope="scope">
          {{ scope.row.desc }}
        </template>
      </el-table-column>
      <el-table-column label="价格" width="80px">
        <template slot-scope="scope"> ¥ {{ scope.row.price }} </template>
      </el-table-column>
      <el-table-column label="有效期" width="80px">
        <template slot-scope="scope">
          {{ scope.row.expire_time | remainDays }} 天
        </template>
      </el-table-column>
      <el-table-column label="可签设备" width="180px">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.iphone > 0">iPhone /</span>
            <span v-if="scope.row.ipad > 0">iPad /</span>
            <span v-if="scope.row.ipod > 0">iPod /</span>
            <span v-if="scope.row.mac > 0">Mac</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import API from "../../../API";
export default {
  name: "SelectDeveloper",
  inject: ["baseInfo"],
  data() {
    return {
      list: [],
      loading: true,
      developer_id: "",
    };
  },
  methods: {
    selected(id) {
      if (id == this.developer_id) {
        return true;
      } else {
        return false;
      }
    },
    getDeveloperList() {
      this.$GET(API.user.userGetDeveloperList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
          for (let i = 0; i < this.list.length; i++) {
            this.list[i].selected = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    selectDeveloper(row) {      
      for (let i = 0; i < this.list.length; i++) {
        if (row.user_id == this.list[i].user_id) {
          this.list[i].selected = true;
          this.developer_id = row.user_id;
        } else {
          this.list[i].selected = false;
        }
      }
      this.list = JSON.parse(JSON.stringify(this.list));
    },
  },
  watch: {
    developer_id() {
      this.$emit("updateDeveloperId", this.developer_id);
    },
  },
  mounted() {
    this.getDeveloperList();
  },
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 550px) {
}
</style>