<template>
  <div v-if="baseInfo.initComplete" id="app">
    <div class="global_bg"></div>
    <router-view></router-view>
    <div v-if="showNav" class="nav_bar_wrap">
      <div class="nav_bar_item_wrap" @click="toHome">
        <img class="logo" :src="`${C.STATIC}/images/logo.png`" alt />
        <div class="nav_title">iSign</div>
      </div>
      <div
        v-if="userInfo.user_name == ''"
        class="nav_bar_item_wrap"
        style="justify-content: flex-end"
      >
        <el-button class="nav_item" type="text" @click="toAppstore"
          >应用商店</el-button
        >
        <el-button class="nav_item" type="text" @click="loginVisible = true"
          >登录</el-button
        >
        <el-button class="nav_item" type="text" @click="registerVisible = true"
          >注册</el-button
        >
      </div>
      <div
        v-if="userInfo.user_name != ''"
        class="nav_bar_item_wrap"
        style="justify-content: flex-end"
      >
        <el-button
          class="nav_item"
          type="text"
          @click="showUserChatGroup = true"
          >帮助</el-button
        >
        <el-button class="nav_item" type="text" @click="toAppstore"
          >应用商店</el-button
        >
        <div style="position: relative">
          <div
            v-show="
              userInfo.unread_count + userInfo.order + userInfo.subscribe > 0
            "
            class="red-dot"
            style="position: absolute; top: 18px; left: 2px"
          ></div>
          <el-button class="nav_item" type="text" @click="toPersonalCenter"
            >个人中心</el-button
          >
        </div>
        <el-button
          v-if="isAdmin"
          class="nav_item"
          type="text"
          @click="$router.push('/adminpage?page=UserList')"
          >管理后台</el-button
        >
      </div>
    </div>
    <Footer v-if="showNav" class="footer"></Footer>
    <el-dialog
      title="登录"
      :visible.sync="loginVisible"
      width="300px"
      @closed="loginClosed"
    >
      <Login @register="register" @forget="forget"></Login>
    </el-dialog>
    <el-dialog
      @exit="registerClosed"
      title="注册"
      :visible.sync="registerVisible"
      width="300px"
    >
      <Register></Register>
    </el-dialog>
    <el-dialog
      title="重置密码"
      :visible.sync="forgetVisible"
      width="300px"
      :modal="false"
    >
      <Forget></Forget>
    </el-dialog>
    <el-dialog
      title="官方用户群"
      :visible.sync="showUserChatGroup"
      custom-class="chat_dialog"
      :modal="true"
    >
      <div>
        <div style="margin-bottom: 10px;">
          若有任何疑问，请添加官方用户群反馈。
        </div>
        <div style="font-size:16px;font-weight:600;">QQ</div>
        <a style="color: " href="https://jq.qq.com/?_wv=1027&k=mdLNX5Mu"
          >点此加群：910071770</a
        >
        <div style="font-size:16px;font-weight:600;margin-top:20px;">微信</div>
        <div style="width: 100%;text-align:center;">
          <img style="width: 150px;" src="/images/qr_code.jpg" alt="" />
          <div>微信扫描二维码加群</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Footer from './components/general/Footer.vue'
import Login from './components/Home/Login'
import Forget from './components/Home/Forget.vue'
import Register from './components/Home/Register'
import C from './config'
import API from './API'
import D from './const'

let productList = []
let productMap = {}
let deviceList = []
let deviceMap = {}
let appList = []
let appMap = {}
let initComplete = false
export default {
  name: 'App',
  components: {
    Footer,
    Login,
    Register,
    Forget,
  },
  provide() {
    return {
      userInfo: this.userInfo,
      productList: this.productList,
      productMap: this.productMap,
      deviceList: this.deviceList,
      deviceMap: this.deviceMap,
      qiniuUploader: this.qiniuUploader,
      appList: this.appList,
      appMap: this.appMap,
      baseInfo: this.baseInfo,
      DEVELOPER_SIGN_FEE: this.DEVELOPER_SIGN_FEE,
      DEVELOPER_ACCOUNT_DAY_FEE: this.DEVELOPER_ACCOUNT_DAY_FEE,
      D: D,
      C: C,
      refreshUserInfo: this.getUserInfo,
      isMobileSafari: this.isMobileSafari,
    }
  },
  data() {
    return {
      C: C,
      D: D,
      registerVisible: false,
      loginVisible: false,
      forgetVisible: false,
      userInfo: {
        user_name: '',
        type: '',
        email: '',
        email_status: '',
        phone: '',
        status: '',
        unread_count: 0,
        user_id: '',
      },
      baseInfo: {
        DEVELOPER_ACCOUNT_DAY_FEE: 0,
        DEVELOPER_SIGN_FEE: 0,
        initComplete: initComplete,
      },
      productList: productList,
      productMap: productMap,
      deviceList: deviceList,
      deviceMap: deviceMap,
      appList: appList,
      appMap: appMap,
      isMobileSafari: false,
      showUserChatGroup: false,
    }
  },
  computed: {
    showNav() {
      let path = this.$route.path
      if (path == '/dl' || path == '/resetpassword') {
        return false
      }
      return true
    },
    isAdmin() {
      if (
        this.userInfo.type == D.USER.TYPE.ADMIN.ID ||
        this.userInfo.type == D.USER.TYPE.SUPER_ADMIN.ID
      ) {
        return true
      } else {
        return false
      }
    },
    isDeveloper() {
      if (this.userInfo.type == D.USER.TYPE.NORMAL_USER.ID) {
        return false
      } else {
        return true
      }
    },
  },
  watch: {
    $route() {
      let login = this.$route.query.login
      if (login == 'true') {
        this.loginVisible = true
      }
    },
  },
  methods: {
    qiniuUploader(uploadKey, uploadToken, file, cb) {
      const observable = this.$qiniu.upload(file, uploadKey, uploadToken)
      observable.subscribe({
        next(res) {
          cb(+res.total.percent.toFixed(2), null)
        },
        error(error) {
          cb(-1, error)
        },
        complete() {
          cb(1, null)
        },
      }) // 上传开始
    },
    getBaseInfo() {
      this.$GET(API.general.getBaseInfo, {})
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          let product_list = res.data.product_list
          for (let productInfo of product_list) {
            this.productMap[`${productInfo._id}`] = productInfo
          }
          let productType = {}
          for (let deviceInfo of product_list) {
            if (!productType[deviceInfo.type]) {
              productType[deviceInfo.type] = {
                value: deviceInfo.type,
                label: deviceInfo.type,
                children: [],
              }
            }
            productType[deviceInfo.type].children.push({
              value: deviceInfo._id,
              label: deviceInfo.name,
            })
          }
          for (let type of Object.keys(productType)) {
            this.productList.push(productType[type])
          }

          let app_list = res.data.app_list

          for (let appInfo of app_list) {
            this.appMap[`${appInfo._id}`] = appInfo
            this.appList.push(appInfo)
          }
          this.baseInfo.DEVELOPER_ACCOUNT_DAY_FEE =
            res.data.DEVELOPER_ACCOUNT_DAY_FEE
          this.baseInfo.DEVELOPER_SIGN_FEE = res.data.DEVELOPER_SIGN_FEE
          this.getUserInfo()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error.message,
          })
        })
    },
    toHome() {
      this.$window.location.href = '/'
    },
    toAppstore() {
      this.$router.push('/appstore')
    },
    getUserInfo() {
      this.$GET(API.user.getUserInfo, {})
        .then((res) => {
          if (res.data.status == 'ok') {
            let userInfo = res.data.data
            for (let key of Object.keys(userInfo)) {
              this.userInfo[key] = userInfo[key]
            }
            this.baseInfo.initComplete = true
            this.getDeviceList()
          } else {
            throw new Error(res.data.msg)
          }
        })
        .catch((error) => {
          // alert(error);
          console.log(error)
        })
    },
    getDeviceList() {
      this.$GET(API.user.userGetDeviceList, {})
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.deviceList = res.data.list
          for (let device of this.deviceList) {
            this.deviceMap[device._id] = device
          }
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    loginClosed() {
      let href = document.location.href
      let index = href.indexOf('&login=')
      if (index > 0) {
        document.location.href = href.slice(0, index)
      }
    },
    registerClosed(status) {
      console.log(status)
      this.registerVisible = false
    },
    toPersonalCenter() {
      if (this.$document.body.clientWidth < 550) {
        this.$router.push('/user?page=root')
      } else {
        this.$router.push('/user?page=MyInfo')
      }
    },
    register() {
      this.loginVisible = false
      this.registerVisible = true
    },
    forget() {
      this.loginVisible = false
      this.registerVisible = false
      this.forgetVisible = true
    },
  },
  mounted() {
    // 检查域名
    if (
      document.location.href.indexOf('isign.ren') < 0 &&
      document.location.href.indexOf('localhost') < 0 &&
      document.location.href.indexOf('isign.local') < 0
    ) {
      document.location.href = 'https://www.isign.ren'
    }
    let token = this.$cookies.get('token')
    if (token == null || token == '') {
      this.baseInfo.initComplete = true
      return
    }
    this.getBaseInfo()
    let userAgent = navigator.userAgent
    if (
      userAgent.indexOf('MicroMessenger') >= 0 ||
      userAgent.indexOf('QQ') >= 0 ||
      userAgent.indexOf('Mobile') < 0
    ) {
      this.isMobileSafari = false
    }
    this.isMobileSafari = true
  },
}
</script>

<style lang="less" scoped>
.global_bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(~/public/images/home/background.png);
  background-size: 100%;
  background-repeat: repeat;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
}
body {
  margin: 0;
}

.nav_bar_wrap {
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 99;
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: left;
  color: white;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  .nav_bar_item_wrap {
    height: 100%;
    display: flex;
    align-items: center;
    .nav_item {
      color: white;
      margin-left: 10px;
    }
  }
  .nav_title {
    margin-left: 10px;
    font-size: 18px;
    line-height: 40px;
  }
  .logo {
    width: 30px;
    height: 30px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 550px) {
  // .nav_bar_item_wrap {
  //   width: 200px;
  //   flex-direction: row;
  //   overflow: scroll;
  // }
  .router-view {
    padding: 0;
  }
  .footer {
    position: relative;
  }
}
</style>
