<template>
  <div class="wrap">
    <div class="table_content">
    <el-table
      :data="order_list"
      @row-click="showOrderDetail"
      :max-height="tableHeight"
    >
      <el-table-column label="IPA" width="150">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.resource_info | fileName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间" width="150">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.status_list[0].time | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.status | statusName
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-dialog
      title="详情"
      :visible.sync="orderDetailVisible"
      custom-class="order_detail_dialog"
      :modal-append-to-body="false"
      @closed="getOrderList"
    >
      <OrderDetailUser
        v-if="orderDetailVisible"
        :order_id="order_id"
        @exit="orderDetailClosed"
      />
    </el-dialog>
  </div>
</template>

<script>
import OrderDetailUser from "./OrderDetailUser";
import API from "../../API";
export default {
  name: "MyPublish",
  components: {
    OrderDetailUser,
  },
  data() {
    return {
      orderDetailVisible: false,
      showCreateOrder: false,
      order_list: [],
      selectedOrderInfo: {},
      order_id: "",
    };
  },
  computed: {
    tableHeight() {
      let width = document.body.clientWidth
      if (width > 550) {
        return 515
      } else {
        return document.body.clientHeight - 315
      }
    }
  },
  methods: {
    getOrderList() {
      this.$GET(API.order.orderList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.order_list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    showOrderDetail(row) {
      this.order_id = row._id;
      this.orderDetailVisible = true;
    },  
    orderDetailClosed() {
      this.orderDetailVisible = false;
      this.getOrderList();
    },
  },
  watch: {
    $route() {
      if (this.$route.query.subwindow == "create") {
        this.addDeviceVisible = true;
      }
    },
  },
  mounted() {
    this.getOrderList();
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 550px) {
  .wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
