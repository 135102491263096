<template>
  <div class="outter_app_detail_wrap">
    <el-menu
      class="menu"
      mode="horizontal"
      default-active="OutterAppInfo"
      @select="handleSelect"
    >
      <el-menu-item index="OutterAppInfo">
        <i class="el-icon-info"></i>
        <span>基本信息</span>
      </el-menu-item>
      <el-menu-item index="OutterAppOrder">
        <i class="el-icon-s-order"></i>
        <span>订单列表</span>
      </el-menu-item>
    </el-menu>
   <div class="sub_page">
      <OutterAppInfo v-if="active == 'OutterAppInfo'"></OutterAppInfo>
      <OutterAppOrder v-if="active == 'OutterAppOrder'"></OutterAppOrder>
   </div>
  </div>
</template>
<script>
import API from "../API";
import OutterAppInfo from '../components/AdminPage/OutterAppInfo.vue'
import OutterAppOrder from '../components/AdminPage/OutterAppOrder.vue'
export default {
  name: "OutterAppDetailPage",
  components: {
      OutterAppInfo,
      OutterAppOrder
  },
  computed: {
    appId() {
      return this.$route.query.appId;
    },
  },
  data() {
    return {
      appInfo: false,
      active: "OutterAppInfo",
    };
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      this.active = key
    },
    getAppInfo() {
      this.$GET(API.outter.adminGetAppInfo, { appId: this.appId })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.appInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getAppInfo();
  },
};
</script>
<style lang="less" scoped>
.outter_app_detail_wrap {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px - 140px);
  .sub_page {
    flex: 1;
    background: rgba(255, 255, 255, 0.96);
  }
}
@media screen and (max-width: 550px) {
}
</style>