<template>
  <div class="window_container">
    <div v-if="busInfo" class="bus_detail_wrap window_wrap">
      <div class="base_info_wrap">
        <AppInfo :app_id="busInfo.app_id"></AppInfo>
      </div>
      <div class="detail_info_wrap">
        <DetailNavBar :content="busInfo.name" />
        <el-tabs v-model="activeTab" @tab-click="tabChanged">
          <el-tab-pane label="接单" name="order_center"></el-tab-pane>
          <el-tab-pane label="签名" name="version"></el-tab-pane>
          <el-tab-pane label="订单列表" name="orders"></el-tab-pane>
        </el-tabs>
        <div v-if="activeTab == 'version'" class="version_info_wrap">
          <div class="new_signing_info_wrap">
            <div>
              <span :style="{ color: bidNull ? 'red' : 'black' }">* Bundle ID (必填）</span>
              <br />
              <el-input style="width: 320px; margin-right: 20px" v-model="busInfo.desc"
                placeholder="请填写签名后的Bundle ID。例如：ren.isign.utm"></el-input>
              <el-button type="success" size="small" @click="updateBid()">更新</el-button>
            </div>
            <div>
              未签名设备数：{{ unsignedDeviceCount }}
              <el-button class="download_udidlist_button" type="text" @click="downloadUdid"><i
                  class="el-icon-download"></i> 下载 UDID 列表</el-button>
            </div>
          </div>
          版本列表
          <el-table style="margin-top: 10px" :data="version_list" max-height="450px">
            <el-table-column label="版本" width="100px">
              <template slot-scope="scope">
                {{ scope.row.version }}
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="120px">
              <template slot-scope="scope">
                {{ scope.row.time | timeFilter }}
              </template>
            </el-table-column>
            <el-table-column label="已签" width="50px">
              <template slot-scope="scope">
                {{ signedMap[scope.row._id] }}
              </template>
            </el-table-column>
            <el-table-column label="待签" width="50px">
              <template slot-scope="scope">
                {{ waitSignMap[scope.row._id] }}
              </template>
            </el-table-column>
            <el-table-column label="" width="360px">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="
                  $downloadIpa(
                    scope.row.raw_id,
                    `${appMap[busInfo.app_id].name}-${scope.row.version}.ipa`
                  )
                  ">下载原始IPA</el-button>
                <el-button type="primary" size="mini" @click="uploadIPA(scope.row._id)"
                  :disabled="bidNull">上传已签IPA</el-button>
                <a style="margin-left: 10px" v-if="scope.row.resource_id"
                  :href="installPlistPath(scope.row.resource_id)"><el-button size="mini" type="success">安装</el-button></a>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog title="上传IPA" :visible.sync="uploadDialogVisible" custom-class="upload_dialog" :modal="false">
            <div class="upload_hint">
              请注意，每次更新版本的 IPA 都需要包含所有已上车的
              UDID。您可在“版本管理”下载所有 UDID 列表用于导入。
            </div>
            <AddIPA v-if="uploadDialogVisible" @uploadComplete="uploadComplete" :bid="busInfo.desc"></AddIPA>
          </el-dialog>
        </div>

        <div v-if="activeTab == 'order_center'">
          <el-table style="margin-top: 10px" :data="new_subscribe_list" max-height="450px">
            <el-table-column label="版本" width="100px">
              <template slot-scope="scope">
                {{ scope.row.version_info.version }}
              </template>
            </el-table-column>
            <el-table-column label="付款时间" width="120px">
              <template slot-scope="scope">
                {{ scope.row.time | timeFilter }}
              </template>
            </el-table-column>
            <el-table-column label="类型" width="80px">
              <template slot-scope="scope">
                {{ scope.row.is_init ? '首次安装' : '更新付款' }}
              </template>
            </el-table-column>
            <el-table-column label="金额" width="60px">
              <template slot-scope="scope">
                {{ scope.row.price }}
              </template>
            </el-table-column>
            <el-table-column label="当前状态" width="100px">
              <template slot-scope="scope">
                {{ scope.row.status | statusName }}
              </template>
            </el-table-column>
            <el-table-column label="" width="150px">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="lockSubscribe(scope.row._id)">接单</el-button>
                <el-button type="danger" size="mini" @click="requestRefund(scope.row._id)">退款</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="activeTab == 'orders'">
          <el-table style="margin-top: 10px" :data="order_list" max-height="450px" @row-click="openSubscribeDetail">
            <el-table-column label="时间" width="150px">
              <template slot-scope="scope">
                {{ scope.row.time | timeFilter }}
              </template>
            </el-table-column>
            <el-table-column label="类型" width="150px">
              <template slot-scope="scope">
                {{ scope.row.is_init ? '首次安装' : '更新' }}
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100px">
              <template slot-scope="scope">
                {{ scope.row.status | statusName }}
              </template>
            </el-table-column>
            <el-table-column label="付款金额" width="150px">
              <template slot-scope="scope">
                ¥ {{ scope.row.price }}
                <span v-if="scope.row.promoInfo" style="color: red">（优惠券 -¥{{ scope.row.promoInfo.discount }}）</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background layout="prev, pager, next" :total="total" :page-size="size" :current-page.sync="page"
            @current-change="getOrderList">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="订单详情" :visible.sync="subscribeDetailDialogVisible" custom-class="order_detail_dialog" :modal="true">
      <SubscribeDetailDeveloper v-if="subscribeDetailDialogVisible" :subscribe_id="subscribe_id" />
    </el-dialog>
    <el-dialog title="选择更新范围" :visible.sync="selectOrderRangeVisible" custom-class="order_detail_dialog" :modal="true">
      <b>注：列表中不包含更新订单，仅显示初次签名的订单。如需更新 IPA 文件，点击“更新所有”即可。</b>
      <el-table v-if="selectOrderRangeVisible" style="margin-top: 10px" :data="uploadingVersionSuscribeList"
        max-height="450px" @selection-change="handleVersionUpdateSelectionChanged" ref="selectUploadRangeTable">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="接单时间" width="150px">
          <template slot-scope="scope">
            {{ scope.row.time | timeFilter }}
          </template>
        </el-table-column>
        <el-table-column label="设备名称" width="150px">
          <template slot-scope="scope">
            {{ scope.row.device_info.name }}
          </template>
        </el-table-column>
        <el-table-column label="UDID" width="250px">
          <template slot-scope="scope">
            {{ scope.row.device_info.udid }}
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px; display: flex; justify-content: flex-end;">
        <el-button type="success" @click="uploadDialogVisible = true"
          :disabled="update_subscribe_list.length == 0">仅更新选中</el-button>
        <el-button type="success" @click="updateAllSubscribe">更新所有</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import API from '../API'
import AddIPA from '../components/general/AddIPA'
import AppInfo from '../components/general/AppInfo'
import DetailNavBar from '../components/general/DetailNavBar'
import SubscribeDetailDeveloper from '../components/PersonalCenter/SubscribeDetailDeveloper'
export default {
  name: 'BusDetail',
  inject: ['D', 'productMap', 'appMap'],
  components: {
    AddIPA,
    AppInfo,
    DetailNavBar,
    SubscribeDetailDeveloper,
  },
  data() {
    return {
      busInfo: false,
      bidNull: true,
      uploading_version_id: '',
      new_version: '',
      version_list: [],
      new_subscribe_list: [],
      uploadDialogVisible: false,
      subscribeDetailDialogVisible: false,
      selectOrderRangeVisible: false,
      activeTab: 'order_center',
      subscribe_list: [],
      order_list: [],
      update_list: [],
      waitSignMap: {},
      signedMap: {},
      page: 1,
      size: 20,
      total: 0,
      subscribe_id: '',
      update_subscribe_list: []
    }
  },
  computed: {
    uploadingVersionSuscribeList() {
      return this.subscribe_list.filter((subscribe) => {
        return subscribe.version_id == this.uploading_version_id && subscribe.status === "SIGNING"
      })
    },
    bus_id() {
      return this.$route.query.bus_id
    },
    unsignedDeviceCount() {
      let count = 0
      for (let update of this.update_list) {
        if (
          update.status_list[update.status_list.length - 1].status ==
          this.D.ORDER.STATUS.SIGNING.ID
        ) {
          count++
        }
      }
      return count
    },
  },

  methods: {
    updateAllSubscribe() {
      this.update_subscribe_list = []
      this.uploadDialogVisible = true
    },
    handleVersionUpdateSelectionChanged(val) {
      this.update_subscribe_list = val
    },
    installPlistPath(resource_id) {
      return `itms-services://?action=download-manifest&amp;url=https://www.isign.ren/plist/${resource_id}.plist`
    },
    tabChanged() {
      switch (this.activeTab) {
        case 'version':
          this.getVersionList()
          this.getSubscribeList()
          break
        case 'orders':
          this.getOrderList()
          break
        case 'order_center':
          this.getNewSubscribeList()
          break
      }
    },
    openSubscribeDetail(row) {
      this.subscribe_id = row._id
      this.subscribeDetailDialogVisible = true
    },
    requestRefund(subscribeId) {
      this.$POST(API.subscribe.developerRejectOrder, {
        subscribe_id: subscribeId,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.getNewSubscribeList()
          this.$message({
            type: 'success',
            message: '已申请退款。',
          })
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getOrderList() {
      this.$GET(API.subscribe.developerGetSubscribeList, {
        bus_id: this.bus_id,
        page: this.page,
        size: this.size,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.order_list = res.data.list
          this.total = res.data.total
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getVersionList() {
      this.$GET(API.subscribe.developerGetBusAppVersionList, {
        bus_id: this.bus_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          let version_list = res.data.list
          this.getUpdateList(version_list)
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getSubscribeList() {
      this.$GET(API.subscribe.getBusSubscribeInitList, {
        bus_id: this.bus_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.subscribe_list = res.data.list
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getUpdateList(version_list) {
      this.$GET(API.subscribe.developerGetBusUpdateList, {
        bus_id: this.bus_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          for (let version of version_list) {
            this.signedMap[version._id] = 0
            this.waitSignMap[version._id] = 0
          }
          this.update_list = res.data.list
          for (let update of this.update_list) {
            let status =
              update.status_list[update.status_list.length - 1].status
            if (status == this.D.ORDER.STATUS.SIGNING.ID) {
              this.waitSignMap[update.version_id] += 1
            }
            if (
              status == this.D.ORDER.STATUS.SIGNED.ID ||
              status == this.D.ORDER.STATUS.DONE.ID
            ) {
              this.signedMap[update.version_id] += 1
            }
          }
          this.version_list = version_list
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    deleteVersion(version_id) {
      this.$POST(API.subscribe.developerDeleteVersion, {
        version_id: version_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.$message({
            type: 'success',
            message: '成功',
          })
          this.getVersionList()
          this.getSubscribeList()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    downloadUdid() {
      var element = document.createElement('a')
      var message = 'Device ID	Device Name	Device Platform\n'
      for (let subscribe of this.subscribe_list) {
        let deviceInfo = subscribe.device_info
        let platform = 'ios'
        let type = this.productMap[deviceInfo.product_id].type
        if (type == 'Mac') {
          platform = 'mac'
        }
        message += `${deviceInfo.udid}	${deviceInfo.udid}	${platform}\n`
      }
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(message)
      )
      element.setAttribute('download', 'UDID列表')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    createVersion(version) {
      if (version == '') {
        this.$message({
          type: 'error',
          message: '请输入版本号',
        })
        return
      }
      this.$POST(API.subscribe.developerCreateVersion, {
        version: version,
        bus_id: this.bus_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.$message({
            type: 'success',
            message: '创建版本成功',
          })
          this.getVersionList()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getNewSubscribeList() {
      this.$GET(API.subscribe.developerGetNewSubscribeList, {
        bus_id: this.bus_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.new_subscribe_list = res.data.list
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    uploadIPA(version_id) {
      this.update_subscribe_list = []
      this.uploading_version_id = version_id
      this.selectOrderRangeVisible = true
    },
    uploadComplete(resource_id) {
      const subscribe_ids = this.update_subscribe_list.map((subscribe) => {
        return subscribe._id
      })
      this.$POST(API.subscribe.developerEditVersion, {
        version_id: this.uploading_version_id,
        bus_id: this.bus_id,
        resource_id: resource_id,
        subscribe_ids
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.$message({
            type: 'success',
            message: '更新成功',
          })
          let _this = this
          setTimeout(() => {
            _this.uploadDialogVisible = false
            _this.selectOrderRangeVisible = false
          }, 1000)
          this.getVersionList()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    lockSubscribe(subscribe_id) {
      this.$POST(API.subscribe.developerLockSubscribe, {
        subscribe_id: subscribe_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.$message({
            type: 'success',
            message: '接单成功',
          })
          this.getNewSubscribeList()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getBusInfo() {
      this.$GET(API.subscribe.getBusInfo, { bus_id: this.bus_id })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.busInfo = res.data.data
          if (this.busInfo.desc == '') {
            this.bidNull = true
          } else {
            this.bidNull = false
          }
          this.getSubscribeList()
          this.getVersionList()
          this.getNewSubscribeList()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    updateBid() {
      this.$POST(API.subscribe.updateBid, {
        bus_id: this.bus_id,
        bid: this.busInfo.desc,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.$message({
            type: 'success',
            message: '已更新Bundle ID',
          })
          this.bidNull = false
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
  },
  mounted() {
    this.getBusInfo()
  },
}
</script>

<style lang="less" scoped>
.bus_detail_wrap {
  display: flex;
  flex-direction: row;

  .base_info_wrap {
    background-color: white;
    width: 250px;
  }

  .detail_info_wrap {
    padding: 20px;
  }

  .version_info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .new_signing_info_wrap {
      .download_udidlist_button {
        margin-left: 20px;
      }
    }

    .new_version_wrap {
      width: 300px;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
    }

    .upload_hint {
      margin-bottom: 20px;
      color: red;
    }
  }
}

@media screen and (max-width: 550px) {
  .bus_detail_wrap {
    display: flex;
    flex-direction: column;
    min-height: 50vh;

    .base_info_wrap {
      background-color: white;
      width: 100%;
      display: none;
    }

    .detail_info_wrap {
      min-height: 50vh;
    }
  }
}
</style>
