<template>
  <div></div>
</template>
<script>
export default {
  name: "BindWechat",
  data() {
    return {};
  },
  mounted() {
      document.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx97ecc9dc79cda664&redirect_uri=https%3A%2F%2Fwww.isign.ren%2F%23%2FbindWechatRedirect&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect';
  }
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 550px) {
}
</style>