<template>
  <div class="dialog_wrap">
    <div v-if="!isEdit" class="udid-img-wrap">
      <a href="/#/udid"><img src="/images/personal/udid.png" alt="" /></a>
      <div>
        扫码获取UDID 或
        <el-button
          @click="$window.location.href = '/#/udid'"
          type="text"
          size="mini"
          >点击此处</el-button
        >
      </div>
    </div>
    <div class="info_area">
      <el-input
        :disabled="isEdit"
        class="info_item"
        type="text"
        v-model="udid"
        placeholder="设备UDID（用于签名）"
      ></el-input>
      <el-input
        class="info_item"
        type="text"
        v-model="name"
        placeholder="设备名称（用于备注）"
      ></el-input>
      <el-cascader
        class="info_item"
        v-model="product"
        :options="productList"
        placeholder="请选择设备型号"
      ></el-cascader>
      <div class="info_item">
        <el-button
          style="width: 100%; margin-top: 20px;"
          type="primary"
          @click="userUpdateDevice"
          >{{ submitButtonTitle }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "AddDevice",
  props: ["deviceInfo"],
  inject: ['productList', 'productMap'],
  data() {
    return {
      product: [],
      name: "",
      udid: "",
    };
  },
  methods: {
    userUpdateDevice() {
      try {
        if (this.udid == "" || this.name == "" || !this.product_id) {
          throw new Error("请输入设备名称和UDID，选择设备型号");
        }
        if (!this.$isValidUdid(this.udid)) {
          throw new Error("iSign 无法识别您输入的UDID");
        }
        this.$POST(API.user.userUpdateDevice, {
          udid: this.udid,
          name: this.name,
          product_id: this.product_id,
        }).then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$emit("exit");
        });
      } catch (error) {
        this.$message({
          type: "error",
          message: error.message,
        });
      }
    },
    fillDeviceInfo() {
      if (this.deviceInfo) {
        let type = this.productMap[this.deviceInfo.product_id].type;
        this.udid = this.deviceInfo.udid;
        this.name = this.deviceInfo.name;
        this.product = [type, this.deviceInfo.product_id];
      } else {
        this.udid = "";
        this.name = "";
        this.product = [];
      }
    },
  },
  computed: {
    submitButtonTitle() {
      if (this.isEdit) {
        return "更新信息";
      } else {
        return "新增设备";
      }
    },
    isEdit() {
      this.fillDeviceInfo();
      if (this.deviceInfo) {
        return true;
      }
      return false;
    },
    product_id() {
      if (this.product.length != 2) {
        return "";
      } else {
        return this.product[1];
      }
    },
  },
  mounted() {
    if (this.$route.query.UDID) {
      this.udid = this.$route.query.UDID;
    }
  },
};
</script>

<style lang="less" scoped>
.dialog_wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
  .info_area {
    width: 100%;
    .info_item {
      margin-top: 15px;
      width: 100%;
      margin-left: 0px !important;
    }
  }
  .udid-img-wrap {
    font-size: 12px;
    text-align: center;
    width: 200px;
    img {
      width: 110px;
    }
  }
}
</style>