<template>
  <div class="app_list_wrap">
    <div class="title-1">App 列表</div>
    <el-button
      style="margin: 10px 0 10px 0"
      type="primary"
      icon="el-icon-plus"
      size="mini"
      @click="openCreateAppDialog"
      >添加</el-button
    >
    <el-button type="success" size="mini" @click="destributeApp">分发</el-button>
    <el-table :data="list" style="width: 850px" max-height="400px">
      <el-table-column label="" width="20">
        <template slot-scope="scope">
          <div :class="scope.row.status"></div>
        </template>
      </el-table-column>
      <el-table-column label="" width="40">
        <template slot-scope="scope">
          <img class="app_logo" :src="scope.row.logo" alt="" />
        </template>
      </el-table-column>
      <el-table-column label="App" width="120px">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="简介" width="220px">
        <template slot-scope="scope">
          {{ scope.row.desc }}
        </template>
      </el-table-column>
      <el-table-column label="" width="400px">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editApp(scope.row._id)"
            >基本信息</el-button
          >
          <el-button type="primary" size="mini" @click="editDetail(scope.row)"
            >编辑详情</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="previewDetail(scope.row._id)"
            >查看详情</el-button
          >
          <el-button
            type="normal"
            size="mini"
            @click="openResourceListDialog(scope.row)"
            >版本</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="编辑应用信息"
      :visible.sync="appInfoVisible"
      custom-class="edit_app_dialog"
    >
      <EditApp
        v-if="appInfoVisible"
        :app_id="app_id"
        @exit="exitAppInfo"
      ></EditApp>
    </el-dialog>
    <el-dialog
      title="应用版本列表"
      :visible.sync="appVersionListVisible"
      custom-class="edit_app_dialog"
    >
      <AppVersionList
        v-if="appVersionListVisible"
        :appInfo="appInfo"
      ></AppVersionList>
    </el-dialog>
    <el-dialog
      :visible.sync="editAppDetailVisible"
      custom-class="passage_editor_dialog"
    >
      <quill-editor class="editor" ref="detailEditor" v-model="detail">
      </quill-editor>
      <el-button type="primary" @click="submitDetail">提交</el-button>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import EditApp from "./EditApp";
import AppVersionList from "./AppVersionList";
export default {
  name: "AppList",
  components: {
    EditApp,
    AppVersionList,
  },
  data() {
    return {
      app_id: false,
      appInfoVisible: false,
      appVersionListVisible: false,
      editAppDetailVisible: false,
      appVersionList: [],
      list: [],
      detail: "",
    };
  },
  methods: {
    getAppList() {
      this.$GET(API.general.adminGetAppList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    openResourceListDialog(row) {
      this.appInfo = row;
      this.appVersionListVisible = true;
    },
    adminAddApp(name) {
      this.$POST(API.general.adminAddApp, { name: name })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "创建成功",
          });
          this.getAppList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    openCreateAppDialog() {
      this.$prompt("请输入App名称", "新增App", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.adminAddApp(value);
        })
        .catch(() => {
          console.log("取消");
        });
    },
    editApp(app_id) {
      this.app_id = app_id;
      this.appInfoVisible = true;
    },
    editDetail(row) {
      this.appInfo = row;
      this.$GET(API.general.adminGetAppDetailContent, { app_id: row._id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.detail = res.data.data;
          this.editAppDetailVisible = true;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    submitDetail() {
      this.$POST(API.general.adminUpdateAppDetail, {
        app_id: this.appInfo._id,
        detail: this.detail,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "更新成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    destributeApp() {
      this.$POST(API.general.adminDestributeApp, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    previewDetail(app_id) {
      window.location.href = `/app/${app_id}.html`;
    },
    exitAppInfo() {
      this.appInfoVisible = false;
      this.getAppList();
    },
  },
  mounted() {
    this.getAppList();
  },
};
</script>


<style lang="less" scoped>
.app_list_wrap {
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;

  .app_logo {
    width: 30px;
  }
  .OPEN {
    width: 10px;
    height: 10px;
    background-color: #00c91e;
    border-radius: 5px;
  }
  .CLOSED {
    width: 10px;
    height: 10px;
    background-color: #f7c708;
    border-radius: 5px;
  }
}
</style>


<style>
.ql-editor {
  height: 700px !important;
}
</style>