<template>
  <div>
    <div class="title_wrap">
      <el-input v-model="title" placeholder="标题"></el-input>
    </div>
    <div class="content_wrap">
      <el-input
        type="textarea"
        autosize
        placeholder="请输入内容"
        v-model="content"
      >
      </el-input>
    </div>
    <el-button @click="sendMessage" type="primary">发送</el-button>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "Message",
  props: ["reciever_id"],
  data() {
    return {
      title: "",
      content: "",
      url: "-1",
    };
  },
  methods: {
    sendMessage() {
      this.$POST(API.user.adminSendMessage, {
        title: this.title,
        content: this.content,
        url: this.url,
        reciever_id: this.reciever_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "发送成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>