<template>
  <div class="wrap">
    <div class="qr_wrap">
      <div v-if="alipay == ''" class="qr_code_info">支付宝收款码</div>
      <div v-if="alipay == ''" class="plus_wrap">
        <i class="el-icon-plus"></i>
      </div>
      <img :src="alipay" alt="" />
      <input
        type="file"
        ref="alipayInput"
        @change="toBase64($D.ORDER.PAY_METHOD.ALIPAY.ID)"
        accept="image/jpeg,image/png,image/jpg"
        class="upload_input"
      />
    </div>
    <div class="qr_wrap">
      <div v-if="wechat == ''" class="qr_code_info">微信收款码</div>
      <div v-if="wechat == ''" class="plus_wrap">
        <i class="el-icon-plus"></i>
      </div>
      <img :src="wechat" alt="" />
      <input
        ref="wechatInput"
        type="file"
        @change="toBase64($D.ORDER.PAY_METHOD.WECHAT.ID)"
        accept="image/jpeg,image/png,image/jpg"
        class="upload_input"
      />
    </div>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "WalletQR",
  data() {
    return {
      wechat: "",

      alipay: "",
    };
  },
  methods: {
    getQRCode(type) {
      this.$GET(API.user.getQrCode, {
        type: type,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          if (type == this.$D.ORDER.PAY_METHOD.WECHAT.ID) {
            this.wechat = res.data.data;
          } else {
            this.alipay = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    upload(type) {
      let img;
      if (type == this.$D.ORDER.PAY_METHOD.ALIPAY.ID) {
        img = this.alipay;
      } else {
        img = this.wechat;
      }
      this.$POST(API.user.updateDeveloperQRCode, { img: img, type: type })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "上传成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    toBase64(type) {
      let _this = this;
      let file;
      if (type == this.$D.ORDER.PAY_METHOD.ALIPAY.ID) {
        file = this.$refs.alipayInput.files[0];
      } else {
        file = this.$refs.wechatInput.files[0];
      }
      let reader = new FileReader();
      reader.onload = function (e) {
        let img = new Image();
        img.src = e.target.result;
        setTimeout(() => {
          let ratio = img.width / img.height;
          console.log(ratio);
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");
          // 设置宽高度为等同于要压缩图片的尺寸
          let targetWidth = 200,
            targetHeight = 200 / ratio;
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          context.clearRect(0, 0, targetWidth, targetHeight);
          //将img绘制到画布上
          context.drawImage(img, 0, 0, targetWidth, targetHeight);
          console.log(targetWidth, targetHeight);
          if (type == _this.$D.ORDER.PAY_METHOD.ALIPAY.ID) {
            _this.alipay = canvas.toDataURL("image/jpeg");
          } else {
            _this.wechat = canvas.toDataURL("image/jpeg");
          }
          _this.upload(type);
        }, 1000);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
  },
  mounted() {
    this.getQRCode(this.$D.ORDER.PAY_METHOD.WECHAT.ID);
    this.getQRCode(this.$D.ORDER.PAY_METHOD.ALIPAY.ID);
  },
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  .qr_wrap {
    width: 220px;
    height: 220px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    border: 1px #dddddd solid;
    .plus_wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      i {
        font-size: 50px;
        line-height: 220px;
        text-align: center;
        width: 100%;
      }
    }
    .qr_code_info {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
      color: #888888;
    }
    img {
      width: 80%;
      display: block;
      margin: auto;
    }
    .upload_input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      filter: alpha(opacity=0);
      cursor: pointer;
    }
  }
}
</style>