<template>
  <div class="personal_center_wrap window_container">
    <div class="personal_center window_wrap" ref="content">
      <div class="content_wrap" :style="{ left: mobileContentLeft }">
        <div class="mobile_content_nav">
          <el-button class="back_button" type="text" @click="goback">
            <i class="el-icon-arrow-left"></i> 返回</el-button
          >
        </div>
        <UserList ref="contentPage" v-if="page == 'UserList'"></UserList>
        <DeveloperApply
          ref="contentPage"
          v-if="page == 'DeveloperApply'"
        ></DeveloperApply>
        <IPAList ref="contentPage" v-if="page == 'IPAList'"></IPAList>
        <PublicIPA ref="contentPage" v-if="page == 'PublicIPA'"></PublicIPA>
        <OrderList ref="contentPage" v-if="page == 'OrderList'"></OrderList>
        <OutterApp ref="contentPage" v-if="page == 'OutterApp'"></OutterApp>
        <WithdrawList
          ref="contentPage"
          v-if="page == 'WithdrawList'"
        ></WithdrawList>
        <ProductList
          ref="contentPage"
          v-if="page == 'ProductList'"
        ></ProductList>
        <AppList ref="contentPage" v-if="page == 'AppList'"></AppList>
        <SubscribeList
          ref="contentPage"
          v-if="page == 'SubscribeList'"
        ></SubscribeList>
        <RefundList ref="contentPage" v-if="page == 'RefundList'"></RefundList>
        <PromocodeList v-if="page == 'PromocodeList'"></PromocodeList>
      </div>
      <CustomNavGuide
        class="navi"
        :pageConfig="pageConfig"
        :page="page"
        @pageChanged="pageChanged"
        :style="{ left: mobileNavLeft }"
      ></CustomNavGuide>
    </div>
  </div>
</template>

<script>
import CustomNavGuide from "../components/general/CustomNavGuide";
import UserList from "../components/AdminPage/UserList";
import DeveloperApply from "../components/AdminPage/DeveloperApply";
import IPAList from "../components/AdminPage/IPAList";
import PublicIPA from "../components/AdminPage/PublicIPA";
import OrderList from "../components/AdminPage/OrderList";
import WithdrawList from "../components/AdminPage/WithdrawList";
import ProductList from "../components/AdminPage/ProductList";
import AppList from "../components/AdminPage/AppList";
import SubscribeList from "../components/AdminPage/SubscribeList";
import RefundList from "../components/AdminPage/RefundList";
import OutterApp from "../components/AdminPage/OutterApp.vue";
import PromocodeList from "../components/AdminPage/PromocodeList.vue";
export default {
  components: {
    CustomNavGuide,
    UserList,
    DeveloperApply,
    IPAList,
    PublicIPA,
    OrderList,
    WithdrawList,
    ProductList,
    AppList,
    SubscribeList,
    RefundList,
    OutterApp,
    PromocodeList,
  },
  inject: ["userInfo", "baseInfo", "refreshUserInfo"],
  data() {
    return {
      page: "UserList",
      pageSize: {
        width: "700px",
        height: "400px",
      },
      mobileNavLeft: "0px",
      mobileContentLeft: "20vw",
      pageConfig: [
        {
          title: "通用",
          items: [
            {
              title: "产品库",
              key: "ProductList",
            },
            {
              title: "App",
              key: "AppList",
            },
            {
              title: "外部App",
              key: "OutterApp",
            },
            {
              title: "优惠券",
              key: "PromocodeList",
            },
          ],
        },
        {
          title: "用户",
          items: [
            {
              title: "用户列表",
              key: "UserList",
            },
            {
              title: "开发者申请",
              key: "DeveloperApply",
            },
          ],
        },
        {
          title: "IPA",
          items: [
            {
              title: "IPA列表",
              key: "IPAList",
            },
            {
              title: "公开IPA库",
              key: "PublicIPA",
            },
          ],
        },
        {
          title: "订单",
          items: [
            {
              title: "单次签名订单列表",
              key: "OrderList",
            },
            {
              title: "应用商店订单列表",
              key: "SubscribeList",
            },
            {
              title: "提现列表",
              key: "WithdrawList",
            },
            {
              title: "退款列表",
              key: "RefundList",
            },
          ],
        },
      ],
    };
  },
  methods: {
    goback() {
      this.$router.push({ path: "/adminpage", query: { page: "root" } });
    },
    pageChanged(data) {
      this.$router.push({ path: "/adminpage", query: { page: data.key } });
    },
    updateContentSize() {
      let currentPage = this.$route.query.page;
      let _this = this;
      if (currentPage == "root") {
        setTimeout(() => {
          _this.page = currentPage;
        }, 500);
      } else {
        this.page = currentPage;
      }

      if (this.$route.query.page == "root") {
        this.mobileNavLeft = "0vw";
        this.mobileContentLeft = "-40vw";
      } else {
        this.mobileNavLeft = "-100vw";
        this.mobileContentLeft = "-100vw";
      }
    },
  },
  watch: {
    $route() {
      this.updateContentSize();
    },
  },
  mounted() {
    let page = this.$route.query.page;
    if (!page) {
      this.$router.push({ path: "/adminpage", query: { page: "MyInfo" } });
    }
    this.updateContentSize();
  },
};
</script>

<style lang="less" scoped>
.personal_center_wrap {
  .personal_center {
    flex-direction: row-reverse;
    display: flex;
    .mobile_content_nav {
      display: none;
    }
    .navi {
      display: block;
      width: 180px;
    }
    .content_wrap {
      transition: width 0.3s ease-in-out;
    }
  }
}

@media screen and (max-width: 550px) {
  .personal_center_wrap {
    overflow: hidden;
    .personal_center {
      position: relative;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0px;
      box-sizing: border-box;
      width: 200vw;
      .mobile_content_nav {
        display: block;
        height: 40px;
        background-color: white;
        padding-left: 10px;
      }
      .navi {
        width: 100vw;
        transition: left 0.3s ease-in-out;
        position: relative;
        min-height: calc(100vh - 140px);
      }
      .content_wrap {
        width: 100vw !important;
        left: 100vw;
        transition: left 0.3s ease-in-out;
        position: relative;
      }
    }
  }
}
</style>
