const RUN_ENV = {
  development: {
    STATIC: "http://localhost:8080",
    API_DOMAIN: "http://localhost:3000",
  },
  production: {
    STATIC: "https://www.isign.ren",
    API_DOMAIN: "https://www.isign.ren:3000",
  },
  active: {},
};

RUN_ENV.active = RUN_ENV.production;
module.exports = RUN_ENV.active;
