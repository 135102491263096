<template>
  <div class="wrap">
    <div class="title-1">优惠券管理</div>
    <el-button
      style="margin-bottom: 10px"
      size="mini"
      type="success"
      @click="newPromocodeVisible = true"
      >新增</el-button
    >
    <el-switch
      style="float: right"
      v-model="valid"
      active-color="#13ce66"
      :active-value="true"
      active-text="查看有效"
      inactive-color="#ff4949"
      :inactive-value="false"
      inactive-text="查看无效"
      @change="refresh"
    >
    </el-switch>
    <el-table :data="list" style="width: 850px" max-height="600px">
      <el-table-column label="优惠码" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="50">
        <template slot-scope="scope">
          <span
            :style="{
              color: scope.row.valid ? 'green' : 'red',
              fontWeight: 600,
            }"
            >{{ scope.row.valid ? '有效' : '无效' }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="类型" width="70">
        <template slot-scope="scope">
          <span>{{ D.ORDER.PROMOCODE[scope.row.type].DESC }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总共" width="50">
        <template slot-scope="scope">
          <span>{{ scope.row.total }}</span>
        </template>
      </el-table-column>
      <el-table-column label="已用" width="50">
        <template slot-scope="scope">
          <span>{{ scope.row.used }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开发者" width="80">
        <template slot-scope="scope">
          <span>{{
            scope.row.developer_id
              ? toDeveloperName(scope.row.developer_id)
              : '不限'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期" width="120">
        <template slot-scope="scope">
          <div>{{ scope.row.start | timeFilter }}</div>
          <div style="text-align: center">至</div>
          <div>{{ scope.row.end | timeFilter }}</div>
        </template>
      </el-table-column>
      <el-table-column label="可用设备" width="100">
        <template slot-scope="scope">
          <div v-for="(device, index) in scope.row.device_type" :key="index">
            {{ device }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="描述">
        <template slot-scope="scope">
          <span>{{ scope.row.description }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="newPromocodeVisible"
      width="500px"
      :show-close="false"
      title="新增优惠券"
    >
      <div>优惠码</div>
      <el-input v-model="promocode.code" class="mb-10"></el-input>
      <div class="mb-10">
        <div>可用设备</div>
        <el-checkbox-group v-model="promocode.device_type">
          <el-checkbox label="iPhone">iPhone</el-checkbox>
          <el-checkbox label="iPad">iPad</el-checkbox>
          <el-checkbox label="iPod">iPod</el-checkbox>
          <el-checkbox label="Apple TV">Apple TV</el-checkbox>
          <el-checkbox label="Mac">Mac</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="mb-10">
        <div>类型</div>
        <el-radio
          v-for="(type, index) in D.ORDER.PROMOCODE"
          :key="index"
          v-model="promocode.type"
          :label="type.ID"
          >{{ type.DESC }}</el-radio
        >
      </div>
      <div class="mb-10">
        <div>描述</div>
        <el-input v-model="promocode.description"></el-input>
      </div>
      <div class="mb-10">
        <div>优惠值</div>
        <el-input v-model="promocode.value"></el-input>
      </div>
      <div class="mb-10">
        <div>开发者</div>
        <el-select
          v-model="promocode.developer_id"
          placeholder="选择限定开发者"
        >
          <el-option
            v-for="item in developerList"
            :key="item.user_id"
            :label="item.user_name"
            :value="item.user_id"
          >
          </el-option>
        </el-select>
        <el-button
          style="margin-left: 20px"
          size="mini"
          @click="promocode.developer_id = null"
          type="success"
          >设为不限开发者</el-button
        >
      </div>
      <div class="mb-10">
        <div>总量</div>
        <el-input v-model="promocode.total"></el-input>
      </div>
      <div class="mb-10">
        <div>起止时间</div>
        <el-date-picker
          v-model="promocode.start"
          type="datetime"
          placeholder="选择日期时间"
          value-format="timestamp"
          prefix-icon="d"
        >
        </el-date-picker>
        -
        <el-date-picker
          v-model="promocode.end"
          type="datetime"
          placeholder="选择日期时间"
          value-format="timestamp"
          prefix-icon="d"
        >
        </el-date-picker>
      </div>
      <div class="mb-10">
        <div>是否启用</div>
        <el-switch
          v-model="promocode.valid"
          active-text="启用"
          :active-value="true"
          inactive-text="禁用"
          :inactive-value="false"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
      <div class="mb-10">
        <el-button style="width: 100%" type="success" @click="submit"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from '../../API'
import D from '../../const'
export default {
  name: 'PromocodeList',
  data() {
    return {
      D: D,
      valid: true,
      list: [],
      newPromocodeVisible: false,
      promocode: {
        code: '',
        type: D.ORDER.PROMOCODE.FREE.ID,
        description: '',
        value: 0,
        developer_id: null,
        total: 1,
        start: null,
        end: null,
        valid: true,
        device_type: ['iPhone', 'iPad', 'iPod', 'Apple TV', 'Mac'],
      },
      developerList: [],
      developerMap: {},
    }
  },
  mounted() {
    this.refresh()
    this.getDeveloperList()
  },
  methods: {
    toDeveloperName(id) {
      if (!this.developerMap[id]) {
        return '未知开发者'
      }
      return this.developerMap[id].user_name
    },
    refresh() {
      this.$GET(API.general.adminListPromocode, { valid: this.valid })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.list = res.data.list
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getDeveloperList() {
      this.$GET(API.user.userGetDeveloperList, {})
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.developerList = res.data.list
          let map = {}
          for (let developer of res.data.list) {
            map[developer.user_id] = developer
          }
          this.developerMap = map
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    submit() {
      this.$POST(API.general.adminAddPromocode, this.promocode)
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.$message({
            type: 'success',
            message: '已添加优惠券',
          })
          this.refresh()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
  },
}
</script>
<style lang="less" scoped>
.mb-10 {
  margin-bottom: 10px;
}
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;
}
@media screen and (max-width: 550px) {
}
</style>
