<template>
  <div class="paid_apps_wrap">
    <div class="title-1">已购 App</div>
    <div style="margin-bottom: 10px;">
      <el-radio v-model="type" label="store">应用商店</el-radio>
      <el-radio v-model="type" label="custom">定制签名</el-radio>
      <a href="https://support.isign.ren/index.php/archives/99/"
        ><el-button type="success" style="margin-bottom: 20px;"
          >安装指南</el-button
        ></a
      >
    </div>
    <MyApp v-if="type == 'store'" />
    <MyPublish v-if="type == 'custom'" />
  </div>
</template>
<script>
import MyApp from "./MyApp";
import MyPublish from "./MyPublish";
export default {
  name: "",
  components: {
    MyApp,
    MyPublish,
  },
  data() {
    return {
      type: "store",
    };
  },
};
</script>
<style lang="less" scoped>
.paid_apps_wrap {
  padding: 20px;
  width: 600px;
  height: 600px;
  box-sizing: border-box;
}
@media screen and (max-width: 550px) {
  .paid_apps_wrap {
    width: 100%;
  }
}
</style>
