<template>
  <div class="wrap">
    <div class="title-1">开发者申请</div>
    <div class="developer-guide">
      <p class="guide_text">
        <span
          style="
            font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
            font-weight: 400;
          "
        >
          1. 开发一个单页 App 用于申请 ，内容为：
          <br />&nbsp;&nbsp; “xxx申请成为 iSign
          的注册开发者，邮箱：xxx” <br/><el-button
            type="text"
            @click="sampleImgDialogVisible = true"
          >
            查看示例</el-button
          >
          <br />2. 在苹果开发者后台注册 iSign 测试机的 UDID <br />
          <span style="color: #169bd5; font-weight: 900"
            >00008130-000A4D5E0811401C</span
          >
          <br />
          <br />3. 在苹果开发者后台生成包含 iSign 测试机的 ProvisioningProfile
          (选择 Development 中的“iOS App Development”)，将 App 打包为 IPA 文件
          <br /><br />
          4. 在 App Store 下载 “Developer” App，登录后打开“账户”页面并截图<br />
          <el-button type="text" @click="developerAppDialogVisible = true"
            >查看示例</el-button
          >
          <br />5. 上传签名后的 IPA 以及“Developer”App的截图，等待 iSign
          工作人员审核。 <br/> <span style="color: red; font-weight: 600;">最后请添加站长QQ好友：490994868 并备注“申请iSign开发者”。</span> <br />
        </span>
      </p>
      <el-dialog
        :visible.sync="sampleImgDialogVisible"
        custom-class="developer_apply_dialog"
      >
        <img style="width: 100%" src="/images/personal/app_sample.png" alt="" />
      </el-dialog>
      <el-dialog
        :visible.sync="developerAppDialogVisible"
        custom-class="developer_apply_dialog"
      >
        <img style="width: 100%" src="/images/personal/account.png" alt="" />
      </el-dialog>
    </div>

    <div class="dynamic">
      <DeveloperApply
        :devStatus="devStatus"
        v-if="
          devStatus.status == D.USER.DEVELOPER_STATUS.NO_DATA.ID ||
          devStatus.status == D.USER.DEVELOPER_STATUS.FAILED.ID
        "
      />
      <DeveloperValid
        :devStatus="devStatus"
        v-if="devStatus.status == D.USER.DEVELOPER_STATUS.NORMAL.ID || devStatus.status == D.USER.DEVELOPER_STATUS.REST.ID"
      />
      <DeveloperVerifying
        v-if="devStatus.status == D.USER.DEVELOPER_STATUS.PENDING.ID"
      />
    </div>
  </div>
</template>

<script>
import DeveloperApply from "./DeveloperApply";
import DeveloperValid from "./DeveloperValid";
import DeveloperVerifying from "./DeveloperVerifying";
import D from "../../const";
import API from "../../API";
export default {
  name: "Developer",
  components: {
    DeveloperApply,
    DeveloperValid,
    DeveloperVerifying,
  },
  data() {
    return {
      sampleImgDialogVisible: false,
      developerAppDialogVisible: false,
      D: D,
      devStatus: {
        status: "",
      },
    };
  },
  methods: {
    getDeveloperStatus() {
      this.$GET(API.user.getDeveloperStatus, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.devStatus = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
  mounted() {
    this.getDeveloperStatus();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 600px;
  height: 600px;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  //   background-color: #000;
  
  .developer-guide {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    flex-wrap: wrap;
    margin-top: 20px;
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .wrap {
    width: 100%;
    height: auto;
    padding: 20px;
    overflow: scroll;
    box-sizing: border-box;
  }
}
</style>