<template>
  <div class="app_info_wrap">
    <div class="base_info">
      <img class="app_icon" :src="appInfo(app_id, 'logo')" alt="" />
      <div class="app_name">{{ appInfo(app_id, "name") }}</div>
    </div>
    <div class="info_wrap">
      <div class="info_title">简介</div>
      <div class="desc">{{ appInfo(app_id, "desc") }}</div>
    </div>
    <div class="info_wrap">
      <div class="info_title">App 作者</div>
      <div class="desc">{{ appInfo(app_id, "author") }}</div>
    </div>
    <div class="info_wrap">
      <div class="info_title">许可证</div>
      <div class="desc">{{ appInfo(app_id, "license") }}</div>
    </div>
     <div class="info_wrap">
      <div class="info_title">官网地址</div>
     <div>
        官网地址：<a
          v-if="app_id != '请选择'"
          class="link"
          :href="appInfo(app_id, 'url')"
          >{{ appInfo(app_id, "url") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppInfo",
  props: ["app_id"],
  inject: ["appMap"],
  methods: {
    appInfo(app_id, key) {
      if (this.appMap[app_id]) {
        return this.appMap[app_id][key];
      } else {
        if (key == "logo") {
          return "/images/logo.png";
        }
        return "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.link {
  text-decoration: none;
}
.app_info_wrap {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .base_info {
    display: flex;
    margin-bottom: 20px;
    .app_icon {
      width: 80px;
      height: 80px;
      border-radius: 20px;
    }
    .app_name {
      margin-left: 20px;
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
    }
  }
  .extra_info {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: #49a3ff;
    }
  }
}

@media screen and (max-width: 550px) {
  .app_info_wrap {
    display: none;
    .base_info {
      margin-left: 10px;
      margin-right: 10px;
      height: 80px;
      // width: 200px;
      .app_name {
        font-size: 14px;
        font-weight: 600;
      }
      .desc {
        font-size: 12px;
      }
    }
    .app_icon {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
    }
    .extra_info {
      font-size: 12px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      a {
        text-decoration: none;
        color: #49a3ff;
      }
    }
  }
}
</style>
