<template>
  <div class="sub-wrap">
    <div class="success-text">您的开发者认证申请已通过。</div>
    <div class="validtime-text">
      认证有效期至 {{ devStatus.expire_time | timeFilter }}。
    </div>
    <div v-if="showReUploadTips" class="reupload-text">
      <p>
        <span>
          您的开发者认证即将到期（剩余{{expireInDays}}天），请在近期内重新提交审核。
          <br />请重新在苹果开发者中心生成新的 Provisioning Profile。
        </span>
      </p>
      <div class="upload_wrap">
        <AddIPA @uploadComplete="apply"></AddIPA>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../API";
import AddIPA from "../general/AddIPA";
export default {
  props: ["devStatus"],
  name: "DeveloperValid",
  components: { AddIPA },
  computed: {
    showReUploadTips() {
      let ts = Date.parse(new Date());
      if (this.devStatus.expire_time - ts < 259200000) {
        return true;
      }
      return false;
    },
    expireInDays() {
       let ts = Date.parse(new Date());
       let days = (this.devStatus.expire_time - ts) / 86400000;
       return Math.abs(days.toFixed(0));
    }
  },
  methods: {
    apply(resource_id) {
      this.$POST(API.user.applyForDeveloper, { resource_id: resource_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "申请提交成功",
          });
          let that = this;
          setTimeout(() => {
            that.$window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style lang='less' scoped>
.sub-wrap {
  width: 100%;
  .success-text {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 900;
    color: #008c8c;
  }
  .validtime-text {
    margin-top: 10px;
    font-size: 14px;
  }
}
.upload_wrap {
  margin-top: 20px;
  width: 400px;
}

.reupload-text {
  margin-bottom: 10px;
  color: #a30014;
  font-size: 16px;
  font-weight: 900;
}
</style>