<template>
  <div class="link_account_wrap">
    <div class="title-1">关联账号</div>
    <div style="font-size: 14px">
      本页可查看和关联其他iSign的账号的待接单数量。iSign
      账号注册后默认为主账号，只有主账号可以关联子账号。若要取消关联账号，请登录到子账号，然后点击“取消关联”按钮。
    </div>

    <div class="option_wrap" v-if="loaded">
      <el-button
        v-if="isMaster"
        type="success"
        size="small"
        icon="el-icon-plus"
        @click="newLinkAccountDialog = true"
        >关联新账号</el-button
      >

      <el-button v-if="!isMaster" type="danger" size="small" icon="trash" @click="unlink"
        >从主账号取消关联</el-button
      >
    </div>

    <div style="color: red; font-weight: 600; margin-top: 10px">
      当前账号：{{ userInfo.user_name }} ({{ isMaster ? "主" : "子" }}账号)
    </div>

    <el-table
      style="margin-top: 10px"
      :data="waitForPickList"
      max-height="450px"
    >
      <el-table-column label="账号" width="120px">
        <template slot-scope="scope">
          {{ scope.row.username }}
          ({{ scope.row.isMaster ? "主" : "子" }})
        </template>
      </el-table-column>
      <el-table-column label="应用商店" width="70px">
        <template slot-scope="scope">
          {{ scope.row.subscribeCount }}
        </template>
      </el-table-column>
      <el-table-column label="定制签名" width="70px">
        <template slot-scope="scope">
          {{ scope.row.orderCount }}
        </template>
      </el-table-column>
      <el-table-column label="外部订单" width="70px">
        <template slot-scope="scope">
          {{ scope.row.outterCount }}
        </template>
      </el-table-column>
      <el-table-column label="" width="100px">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="small"
            @click="switchAccount(scope.row.user_id)"
            >切换登录</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="请输入被关联账号信息"
      :visible.sync="newLinkAccountDialog"
      custom-class="normal_dialog"
      :modal="true"
    >
      <div class="info_wrap">
        <div class="info_title">用户名</div>
        <div class="info_content_wrap">
          <el-input
            v-model="newLinkUserName"
            placeholder="输入您想关联的账号"
          ></el-input>
        </div>
      </div>
      <div class="info_wrap">
        <div class="info_title">密码</div>
        <div class="info_content_wrap">
          <el-input
            type="password"
            v-model="newLinkUserPassword"
            placeholder="输入被关联账号的密码"
          ></el-input>
        </div>
      </div>
      <div class="option_panel">
        <el-button
          class="option_item"
          type="normal"
          @click="newLinkAccountDialog = false"
          >取消</el-button
        >
        <el-button class="option_item" type="primary" @click="submit"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "../../API";
export default {
  name: "LinkAccount",
  inject: ["userInfo"],
  data() {
    return {
      newLinkAccountDialog: false,
      newLinkUserName: "",
      newLinkUserPassword: "",
      isMaster: false,
      waitForPickList: [],
      loaded: false
    };
  },
  methods: {
    submit() {
      this.$POST(API.user.linkAccount, {
        user_name: this.newLinkUserName,
        password: this.newLinkUserPassword,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          window.location.reload();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getLinkAccountInfo() {
      this.$GET(API.user.getLinkAccountInfo, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.isMaster = res.data.isMaster;
          this.waitForPickList = res.data.waitForPickList;
          this.loaded = true
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    switchAccount(user_id) {
      this.$GET(API.user.switchAccount, { user_id: user_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }

          this.$cookies.set("token", res.data.token);
          window.location.reload();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    unlink() {
      this.$POST(API.user.unlinkAccount, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          window.location.reload();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getLinkAccountInfo();
  },
};
</script>
<style lang="less" scoped>
.link_account_wrap {
  box-sizing: border-box;
  padding: 20px;
  text-align: left;
  width: 600px;
  height: 600px;
  .option_wrap {
    margin-top: 10px;
  }
}
@media screen and (max-width: 550px) {
  .link_account_wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>