<template>
  <div class="create_order_wrap">
    <el-steps style="width: 100%" :active="active" finish-status="success">
      <el-step title="设备"></el-step>
      <el-step title="开发者"></el-step>
      <el-step title="支付"></el-step>
    </el-steps>
    <div class="content_wrap">      
      <SelectDevice
        v-if="active == 0"
        @updateDeviceList="updateDeviceList"
      ></SelectDevice>
      <SelectDeveloper v-if="active == 1" @updateDeveloperId="updateDeveloperId"> </SelectDeveloper>
      <PayOrder
        v-if="active == 2"
        :type="D.ORDER.TYPE.SINGLE.ID"
        :info_id="order_id"
      ></PayOrder>

    </div>
    <el-button v-if="active == 1" style="margin-top: 12px" @click="previous"
      >上一步</el-button
    >
    <el-button v-if="active != 2" style="margin-top: 12px" type="primary" @click="next">{{
      nextButtonText
    }}</el-button>
  </div>
</template>

<script>
import SelectDevice from "./SelectDevice";
import PayOrder from "../../general/Pay/PayOrder";
import SelectDeveloper from './SelectDeveloper'
import API from "../../../API";
export default {
  name: "CreateOrder",
  components: {    
    SelectDevice,
    PayOrder,
    SelectDeveloper
  },
  inject: ['D'],  
  data() {
    return {
      active: 0,
      nextButtonText: "下一步",      
      device_list: [],
      order_id: "",
      developer_id: "",
    };
  },

  props: ['resource_id'],
  methods: {
    previous() {
      if (this.active > 0) {
        this.active--;
      }
    },
    next() {
      switch (this.active) {
        case 0:
          if (this.device_list.length < 1) {
            this.$message("您必须至少选择1个设备");
            return;
          }
          this.active = 1;
          break;
        case 1:
          if (this.developer_id == '') {
            this.$message("您必须至少选择1个开发者");
            return;
          }
          this.addNewOrder();
          break;      
      }
    },
    addNewOrder() {
      this.$POST(API.order.newOrder, {
        resource_id: this.resource_id,
        device_list: this.device_list,
        developer_id: this.developer_id
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.order_id = res.data.order_id;
          this.active = 2;
        })
        .catch((error) => {
          this.$message(`发生错误：${error}`);
        });
    }, 
    updateDeviceList(list) {
      this.device_list = list;
    },
    updateDeveloperId(id) {
      this.developer_id = id;
    }
  },
  watch: {
    active(newValue) {
      switch (newValue) {
        case 0:
          this.nextButtonText = "下一步";
          break;
        case 1:
          this.nextButtonText = "下一步";
          break;
        case 2:
          this.nextButtonText = "提交订单";
          break;
      }
    },
  },
  mounted() {
    this.addButtonClicked = this.handleAddButton;
  },
};
</script>

<style lang="less" scoped>
.create_order_wrap {
  width: 100%;
  box-sizing: border-box;
}
.content_wrap {
  position: relative;
  box-sizing: border-box;
  padding-top: 10px;
}
</style>
