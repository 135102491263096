<template>
  <div v-if="orderInfo" class="order_detail_wrap">
    <SingleOrderDetail :orderInfo="orderInfo"></SingleOrderDetail>
    <UserOrderControls :orderInfo="orderInfo" :type="D.ORDER.TYPE.SINGLE.ID" @refresh="getOrderDetail"/>
  </div>
</template>

<script>
import API from "../../API";
import SingleOrderDetail from "../general/SingleOrderDetail";
import UserOrderControls from "../general/UserOrderControls";
export default {
  name: "OrderDetailUser",
  props: ["order_id"],
  components: { SingleOrderDetail, UserOrderControls },
  inject: ["D"],
  data() {
    return {
      orderInfo: false,
    };
  },
  methods: {
    getOrderDetail() {
      this.$GET(API.order.orderDetailUser, { order_id: this.order_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.orderInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getOrderDetail();
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 550px) {
}
</style>