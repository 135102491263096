<template>
  <div class="app_store_wrap">
    <div class="app_store_content">
      <div class="title-1">应用列表</div>
      <div class="app_list_wrap">
        <router-link
          :to="`/app?id=${appInfo._id}`"
          class="app_item_wrap"
          v-for="(appInfo, index) in app_list"
          :key="index"
        >
          <img class="logo" :src="appInfo.logo" alt="" />
          <div class="base_info">
            <div class="name">{{ appInfo.name }}</div>
            <div class="desc">{{ appInfo.desc }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../API";
export default {
  name: "AppStore",
  data() {
    return {
      app_list: [],
    };
  },
  methods: {
    getAppList() {
      this.$GET(API.general.getAppList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.app_list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getAppList();
  },
};
</script>

<style lang="less" scoped>
.app_store_wrap {
  padding-top: 50px;
  .app_store_content {
    background-color: rgba(255, 255, 255, 0.9);
    min-height: 100vh;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    .title {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .app_list_wrap {
      width: 100%;
      overflow: scroll;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .app_item_wrap {
        border-radius: 20px;
        text-decoration: none;
        cursor: pointer;
        width: 350px;
        min-height: 100px;  
        margin-bottom: 20px;      
        margin-right: 20px;
        border-bottom: 1px solid #dddddd;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px 0 20px;
        .logo {
          width: 80px;
          border-radius: 20px;
          box-shadow: 0 0 1px 1px #dddddd;
        }
        .base_info {
          width: 300px;
          margin-left: 20px;
          padding: 5px 0 5px 0;
          .name {
            margin-top: 5px;
            font-size: 20px;
            font-weight: 600;
            color: black;
          }
          .desc {        
            font-size: 14px;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            line-clamp: 3;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .app_store_wrap {
    .app_store_content {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0px;
      overflow: hidden;
      padding: 20px;
      box-sizing: border-box;
      min-width: 100vw;
      min-height: 100vh;
      .title {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .app_list_wrap {
        width: 100%;
        overflow: auto;
        border-radius: 20px;
        height: auto;
        .app_item_wrap {
          margin-right: 0;
          border-radius: 0px;
          text-decoration: none;
          cursor: pointer;
          // height: 80px;
          width: 100%;
          margin-bottom: 0px;
          border-bottom: 1px solid #dddddd;
          background-color: rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 20px 0 20px;
          box-sizing: border-box;
          .logo {
            width: 50px;
            border-radius: 10px;
            box-shadow: 0 0 1px 1px #dddddd;
          }
          .base_info {
            margin-left: 20px;
            padding: 5px 0 5px 0;
            .name {
              margin-top: 5px;
              font-size: 14px;
              font-weight: 600;
              color: black;
            }
            .desc {        
              font-size: 12px;
              color: #666666;
              display: -webkit-box;
              -webkit-box-orient: vertical;
           
            }
          }
        }
      }
    }
  }
}
</style>