<template>
  <div class="wechat_login_wrap">
    <img src="/images/icon64_wx_logo.png" alt="" />
    <div style="font-size: 20px; margin-top: 20px">正在使用微信登录...</div>
  </div>
</template>
<script>
import API from "../API";
export default {
  name: "WechatLoginPage",
  computed: {
    CODE() {
      return this.$route.query.code;
    },
  },
  data() {
    return {};
  },
  mounted() {
    let token = this.$cookies.get("token");
    if (token == null || token == "") {
      token = "-1";
    }
    this.$POST(API.user.wechatLogin, { token: token, CODE: this.CODE })
      .then((res) => {
        if (res.data.status != "ok") {
          throw new Error(res.data.msg);
        }
        //登录
        if (token == "-1") {
          this.$cookies.set("token", res.data.token);
          this.$message({
          type: 'success',
           message: '微信登录成功！',
          });
          setTimeout(() => {
            document.location.href = "/"
          }, 1000);
        } else {
          //绑定
          this.$message({
            type: "success",
            message: "微信绑定成功！",
          });
          setTimeout(() => {
            document.location.href = "/"
          }, 1000);
        }
      })
      .catch((error) => {
        this.$message({
          type: "error",
          message: error,
        });
      });
  },
};
</script>
<style lang="less" scoped>
.wechat_login_wrap {
  height: calc(100vh - 340px);
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}
@media screen and (max-width: 550px) {
}
</style>