<template>
  <div class="order_detail_wrap">
    <div class="order_info_wrap">
      <div class="order_info_item">IPA文件</div>
      <div class="order_info">
        {{ orderInfo.resource_info.file_name }} ({{ orderInfo.resource_id }})
      </div>
    </div>
    <div class="order_info_wrap">
      <div class="order_info_item">签名类型</div>
      <div class="order_info">
        {{ D.ORDER.TYPE[orderInfo.type].DESC }}
      </div>
    </div>
     <div class="order_info_wrap">
      <div class="order_info_item">开发者</div>
      <div class="order_info">
        {{ orderInfo.developer_info.user_name}}
      </div>
    </div>
    <div class="order_info_wrap">
      <div class="order_info_item">签名有效期</div>
      <div class="order_info">
        {{ orderInfo.developer_info.expire_time | remainDays}} 天
      </div>
    </div>
    <div class="order_info_wrap">
      <div class="order_info_item">设备数量</div>
      <div class="order_info">
        {{ orderInfo.device_list.length }}
      </div>
    </div>
    <div class="order_info_wrap">
      <div class="order_info_item">设备列表</div>
      <div class="order_info">
        <div v-for="(item, index) in orderInfo.device_info_list" :key="index">
          <span v-if="item.signed" style="color: red">
            已上车 {{ item.name }} ({{ item.udid }})
          </span>
          <span v-if="!item.signed">
            {{ item.name }} ({{ item.udid }})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayOrderSingleDetail",
  props: ["orderInfo"],
  inject: ["D"],
};
</script>

<style lang="less" scoped>
.order_detail_wrap {
  border-top: 1px gray solid;
  border-bottom: 1px gray solid;
  margin-bottom: 5px;
  padding: 10px 0 10px 0;
  .order_info_wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    .order_info_item {
      flex: 2;
    }
    .order_info {
      flex: 5;
    }
  }
}
</style>
