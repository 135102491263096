<template>
  <div class="wrap">
    <div class="info_wrap">
      <div>已提现金额：{{withdrawComplete}}</div>
      <el-table :data="withdrawList" max-height="470px">
        <el-table-column label="发起时间" width="120">
          <template slot-scope="scope">
            <span style="margin-left: 5px">{{
              scope.row.time | timeFilter
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类型" width="180">
          <template slot-scope="scope">
            <span style="margin-left: 5px">{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column label="记录" width="180">
          <template slot-scope="scope">
            <img v-if="scope.row.type == 'WITHDRAW_COMPLETE'" :src="scope.row.desc" alt="" style="width: 150px;">
            <div v-else>{{scope.row.desc}}</div>
          </template>
        </el-table-column>
        
        <el-table-column label="金额" width="80">
          <template slot-scope="scope">
            <span style="font-size: 20px;">{{ scope.row.value_change }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="info_wrap">
      <div class="info_section">
        <img :src="alipay" alt="" />
        <img :src="wechat" alt="" />
      </div>
      <div class="info_section">
        <el-radio v-model="approve" label="true">通过</el-radio>
        <el-radio v-model="approve" label="false">拒绝</el-radio>
        <el-input
          v-if="approve == 'false'"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入拒绝理由"
          v-model="msg"
        >
        </el-input>
        <div class="qr_wrap" v-if="approve == 'true'">
          <div v-if="alipay == ''" class="qr_code_info">打款截图</div>
          <div v-if="alipay == ''" class="plus_wrap">
            <i class="el-icon-plus"></i>
          </div>
          <img :src="msg" alt="" />
          <input
            type="file"
            ref="paidScreenShot"
            @change="toBase64()"
            accept="image/jpeg,image/png,image/jpg"
            class="upload_input"
          />
        </div>
      </div>
      <el-button type="primary" @click="handleWithdraw">提交</el-button>
    </div>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "WithdrawDetail",
  props: ["user_id"],
  inject: ["D"],
  data() {
    return {
      withdrawList: [],
      alipay: "",
      wechat: "",
      approve: "false",
      msg: "",
    };
  },
  computed: {
    withdrawComplete() {
      let sum = 0;
      for (let record of this.withdrawList) {
        if (record.type == this.D.RECORD.TYPE.WITHDRAW_COMPLETE.ID) {
          sum += record.value_change
        }
      }
      return sum;
    }
  },
  methods: {
    getWithdrawList() {
      this.$GET(API.user.getWithdrawHistory, {
        developerId: this.user_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.withdrawList = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    getQrCode(user_id, type) {
      this.$GET(API.user.adminGetQrCode, { user_id: user_id, type: type })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          if (type == this.D.ORDER.PAY_METHOD.ALIPAY.ID) {
            this.alipay = res.data.data;
          } else {
            this.wechat = res.data.data;
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    handleWithdraw() {
      //   ['approve', 'user_id', 'msg']
      this.$POST(API.user.adminSetWithdrawComplete, {
        approve: this.approve,
        user_id: this.user_id,
        msg: this.msg,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "完成",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    toBase64() {
      let _this = this;
      let file = this.$refs.paidScreenShot.files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        let img = new Image();
        img.src = e.target.result;
        setTimeout(() => {
          let ratio = img.width / img.height;
          console.log(ratio);
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");
          // 设置宽高度为等同于要压缩图片的尺寸
          let targetWidth = 350,
            targetHeight = 350 / ratio;
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          context.clearRect(0, 0, targetWidth, targetHeight);
          //将img绘制到画布上
          context.drawImage(img, 0, 0, targetWidth, targetHeight);
          console.log(targetWidth, targetHeight);
          _this.msg = canvas.toDataURL("image/jpeg");
        }, 1000);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
  },
  mounted() {
    this.getQrCode(this.user_id, this.D.ORDER.PAY_METHOD.ALIPAY.ID);
    this.getQrCode(this.user_id, this.D.ORDER.PAY_METHOD.WECHAT.ID);
    this.getWithdrawList();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 400px;
  .info_wrap {
    flex: 1;
    img {
      width: 48%;
      border: 1px #666666 solid;
      box-sizing: border-box;
      margin: 2px;
    }
    .info_section {
      margin-top: 20px;
    }
  }
}
</style>