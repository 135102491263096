<template>
  <div class="login_wrap">
    <div>用户名</div>
    <el-input v-model="user_name"></el-input>
    <div style="margin-top: 20px">密码</div>
    <el-input v-model="password" type="password"></el-input>
     <el-button
        type="text"
        @click="toForget"
        >忘记密码</el-button
      >
    <div v-if="needCaptcha">
      <div style="margin-top: 20px">验证码</div>
      <el-input v-model="captcha"></el-input>
      <img
        class="captcha"
        :src="captchaImg"
        @click="getCaptcha()"
        alt="验证码加载中.."
      />
    </div>
    <div class="controls_wrap">
      <el-button type="normal" style="width: 100%" @click="toRegister"
        >没有帐号？现在注册</el-button
      >
      <el-button class="wechatLogin" type="success" style="width: 100%; margin: 10px 0 0 0" @click="wechatLogin"> <img style="width: 18px;" src="/images/icon64_wx_logo.png" alt=""> 微信登录(需先注册账号)</el-button>
      <el-button
        type="primary"
        style="width: 100%; margin: 10px 0 0 0"
        @click="login"
        >登录</el-button
      >
    </div>
  </div>
</template>

<script>
import API from "../../API";
export default {
  data() {
    return {
      user_name: "",
      password: "",
      captcha: "-1",
      captchaImg: "",
      needCaptcha: false,
    };
  },
  methods: {
    exit(status) {
      this.$emit("exit", status);
    },
    login() {
      this.$POST(API.user.login, {
        user_name: this.user_name,
        password: this.password,
        captcha: this.captcha,
      })
        .then((res) => {
          if (res.data.status == "ok") {
            this.$cookies.set("token", res.data.token);
            this.$message({
              type: "success",
              message: "登录成功",
            });
            let href = document.location.href;
            let index = href.indexOf("&login=");
            if (index > 0) {
              document.location.href = href.slice(0, index);
            }
            this.$window.location.reload();
          } else {
            throw new Error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.toString().indexOf("验证码") >= 0) {
            this.needCaptcha = true;
            this.captcha = "";
            this.getCaptcha();
          } else {
            this.needCaptcha = false;
          }
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    getCaptcha() {
      this.$GET(API.user.validation, {})
        .then((res) => {
          if (res.data.status == "ok") {
            this.captchaImg = res.data.base64Img;
          } else {
            throw new Error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    toRegister() {
      this.$emit("register");
    },
    toForget() {
      this.$emit('forget')
    },
    wechatLogin() {
      document.location = "https://open.weixin.qq.com/connect/qrconnect?appid=wx8afb3683c14f97c6&redirect_uri=https%3A%2F%2Fwww.isign.ren%2F%23%2Fwechat_login&response_type=code&scope=snsapi_login"
    }
  },
};
</script>

<style lang="less" scoped>
.login_wrap {
  width: 100%;
  .controls_wrap {
    margin-top: 20px;
    width: 100%;
  }
}
.wechatLogin {
    display: none;
  }

@media screen and (max-width: 550px) {

}

</style>