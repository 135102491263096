<template>
  <div v-if="appInfo" class="edit_app_wrap">
    <div class="app_info_wrap">
      <div class="app_info_item">
        <div class="title">logo</div>
        <div class="logo">
          <ImageUploader
            :url="appInfo.logo"
            :uploadKey="'img/' + appInfo._id + '.jpg'"
            :width="100"
            @percentageUpdated="percentageUpdated"
          ></ImageUploader>
        </div>
        <el-button
          class="delete_logo_button"
          type="danger"
          size="mini"
          @click="deleteAppIcon"
        >
          <i class="el-icon-close"></i>
        </el-button>
      </div>
      <div class="app_info_item">
        <div class="title">名字</div>
        <el-input v-model="appInfo.name"></el-input>
      </div>
      <div class="app_info_item">
        <div class="title">状态</div>
        <div>
          <el-radio v-model="appInfo.status" :label="D.APP.STATUS.OPEN.ID"
            >启用</el-radio
          >
          <el-radio v-model="appInfo.status" :label="D.APP.STATUS.CLOSED.ID"
            >禁用</el-radio
          >
        </div>
      </div>
      <div class="app_info_item">
        <div class="title">简介</div>
        <el-input v-model="appInfo.desc"></el-input>
      </div>
      <div class="app_info_item">
        <div class="title">作者</div>
        <el-input v-model="appInfo.author"></el-input>
      </div>
      <div class="app_info_item">
        <div class="title">许可证</div>
        <el-input v-model="appInfo.license"></el-input>
      </div>
      <div class="app_info_item">
        <div class="title">官网</div>
        <el-input v-model="appInfo.url"></el-input>
      </div>
      <div class="app_info_item">
        <div class="title">金额</div>
        <el-input v-model="appInfo.app_fee"></el-input>
      </div>      
      <div class="app_info_item">
        <div class="title">开发者</div>
        <el-select v-model="appInfo.user_id" placeholder="请选择开发者">
          <el-option
            v-for="item in developer_list"
            :key="item.user_id"
            :label="item.user_name"
            :value="item.user_id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="app_info_item">
        <div class="title">文章cid</div>
        <el-input v-model="appInfo.cid"></el-input>
      </div>
    </div>
    <div class="options_wrap">
      <el-button type="primary" @click="updateAppInfo">提交</el-button>
    </div>
  </div>
</template>
<script>
import API from "../../API";
import ImageUploader from "../general/ImageUploader.vue";
export default {
  name: "EditApp",
  props: ["app_id"],
  inject: ["D"],
  components: {
    ImageUploader,
  },
  data() {
    return {
      appInfo: false,
      developer_list: [],
    };
  },
  methods: {
    percentageUpdated(p) {
      if (p == 1) {
        this.appInfo.logo = `https://cdn.isign.ren/img/${this.appInfo._id}.jpg`;
        this.$message({
          type: "success",
          message: "Logo更新成功",
        });
      }
    },
    updateAppInfo() {
      // ['app_id', 'status', 'logo', 'name', 'desc',
      // 'url', 'author', 'license', 'user_id', 'app_fee']
      this.$POST(API.general.adminUpdateAppInfo, {
        name: this.appInfo.name,
        status: this.appInfo.status,
        logo: this.appInfo.logo,
        desc: this.appInfo.desc,
        url: this.appInfo.url,
        author: this.appInfo.author,
        license: this.appInfo.license,
        user_id: this.appInfo.user_id,
        app_fee: this.appInfo.app_fee,
        app_id: this.app_id,
        cid: this.appInfo.cid
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
          type: 'success',
           message: 'ok',
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getDeveloperList() {
      this.$GET(API.user.applyForDeveloperList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.developer_list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    deleteAppIcon() {
      this.$POST(API.general.deleteAppIcon, { app_id: this.app_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getAppInfo() {
      this.$GET(API.general.getAppInfo, { app_id: this.app_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.appInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getAppInfo();
    this.getDeveloperList();
  },
};
</script>
<style lang="less" scoped>
.edit_app_wrap {
  .app_info_wrap {
    .app_info_item {
      margin-bottom: 10px;
      .title {
        margin-bottom: 5px;
      }
      .logo {
        width: 80px;
        height: 80px;
      }
      .delete_logo_button {
        width: 80px;
      }
    }
  }
}
@media screen and (max-width: 550px) {
}
</style>