<template>
  <div class="nav-wrap">
    <div
      v-for="(section, sectionIndex) in pageConfig"
      :key="sectionIndex"
      class="nav-section"
    >
      <div class="nav-title">{{ section.title }}</div>
      <div class="nav-items-wrap">
        <div
          v-for="(row, rowIndex) in pageConfig[sectionIndex].items"
          :key="rowIndex"
          :class="page == row.key ? 'nav-item nav-item-selected' : 'nav-item'"
          @click="pageChanged(row)"
        >
          <div>{{ row.title }}</div>
          <div v-if="row.msg && row.msg > 0" class="badge">{{ row.msg }}</div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 20px"></div>
  </div>
</template>

<script>
export default {
  name: "CustomNavGuide",
  props: ["pageConfig", "page"], // [{title: "xxx", items: [{title:"aaa", key: "abc", width: "800px", height: "900px"}]}]
  methods: {
    pageChanged(row) {
      this.$emit("pageChanged", row);
    },
  },
  mounted() {
    let _this = this;
    this.$nextTick(() => {
      if (_this.pageConfig.length > 0) {
        _this.selected = _this.pageConfig[0].items[0].key;
      }
    });
  },
};
</script>

<style lang='less' scoped>
.nav-wrap {
  width: 100%;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 5px 0px #aaaaaa;
  .nav-section {
    width: 90%;
    margin: 0 auto 0 auto;
    .nav-title {
      font-size: 20px;
      font-weight: 900;
      text-align: left;
      padding-left: 15px;
      margin-bottom: 10px;
      padding-top: 20px;
    }
    .nav-items-wrap {
      background-color: white;
      border-radius: 10px;
      width: 95%;
      margin: 0 auto 0 auto;
      overflow: hidden;
      .nav-item {
        box-sizing: border-box;
        padding-left: 20px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: left;
        font-weight: 700;
        font-size: 13px;
        border-style: solid;
        border-color: #d7d7d7;
        border-width: 1px;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        .badge {
          background-color: #ff3d3df1;
          color: white;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          margin-right: 10px;
          line-height: 20px;
          text-align: center;
        }
      }
      .nav-item-selected {
        background-color: #49a3ff;
        color: #fff;
        border: none;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .nav-wrap {
    width: 100%;
    background-color: #f2f2f2;
    box-shadow: 0px 0px 0px 0px #aaaaaa;
    .nav-section {
      width: 90%;
      margin: 0 auto 0 auto;
      .nav-title {
        font-size: 26px;
        font-weight: 900;
        text-align: left;
        padding-left: 15px;
        margin-bottom: 10px;
        padding-top: 20px;
      }
      .nav-items-wrap {
        background-color: white;
        border-radius: 10px;
        width: 95%;
        margin: 0 auto 0 auto;
        overflow: hidden;
        .nav-item {
          box-sizing: border-box;
          padding-left: 20px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 50px;
          line-height: 50px;
          text-align: left;
          font-weight: 700;
          font-size: 16px;
          border-style: solid;
          border-color: #d7d7d7;
          border-width: 1px;
          border-left: 0px;
          border-right: 0px;
          border-top: 0px;
          .badge {
            background-color: #ff3d3df1;
            color: white;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-right: 10px;
            line-height: 20px;
            text-align: center;
          }
        }
        .nav-item-selected {
          background-color: #49a3ff;
          color: #fff;
          border: none;
        }
      }
    }
  }
}
</style>