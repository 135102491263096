<template>
  <div class="wrap">
    <div class="title-1">用户列表</div>
    <span>注册用户数：{{ count }}</span>
    <el-table :data="list" style="width: 850px" max-height="400px">
      <el-table-column label="用户名" width="100">
        <template slot-scope="scope">
          <i class="el-icon-user"></i>
          <span style="margin-left: 5px">{{ scope.row.user_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="邮箱" width="200">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="getUserInfo(scope.row._id)"
            >查看</el-button
          >
          <el-button
            size="mini"
            type="normal"
            @click="sendMessage(scope.row._id)"
            >站内信</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="count"
      :page-size="size"
      :current-page="page"
      @current-change="pageChanged"
    >
    </el-pagination>
    <el-dialog title="用户信息" :visible.sync="showUserDetail">
      <UserDetail :userInfo="selectedUserInfo"></UserDetail>
    </el-dialog>
    <el-dialog title="用户信息" :visible.sync="showMessageBox">
      <Message :reciever_id="reciever_id"></Message>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import UserDetail from "./UserDetail";
import Message from './Message';
export default {
  name: "UserList",
  components: { UserDetail, Message },
  data() {
    return {
      list: [],
      count: 0,
      size: 20,
      page: 1,
      reciever_id: "",
      selectedUserInfo: false,
      showUserDetail: false,
      showMessageBox: false
    };
  },
  computed: {
    pageCount() {
      return this.count / this.size;
    },
  },
  methods: {
    pageChanged(page) {
      this.page = page;
      this.update(this.page, this.size);           
    },
    update(page, size) {
      this.$GET(API.user.list, {
        page: page,
        size: size,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.data.list;
          this.count = res.data.data.count;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getUserInfo(user_id) {
      this.$GET(API.user.adminGetUserInfo, { user_id: user_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.selectedUserInfo = res.data.userInfo;
          this.showUserDetail = true;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    sendMessage(reciever_id) {
      this.reciever_id = reciever_id;
      this.showMessageBox = true;
    }
  },
  mounted() {
    this.update(0, this.size);
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;
  
}
</style>