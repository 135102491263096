<template>
  <div v-if="orderInfo" class="order_detail_wrap">
    <SingleOrderDetail :orderInfo="orderInfo"></SingleOrderDetail>
    <DeveloperOrderControls
      :orderInfo="orderInfo"
      :type="'SINGLE'"
      @refresh="getOrderInfo"
    />
  </div>
</template>

<script>
import API from "../../API";
import SingleOrderDetail from "../general/SingleOrderDetail";
import DeveloperOrderControls from "../general/DeveloperOrderControls";
export default {
  name: "OrderDetailDeveloper",
  inject: ["D", "productMap"],
  props: ["order_id"],
  components: { DeveloperOrderControls, SingleOrderDetail },
  data() {
    return {    
      orderInfo: false
    };
  },  
  methods: {   
    getOrderInfo() {
      this.$GET(API.order.orderDetailDeveloper, { order_id: this.order_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.orderInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },    
  },
  mounted() {
    this.getOrderInfo();
  },
};
</script>

<style lang="less" scoped>
.option_panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  .option_item {
    margin-top: 10px;
  }
}

@media screen and (max-width: 550px) {
  .option_panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .option_item {
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
      display: block;
      margin-left: 0px;
    }
  }
}
</style>