<template>
  <div class="wrap">
    <div class="title-1">硬件信息列表</div>
    <el-button
      style="margin: 10px 0 10px 0"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="updateProductVisible = true"
      >添加</el-button
    >
    <el-table :data="list" style="width: 650px" max-height="400px">
      <el-table-column label="型号" width="200">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SOC" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.soc }}</span>
        </template>
      </el-table-column>
      <el-table-column label="内存" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.memory }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="normal" @click="editProduct(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="deleteProduct(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>    

    <el-dialog
      title="新增设备"
      :visible.sync="updateProductVisible"
      custom-class="add_device_dialog"
    >
      <div class="new_device_wrap">
        <el-input type="text" v-model="name" placeholder="输入设备型号"></el-input>
        <el-input type="text" v-model="soc" placeholder="芯片型号"></el-input>
        <el-input type="text" v-model="memory" placeholder="内存大小（GB）"></el-input>
        <div>
          <el-radio v-model="type" label="iPhone">iPhone</el-radio>
          <el-radio v-model="type" label="iPad">iPad</el-radio>
          <el-radio v-model="type" label="iPod">iPod</el-radio>
          <el-radio v-model="type" label="Apple TV">Apple TV</el-radio>
          <el-radio v-model="type" label="Mac">Mac</el-radio>
        </div>
      </div>
      <el-button type="primary" @click="updateProductInfo">添加/更新</el-button>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "ProductList",
  data() {
    return {
      name: "",
      soc: "",
      type: "iPhone",
      memory: "",
      updateProductVisible: false,
      list: [],
    };
  },
  methods: {
    getProductList() {
      this.$GET(API.general.getProductList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    editProduct(row) {
      this.name = row.name;
      this.soc = row.soc;
      this.type = row.type;
      this.memory = row.memory;
      this.updateProductVisible = true;
    },
    deleteProduct(row) {
      this.$POST(API.general.adminDeleteProduct, { product_id: row._id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.getProductList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    updateProductInfo() {
      this.$POST(API.general.adminUpdateProduct, {
        name: this.name,
        soc: this.soc,
        type: this.type,
        memory: this.memory,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
          type: 'success',
           message: 'ok',
          });
          this.updateProductVisible = false;
          this.getProductList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
  mounted() {
    this.getProductList();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;
  
  .new_device_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 250px;
  }
}
</style>