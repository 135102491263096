<template>
  <div class="udid_page">
    <div class="content_wrap">
      <div class="tips">
        请使用 iOS Safari 浏览器 <br />本页面只可获取当前设备的UDID
      </div>
      <div class="content_window">
        <el-steps direction="vertical" :active="active" space="120px">
          <el-step
            title="下载描述文件"
            description="下载后打开“设置”App来安装"
          ></el-step>
          <el-step
            title="添加到您的设备列表"
            description="将您的UDID粘贴到“个人中心”的设备列表"
          ></el-step>
        </el-steps>
        <div class="step_content">
          <div class="step_content_item">
            <el-button
              style="position: relative; top: 20px;"
              type="primary"
              @click="$window.open('udid.mobileconfig')"
              >点此获取 UDID</el-button
            >
          </div>
          <div class="step_content_item">
            <div>
              <el-input
                style="margin-top: 20px;"
                class="input"
                type="text"
                placeholder="您的UDID"
                v-model="udid"
              ></el-input>
            </div>
            <div style="margin-top: 20px;">
              <el-button type="success" @click="copy">{{
                copied ? '已复制！' : '复制UDID'
              }}</el-button>
              <el-button type="primary" @click="saveUdid">添加到设备</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      copied: false,
    }
  },
  computed: {
    udid() {
      return this.$route.query.UDID
    },
    showTips() {
      let userAgent = navigator.userAgent
      if (
        userAgent.indexOf('MicroMessenger') >= 0 ||
        userAgent.indexOf('QQ') >= 0 ||
        userAgent.indexOf('Mobile') < 0
      ) {
        return true
      }
      return false
    },
    active() {
      if (this.udid != '') {
        return 2
      } else {
        return 0
      }
    },
  },
  methods: {
    saveUdid() {
      this.$cookies.set('udid', this.udid)
      this.$router.push('/user?page=MyDevices&subwindow=addDevice')
    },
    copy() {
      navigator.clipboard.writeText(this.udid)
      this.copied = true
    },
  },
}
</script>

<style lang="less" scoped>
.udid_page {
  width: 100vw;
  height: 90vh;
  background-image: url(~/public/images/home/background.png);
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_wrap {
  width: 100%;
  color: white;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 20px;
}

.content_wrap {
  width: 90%;
}
.content_window {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 350px;
  border-radius: 20px;
  background-color: white;
  text-align: left;
  padding: 20px;
  margin: auto;
  overflow: hidden;
}

.step_content {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding-left: 55px;
  padding-top: 50px;
  box-sizing: border-box;
  .step_content_item {
    width: 100%;
    height: 120px;
  }
}

.tips {
  background-color: red;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  margin: 20px 0 20px 0;
  color: white;
}
</style>
