<template>
  <div class="select_device_wrap">   
    <router-link class="add_device" to="user?page=MyDevices">添加设备</router-link>
    <el-table
      :data="device_list"
      v-loading="loading"
      max-height="300px"
      @row-click="selectDevice"
    >
      <el-table-column width="50" label="选择">
        <template slot-scope="scope">
          <el-checkbox v-if="scope.row.selected" checked></el-checkbox>        
        </template>
      </el-table-column>
      <el-table-column label="设备名称" width="200">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="型号" width="200">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.product_id | productName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column width="190" label="UDID">
        <template slot-scope="scope">
          <span>{{ scope.row.udid }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import API from "../../../API";
export default {
  name: "SelectDevice",
  data() {
    return {
      loading: true,
      udid_list: [],
      device_list: [],
      selected_list: []
    };
  },
  methods: {   
    getUserDeviceList() {
      this.$GET(API.user.userGetDeviceList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.device_list = res.data.list;
          for (let deviceInfo of this.device_list) {
           deviceInfo.selected = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    selectDevice(row) {
      let list = [],selected_list = [];   
      for (let deviceInfo of this.device_list) {
        if (deviceInfo._id == row._id) {
          deviceInfo.selected = !deviceInfo.selected;
        }
        if (deviceInfo.selected) {
          selected_list.push(deviceInfo._id);
        }
        list.push(deviceInfo)
      }
      this.device_list = list;
      this.$emit("updateDeviceList", selected_list);
    }    
  },
  mounted() {
    this.getUserDeviceList();
  },
};
</script>

<style lang="less" scoped>
.select_device_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  .add_device {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    color: #409EFF;
  }
}
</style>
