<template>
  <div class="outter_app panel">
    <div class="title-1">外部App</div>
    <el-button type="success" @click="newAppDialogVisible = true" size="normal"
      >新增</el-button
    >
    <el-table
      style="margin-top: 10px"
      :data="appList"
      max-height="450px"
      @row-click="goDetail"
    >
      <el-table-column label="" width="20px">
        <template slot-scope="scope">
          <div :class="scope.row.online ? 'OPEN' : 'CLOSED'"></div>
        </template>
      </el-table-column>
      <el-table-column label="名称" width="150px">
        <template slot-scope="scope">
          {{ scope.row.appName }}
        </template>
      </el-table-column>
      <el-table-column label="appId" width="150px">
        <template slot-scope="scope">
          {{ scope.row._id }}
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="新增App"
      :visible.sync="newAppDialogVisible"
      custom-class="new_outter_app_dialog"
      :modal="false"
    >
      <el-input v-model="newAppName" placeholder="App名称"></el-input>
      <div class="option_panel">
          <el-button class="option_item" @click="addNew" type="success">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "../../API";
export default {
  name: "OutterApp",
  data() {
    return {
      appList: [],
      newAppDialogVisible: false,
      newAppName: "",
    };
  },
  methods: {
    goDetail(row) {
        let appId = row._id;
        this.$router.push(`/outterapp?appId=${appId}`)
    },
    addNew() {
      this.$POST(API.outter.adminAddApp, { name: this.newAppName })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.getAppList();
          this.newAppDialogVisible = false
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getAppList() {
      this.$GET(API.outter.adminGetAppList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.appList = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getAppList();
  },
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 550px) {
}
</style>