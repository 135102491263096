import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueMeta from "vue-meta";
//pages
import Home from "./pages/Home.vue";
import PersonalCenter from "./pages/PersonalCenter.vue";
import AdminPage from "./pages/AdminPage.vue";
import UDID from "./pages/Udid.vue";
import AppStore from "./pages/AppStore";
import AppDetail from "./pages/AppDetail";
import Error404 from "./pages/404.vue";
import TicketDetail from "./pages/TicketDetail";
import BusDetail from "./pages/BusDetail";
import IPADetail from "./pages/IPADetail";
import ResetPassword from "./pages/ResetPassword";
import OutterAppDetailPage from "./pages/OutterAppDetailPage";
import DL from "./pages/DL";
import WechatLoginPage from "./pages/WechatLoginPage";
import BindWechatRedirect from "./pages/BindWechatRedirect";
import BindWechat from "./pages/BindWechat";
import axios from "axios";
import VueCookies from "vue-cookies";
import moment from "moment";
import API from "./API";
import D from "./const";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "./assets/css/global.less"

moment.locale("zh-cn");
const C = require("./config");
const qiniu = require("qiniu-js");
const API_DOMAIN = C.API_DOMAIN;
// import './utils/rem'

axios.defaults.withCredentials = true;

function checkValidArgs(keys, obj) {
  for (let key of keys) {
    if (Array.isArray(obj[key])) {
      continue;
    }
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      return key;
    }
  }
  return true;
}

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no",
    },
  },
  {
    path: "/udid",
    component: UDID,
  },
  {
    path: "/user",
    component: PersonalCenter,
  },
  {
    path: "/adminpage",
    component: AdminPage,
  },
  {
    path: "/appstore",
    component: AppStore,
  },
  {
    path: "/app",
    component: AppDetail,
  },
  {
    path: "/ticket",
    component: TicketDetail,
  },
  {
    path: "/bus",
    component: BusDetail,
  },
  {
    path: "/404",
    component: Error404,
  },
  {
    path: "/ipa",
    component: IPADetail,
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
  },
  {
    path: "/dl",
    component: DL,
  },
  {
    path: "/outterapp",
    component: OutterAppDetailPage,
  },
  {
    path: "/wechat_login",
    component: WechatLoginPage,
  },
  {
    path: "/bindWechatRedirect",
    component: BindWechatRedirect,
  },
  {
    path: "/bindWechat",
    component: BindWechat,
  }
];

const router = new VueRouter({
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length !== 0) {
    next();
  } else {
    next({
      path: "/404",
    });
  }
});

const GET = function(api, params) {
  if (!params) {
    params = {};
  }
  const url = API_DOMAIN + api.fullPath;
  if (api.authLevel != 0) {
    params.token = Vue.$cookies.get("token");
  }
  let checkResult = checkValidArgs(api.args, params);
  if (checkResult != true) {
    Vue.prototype.$message({
      type: "error",
      message: `${api.fullPath} 参数缺失`,
    });
    throw new Error("参数缺失:" + checkResult);
  }
  return axios
    .get(url, {
      params: params,
    })
    .then((res) => {
      console.log(res);
      if (res.data.status == "error" && res.data.msg == "令牌无效") {
        Vue.$cookies.remove("token");
        document.location.href += "&login=true";
        throw new Error("您还未登录或登录信息失效，请重新登录");
      } else {
        return res;
      }
    });
};

const POST = function(api, data) {
  if (!data) {
    data = {};
  }
  const url = API_DOMAIN + api.fullPath;
  let params = {};
  if (api.authLevel != 0) {
    params.token = Vue.$cookies.get("token");
  }
  let checkResult = checkValidArgs(api.args, data);
  if (checkResult != true) {
    Vue.prototype.$message({
      type: "error",
      message: `${api.fullPath} 参数缺失`,
    });
    throw new Error("参数缺失:" + checkResult);
  }
  return axios
    .post(url, data, {
      params: params,
    })
    .then((res) => {
      if (res.data.status == "error" && res.data.msg == "令牌无效") {
        Vue.$cookies.remove("token");
        document.location.href += "&login=true";
        throw new Error("您还未登录或登录信息失效，请重新登录");
      } else {
        return res;
      }
    });
};

Vue.prototype.$qiniu = qiniu;
Vue.prototype.$window = window;
Vue.prototype.$document = document;
Vue.prototype.$axios = axios;
Vue.prototype.$GET = GET;
Vue.prototype.$POST = POST;
Vue.prototype.$moment = moment;
Vue.prototype.$D = D;
Vue.prototype.$loginVisible = false;

Vue.prototype.$ts2date = function(ts) {
  return moment(ts).format("YYYY-MM-DD HH:mm");
};
Vue.prototype.$downloadIpa = (resource_id, name = "isign.ipa") => {
  console.log("下载 " + resource_id);
  GET(API.resource.getDownloadLink, {
    resource_id: resource_id,
  })
    .then((res) => {
      if (res.data.status != "ok") {
        throw new Error(res.data.msg);
      }
      var element = document.createElement("a");
      element.setAttribute("href", res.data.downloadlink + `?attname=${name}`);
      element.setAttribute("download", name);
      element.style.display = "none";
      document.body.appendChild(element);
      console.log("下载" + name);
      console.log(element);
      element.click();
      document.body.removeChild(element);
    })
    .catch((error) => {
      Vue.prototype.$message({
        type: "error",
        message: error.message,
      });
    });
};

Vue.prototype.$isValidUdid = function extractValidUdid(udid) {
  const match = udid.match(/(0000[\d]{4}-00[A-Fa-f\d]+)|([a-fA-F\d]{40})/);
  if (match && match.length > 0) {
    return match[0];
  } else {
    return null;
  }
};

Vue.prototype.$IsValidEmailAddress = (address) => {
  var myReg = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/;
  if (myReg.test(address)) {
    return true;
  } else {
    return false;
  }
};

Vue.prototype.$userInfo = {};
window.onresize = () => {
  if (document.body.clientWidth < 550) {
    Vue.prototype.$isMobile = true;
  } else {
    Vue.prototype.$isMobile = false;
  }
};

window.onload = () => {
  console.log("loaded");
  if (document.body.clientWidth < 550) {
    Vue.prototype.$isMobile = true;
  } else {
    Vue.prototype.$isMobile = false;
  }
};

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueQuillEditor);
Vue.use(VueMeta);
//filters

let filters = {
  productType(product_id) {
    if (App.data().productMap[product_id]) {
      return App.data().productMap[product_id].type;
    }
    return "未知设备";
  },
  productName(product_id) {
    if (App.data().productMap[product_id]) {
      return App.data().productMap[product_id].name;
    }
    return "未知型号";
  },
  timeFilter(timestamp, patten = "YYYY-MM-DD HH:mm") {
    return moment.unix(Math.round(timestamp / 1000)).format(patten);
  },
  dayFilter(timestamp, patten = "YYYY-MM-DD") {
    return moment.unix(Math.round(timestamp / 1000)).format(patten);
  },
  durationFilter(timeDuration) {
    if (timeDuration) {
      return moment.duration(timeDuration).humanize();
    } else {
      return "统计中..";
    }
  },
  durationToNow(time) {
    if (time) {
      return moment.duration(Date.parse(new Date()) - time).humanize() + "前"
    }
    return "最近未接单"
  },
  remainDays(timestamp) {
    return Math.floor((timestamp - Date.parse(new Date())) / 86400000);
  },
  statusName(status) {
    return D.ORDER.STATUS[status].DESC;
  },
  recordType(ID) {
    if (D.RECORD.TYPE[ID]) {
      return D.RECORD.TYPE[ID].DESC;
    } else {
      return "未知";
    }
  },
  deviceName(device_id) {
    if (App.data().deviceMap[device_id]) {
      return App.data().deviceMap[device_id].name;
    }
    return "";
  },
  deviceUdid(device_id) {
    if (App.data().deviceMap[device_id]) {
      return App.data().deviceMap[device_id].udid;
    }
    return "";
  },
  deviceProductName(device_id) {
    let product_id = App.data().deviceMap[device_id].product_id;
    if (App.data().productMap[product_id]) {
      return App.data().productMap[product_id].name;
    }
    return "未知型号";
  },
  deviceProductType(device_id) {
    let product_id = App.data().deviceMap[device_id].product_id;
    if (App.data().productMap[product_id]) {
      return App.data().productMap[product_id].type;
    }
    return "未知类型";
  },
  fileName(resourceInfo) {
    if (!resourceInfo) {
      return "失效资源";
    } else {
      return resourceInfo.file_name;
    }
  },
};

Vue.filter("timeFilter", filters.timeFilter);
Vue.filter("dayFilter", filters.dayFilter);
Vue.filter("durationFilter", filters.durationFilter);
Vue.filter("durationToNow", filters.durationToNow);
Vue.filter("recordType", filters.recordType);
Vue.filter("productName", filters.productName);
Vue.filter("productType", filters.productType);
Vue.filter("remainDays", filters.remainDays);
Vue.filter("statusName", filters.statusName);
Vue.filter("deviceName", filters.deviceName);
Vue.filter("deviceUdid", filters.deviceUdid);
Vue.filter("deviceProductName", filters.deviceProductName);
Vue.filter("deviceProductType", filters.deviceProductType);
Vue.filter("fileName", filters.fileName);

new Vue({
  render: (h) => h(App),
  router: router,
}).$mount("#app");
