<template>
  <div class="home">
    <div class="content">
      <div class="isign-title">iSign</div>
      <div class="section-content user-content">
        <div class="main-title">我要签名</div>
        <div class="slogan">价格实惠，超值上车。<br />仅 RMB 15 起</div>
        <div class="description">
          在iSign，我们可以帮助您安装App Store以外的App。
        </div>
        <div class="actions">
          <el-button @click="scrollToSign" type="text"
            >进一步了解</el-button
          >
          <el-button @click="toAppstore" type="text">立即安装</el-button>
        </div>
      </div>
      <div class="section-content developer-content">
        <div class="main-title">我是开发者</div>
        <div class="slogan">
          人人为我，我为人人。<br />回收 RMB 688 的开发者账号成本，再小赚一笔。
        </div>
        <div class="description">iSign可助您最大化利用您的开发者账户</div>
        <div class="actions">
          <el-button @click="showVideo = !showVideo" type="text">进一步了解</el-button>
          <el-button @click="toUser" type="text">立即接单</el-button>
        </div>
        <el-collapse-transition>
        <iframe v-show="showVideo" class="iframe_video" src="//player.bilibili.com/player.html?aid=765297987&bvid=BV1Br4y1m7gj&cid=473751324&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>    
        </el-collapse-transition>
        <a class="toBilibili" v-if="showVideo" href="https://www.bilibili.com/video/BV1Br4y1m7gj/"><el-button style="margin-bottom: 5px;">在 Bilibili 打开</el-button></a>
      </div>

      <div ref="howto" class="how-to-sign black">
        <div class="main-title" style="margin-bottom: 20px">如何签名安装</div>
        <div class="slogan" style="margin-bottom: 20px">
          在 iSign 签名和安装应用非常简单。
        </div>
        <el-carousel
          :autoplay="false"
          :loop="false"
          type="card"
          height="600px"
          style="width: 700px; margin: auto"
        >
          <el-carousel-item class="sign-flow-card" style="background: #326ae1">
            <div class="flow-title">1 选择 App</div>
            <div class="flow-desc">
              在应用商店选择您想安装的 App。“应用详情”选项卡内包含这个 App
              的基本介绍和使用方法，通常还会附上 App 的相关视频。
            </div>
            <img src="/images/home/store_1.png" alt="" />
          </el-carousel-item>

          <el-carousel-item class="sign-flow-card" style="background: #fd4141">
            <div class="flow-title">2 检查安装要求</div>
            <div class="flow-desc">
              清注意，并不是所有 App
              都能在您的设备上正常安装和运行，为了避免对您造成损失，请一定要仔细阅读应用详情内的<b>“安装要求”</b>。
            </div>
            <img src="/images/home/store_2.png" alt="" />
          </el-carousel-item>

          <el-carousel-item class="sign-flow-card" style="background: #326ae1">
            <div class="flow-title">3 选择开发者</div>
            <div class="flow-desc">
              切换到“签名安装”选项卡，选择一位开发者为您签名。iSign
              根据开发者的有效期来计算签名费用，详情请见此页面上方的“计费说明”。
            </div>
            <img src="/images/home/store_3.png" alt="" />
          </el-carousel-item>

          <el-carousel-item class="sign-flow-card" style="background: #326ae1">
            <div class="flow-title">4 等待签名</div>
            <div class="flow-desc">
              下单后，请等待开发者签名。至于等多久，取决于开发者本身的安排，您可以参考开发者列表的“平均接单时间”。若您比较着急，可以加入
              iSign 的官方用户群询问开发者签名时间。
            </div>
            <img src="/images/home/store_4.png" alt="" />
          </el-carousel-item>

          <el-carousel-item class="sign-flow-card" style="background: #209920">
            <div class="flow-title">5 本机安装</div>
            <div class="flow-desc">
              大功告成！开发者签名完成后，若您绑定了邮箱则会收到签名完成通知。可进入“个人中心>已购
              App”，订单列表中找到对应订单，并点击“本机安装”。
            </div>
            <img src="/images/home/store_5.png" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div ref="sign" class="how-to-sign-mobile black">
        <div class="main-title" style="margin-bottom: 20px">如何签名安装</div>
        <div class="slogan" style="margin-bottom: 20px">
          在 iSign 签名和安装应用非常简单。
        </div>
        <el-collapse>
          <el-collapse-item
            class="sign-flow-card"
            title="1 选择 App"
          >
           <div class="sign-flow-card-content" style="background: #326ae1">
              <div class="flow-desc">
              在应用商店选择您想安装的 App。“应用详情”选项卡内包含这个 App
              的基本介绍和使用方法，通常还会附上 App 的相关视频。
            </div>
            <img src="/images/home/store_1.png" alt="" />
           </div>
          </el-collapse-item>
          <el-collapse-item
            class="sign-flow-card"
            title="2 检查安装要求"
          >
            <div class="sign-flow-card-content" style="background: #fd4141">
              <div class="flow-desc">
                清注意，并不是所有 App
                都能在您的设备上正常安装和运行，为了避免对您造成损失，请一定要仔细阅读应用详情内的<b>“安装要求”</b>。
              </div>
              <img src="/images/home/store_2.png" alt="" />
            </div>
          </el-collapse-item>

          <el-collapse-item
            class="sign-flow-card"
            title="3 选择开发者"
          >
            <div class="sign-flow-card-content" style="background: #326ae1">
              <div class="flow-desc">
                切换到“签名安装”选项卡，选择一位开发者为您签名。iSign
                根据开发者的有效期来计算签名费用，详情请见此页面上方的“计费说明”。
              </div>
              <img src="/images/home/store_3.png" alt="" />
            </div>
          </el-collapse-item>

          <el-collapse-item
            class="sign-flow-card"
            title="4 等待签名"
          >
            <div class="sign-flow-card-content" style="background: #326ae1">
              <div class="flow-desc">
                下单后，请等待开发者签名。至于等多久，取决于开发者本身的安排，您可以参考开发者列表的“平均接单时间”。若您比较着急，可以加入
                iSign 的官方用户群询问开发者签名时间。
              </div>
              <img src="/images/home/store_4.png" alt="" />
            </div>
          </el-collapse-item>

          <el-collapse-item
            class="sign-flow-card"
            title="5 本机安装"
          >
            <div class="sign-flow-card-content" style="background: #209920">
              <div class="flow-desc">
                大功告成！开发者签名完成后，若您绑定了邮箱则会收到签名完成通知。可进入“个人中心>已购
                App”，订单列表中找到对应订单，并点击“本机安装”。
              </div>
              <img src="/images/home/store_5.png" alt="" />
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div ref="dev" class="how-it-works white">
        <div class="main-title">签名原理</div>
        <div class="text">
          为防止盗版软件泛滥，iOS 设备安装 App
          并不如安卓方便。通常来说，安卓手机可以直接使用任意渠道获取的
          App安装包（APK文件）来安装 App。
        </div>
        <div class="text">
          App Store 保护了开发者不受盗版软件的侵害，也保护了 iOS
          设备不受有害应用的侵扰。当 iOS 设备安装一个新的 App 时， iOS 会主动去
          Apple 服务器验证 App 的安装包内置的“签名”是否有效。 开发者将开发完成的
          App 打包成 IPA
          安装包文件时，开发工具会询问开发者使用以下哪种分发方式。
          <img src="/images/home/sign_types.png" alt="" />
        </div>
        <div class="text">
          使用【App Store Connect】分发的 App 安装包，上传至 App Store Connect
          后会先进行一次初审。初审完成后开发者可申请上架 App Store 或提交到
          TestFlight。
        </div>
        <div class="text">
          对于其余三种分发方式，开发者可自建分发服务器或者使用第三方提供的 App
          分发服务。iSign 使用【Development】方式对 App
          安装包重签名，即俗称的“超级签”，通过自建的服务器帮助用户安装已签名的
          App。
        </div>
      </div>
      <div class="how-it-works black">
        <div class="main-title">这是盗版吗？</div>
        <div class="text">
          不，这不是盗版。不在 App Store 安装 App 并不意味着盗版软件。下面列出的
          App 均是开源的 iOS App，受限于 App Store 的审核机制无法上架 App
          Store（App Store 禁止任何模拟器类型的 App），iSign.ren
          为您提供一种安装他们的方式。
          <img src="/images/home/apps.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const config = require("../config");
export default {
  name: "Home",
  components: {},
  data() {
    return {
      C: config,
      showVideo: false
    };
  },
  methods: {
    toAppstore() {
      this.$router.push("/appstore");
    },
    toUser() {
      this.$router.push("/user?page=Developer");
    },
    scrollToSign() {
      this.$refs.howto.scrollIntoView();
    },
  },
};
</script>
<style lang="less">
@import url("../assets/css/reset");
.home {
  width: 100%;
  padding-top: 140px;

  .content {
    width: 100%;
    text-align: center;

    .section-content {
      padding-top: 30px;
      width: 100%;
      min-height: 270px;
    }

    .user-content {
      color: white;
      background-color: rgba(0, 0, 0, 0.6);
      .description {
        color: #e4e3e3;
      }
    }

    .developer-content {
      color: #333333;
      background-color: rgba(255, 255, 255, 0.6);
      .description {
        color: black;
      }
    }

    .isign-title {
      font-size: 80px;
      margin: 50px 0 100px 0;
      color: white;
      font-weight: 700;
    }

    .main-title {
      font-size: 50px;
      font-weight: 700;
    }

    .slogan {
      font-size: 16px;
      margin-top: 20px;
      line-height: 30px;
    }

    .description {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 300;
    }

    .actions {
      margin: 30px auto 0 auto;
      display: flex;
      width: 300px;
      flex-direction: row;
      justify-content: space-between;
      .el-button {
        text-decoration: none;
        font-size: 20px;
        color: #326ae1;
      }
    }
  }
}

.black {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}
.white {
  background-color: rgba(255, 255, 255, 0.6);
  color: #333333;
}
.text {
  margin-top: 20px;
  width: 550px;
  line-height: 30px;
  text-align: left;
  font-size: 16px;
}
.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
  img {
    width: 550px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
}

.how-to-sign {
  padding: 20px;
  .sign-flow-card {
    box-sizing: border-box;
    padding: 20px;
    border-radius: 20px;
    .flow-title {
      text-align: left;
      font-size: 30px;
      margin-bottom: 10px;
    }
    .flow-desc {
      text-align: left;
      font-size: 14px;
      height: 100px;
      color: #eeeeee;
    }
    img {
      width: 250px;
      border-radius: 20px;
    }
  }
}

.how-to-sign-mobile {
  display: none;
}

.iframe_video {
  width: 100%;
  height: 400px;
}

.toBilibili {
  display: none;
}

@media screen and (max-width: 700px) {
  .toBilibili {
    display: block;
  }
  .iframe_video {
width: 100%;
  height: 200px;
  }
  .how-it-works {
    img {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .text {
      width: 320px;
      text-align: left;
      font-size: 14px;
      padding: 0 10px 0 10px;
      box-sizing: border-box;
    }
  }
  .how-to-sign {
    display: none;
  }
  .how-to-sign-mobile {
    display: block;
    padding-top: 20px;
    .sign-flow-card {
      position: relative;
      .sign-flow-card-content {
        background: rgba(0, 0, 0, 0.6);
        padding: 20px;
        height: 500px;
        overflow: hidden;
      }
      .flow-title {
        text-align: left;
        font-size: 30px;
        margin-bottom: 10px;
      }
      .flow-desc {
        text-align: left;
        font-size: 14px;
        color: #eeeeee;
        padding-bottom: 10px;
      }
      img {
        display: block;
        height: 600px;
        border-radius: 20px;
        margin: auto;
      }
    }
  }
}
</style>