let defines = {
    USER: {
        TYPE: {
            NORMAL_USER: {
                ID: "NORMAL_USER",
                DESC: "普通用户",
            },
            DEVELOPER: {
                ID: "DEVELOPER",
                DESC: "开发者",
            },
            ADMIN: {
                ID: "ADMIN",
                DESC: "管理员",
            },
            SUPER_ADMIN: {
                ID: "SUPER_ADMIN",
                DESC: "超级管理员",
            },
            APP: {
                ID: "APP",
                DESC: "外部应用"
            }
        },
        STATUS: {
            NORMAL: {
                ID: "NORMAL",
                DESC: "正常",
            },
            NOT_VERIFIED: {
                ID: "NOT_VERIFIED",
                DESC: "未验证邮箱",
            },
            ORDER_LIMIT: {
                ID: "ORDER_LIMIT",
                DESC: "限制接单/发单",
            },
            BANNED: {
                ID: "BANNED",
                DESC: "封停，限制登陆",
            }
        },
        DEVELOPER_STATUS: {
            NO_DATA: {
                ID: "NO_DATA",
                DESC: "非开发者，也未申请"
            },
            NORMAL: {
                ID: "NORMAL",
                DESC: "开发者"
            },
            REST: {
                ID: "REST",
                DESC: "开发者休息中"
            },
            PENDING: {
                ID: "PENDING",
                DESC: "正在审核中"
            },
            FAILED: {
                ID: "FAILED",
                DESC: "审核不通过"
            },
            EXPIRED: {
                ID: "EXPIRED",
                DESC: "已过期"
            }
        },       
        EMAIL_STATUS: {
            NORMAL: {
                ID: "NORMAL",
                DESC: "正常"
            },
            NOT_VERIFIED: {
                ID: "NOT_VERIFIED",
                DESC: "未验证"
            }
        }
    },
    ORDER: {
        PROMOCODE: {
            NORMAL: {
                ID: "NORMAL",
                DESC: "固定金额"
            },
            FREE: {
                ID: "FREE",
                DESC: "抵扣全额"
            },
            PERCENT: {
                ID: "PERCENT",
                DESC: "按百分比优惠"
            },
        },
        TYPE: {
            NORMAL: {
                ID: "NORMAL",
                DESC: "普通签名",
            },
            CUSTOM: {
                ID: "CUSTOM",
                DESC: "定制签名",
            },
            SUBSCRIBE: {
                ID: "SUBSCRIBE",
                DESC: "上车"
            },
            SINGLE: {
                ID: "SINGLE",
                DESC: "单次签名"
            },
            UPDATE: {
                ID: "UPDATE",
                DESC: "更新"
            }
        },
        ALIPAY_NOTIFY: {
            WAIT_BUYER_PAY: {
                ID: "WAIT_BUYER_PAY",
                DESC: "交易创建，等待买家付款"
            },
            TRADE_CLOSED: {
                ID: "TRADE_CLOSED",
                DESC: "未付款交易超时关闭，或支付完成后全额退款"
            },
            TRADE_SUCCESS: {
                ID: "TRADE_SUCCESS",
                DESC: "交易支付成功"
            },
            TRADE_FINISHED: {
                ID: "TRADE_FINISHED",
                DESC: "交易结束，不可退款"
            }
        },
        STATUS: {
            WAIT_FOR_PAY: {
                ID: "WAIT_FOR_PAY",
                DESC: "等待付款",
            },
            WAIT_FOR_PICK: {
                ID: "WAIT_FOR_PICK",
                DESC: "等待接单",
            },        
            SIGNING: {
                ID: "SIGNING",
                DESC: "签名中",
            },
            SIGNED: {
                ID: "SIGNED",
                DESC: "已签名",
            },
            DONE: {
                ID: "DONE",
                DESC: "订单完成",
            },
            CANCELD: {
                ID: "CANCELD",
                DESC: "订单取消",
            },
            NOT_SIGNABLE: {
                ID: "NOT_SIGNABLE",
                DESC: "验证不可签",
            },
            REQUEST_FOR_REFUND: {
                ID: "REQUEST_FOR_REFUND",
                DESC: "请求退款"
            },
            REFUNDING: {
                ID: "REFUNDING",
                DESC: "退款中"
            },
            REFUNDED: {
                ID: "REFUNDED",
                DESC: "已退款",
            }            
        },
        STATUS_ALLOW_LIST: {
            WAIT_FOR_PAY: ['WAIT_FOR_PICK', 'CANCELD'],
            WAIT_FOR_PICK: ['SIGNING', 'REQUEST_FOR_REFUND','DOWNLOAD'],        
            SIGNING: ['SIGNED', 'NOT_SIGNABLE','DOWNLOAD'],
            SIGNED: ['DONE', 'REQUEST_FOR_REFUND','DOWNLOAD','DOWNLOAD_SIGNED','INSTALL', 'WAIT_FOR_PICK'],
            DONE: ['DOWNLOAD','DOWNLOAD_SIGNED','INSTALL'],
            CANCELD: [],
            NOT_SIGNABLE: ['REFUNDING', 'REQUEST_FOR_REFUND'],
            REQUEST_FOR_REFUND: ['REFUNDING', 'REFUNDED'],
            REFUNDING: ['REFUNDED'],
            REFUNDED: [],
        },
        PAY_METHOD: {
            ALIPAY: {
                ID: "ALIPAY",
                DESC: "支付宝"
            },
            WECHAT: {
                ID: "WECHAT",
                DESC: "微信"
            },
            FAKE_PAY: {
                ID: "FAKE_PAY",
                DESC: "模拟支付"
            }
        },
        WECHAT_TRADE_TYPE: {
            NATIVE: 'NATIVE',
            MWEB: 'MWEB'
        }
    },
    RECORD: {
        STATUS: {
            DONE: {
                ID: "DONE",
                DESC: "完成"
            },
            APPLY: {
                ID: "APPLY",
                DESC: "提现发起",
            },
            APPROVED: {
                ID: "APPROVED",
                DESC: "审核成功",
            },
            SUCCESS: {
                ID: "SUCCESS",
                DESC: "提现成功",
            },
            DECLINED: {
                ID: "DECLINED",
                DESC: "提现审核未通过",
            },
            FAILED: {
                ID: "FAILED",
                DESC: "提现失败",
            }
        },
        TYPE: {
            INCOME: {
                ID: "INCOME",
                DESC: "签名收入"
            },
            WITHDRAW: {
                ID: "WITHDRAW",
                DESC: "提现发起"
            },
            WITHDRAW_COMPLETE: {
                ID: "WITHDRAW_COMPLETE",
                DESC: "提现完成"
            },
            WITHDRAW_FAILED: {
                ID: "WITHDRAW_FAILED",
                DESC: "提现失败"
            }
        }
    },
    APP: {
        STATUS: {
            CLOSED: {
                ID: "CLOSED",
                DESC: "关闭"
            },
            OPEN: {
                ID: "OPEN",
                DESC: "开启"
            }
        }
    }, 
    BUS: {
        STATUS: {
            NORMAL: {
                ID: "NORMAL",
                DESC: "正常"
            },
            CLOSED: {
                ID: "NORMAL",
                DESC: "关闭"
            }
        }
    },
    OUTTER: {
        ORDER_STATUS: {
            PENDING: {
                ID: "PENDING",
                DESC: "等待签名"
            },
            SIGNING: {
                ID: 'SIGNING',
                DESC: '签名中'
            },
            SIGNED: {
                ID: "SIGNED",
                DESC: "已签名"
            },
            DONE: {
                ID: "DONE",
                DESC: "已完成"
            },
            CANCEL: {
                ID: "CANCEL",
                DESC: "名额释放，订单取消"
            },
            TIMEOUT: {
                ID: "TIMEOUT",
                DESC: "开发者签名超时"
            },
            REFUND: {
                ID: "REFUND",
                DESC: "签名失败，退款"
            }
        }
    }
};

module.exports = defines;