<template>
  <div v-if="subscribeInfo" class="order_detail">
    <div class="detail_wrap">
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-document"></i> IPA版本</div>
        <div class="detail_content">
          {{ subscribeInfo.version_info.version }}
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-star-off"></i> 订单金额</div>
        <div class="detail_content">¥ {{ subscribeInfo.price }}</div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-files"></i> 当前状态</div>
        <div class="detail_content">
          {{ subscribeInfo.status | statusName }}
          <span>{{ payMethod }}</span>
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-time"></i> 创建时间</div>
        <div class="detail_content">
          {{ subscribeInfo.status_list[0].time | timeFilter }}
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-info"></i> 订单号</div>
        <div class="detail_content">{{ subscribeInfo._id }}</div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-info"></i> 开发者信息</div>
        <div class="detail_content">
          {{ subscribeInfo.developerInfo.user_name }}
        </div>
        <div class="detail_content" style="font-weight: 600; color: red">
          {{ subscribeInfo.developerInfo.desc }}
        </div>
      </div>
    </div>
    <div class="detail_wrap">
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-notebook-2"></i> 记录</div>
        <div class="status_list">
          <el-timeline :reverse="true">
            <el-timeline-item
              v-for="(activity, index) in subscribeInfo.status_list.slice(
                0,
                subscribeInfo.status_list.length - 1
              )"
              :key="index"
              :timestamp="activity.time | timeFilter"
            >
              {{ activity.status | statusName }} {{ extra(activity) }}
            </el-timeline-item>
            <el-timeline-item
              :timestamp="
                subscribeInfo.status_list[subscribeInfo.status_list.length - 1]
                  .time | timeFilter
              "
              type="success"
            >
              {{ subscribeInfo.status | statusName
              }}{{
                extra(
                  subscribeInfo.status_list[
                    subscribeInfo.status_list.length - 1
                  ]
                )
              }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div v-if="promoInfo" class="detail_item">
        <div class="sub_title" style="color: red">优惠券信息</div>
        <div class="info">
          <span class="title">优惠券代码</span>
          <span class="content">{{ promoInfo.promoInfo.code }}</span>
        </div>
        <div class="info">
          <span class="title">类型</span>
          <span class="content">{{
            D.ORDER.PROMOCODE[promoInfo.promoInfo.type].DESC
          }}</span>
        </div>
        <div class="info">
          <span class="title">优惠金额</span>
          <span class="content">-¥{{ promoInfo.discount }}</span>
        </div>
        <div class="info">
          <span class="title">订单小计</span>
          <span class="content">¥{{ promoInfo.subtotal }}</span>
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-mobile"></i> 设备</div>
        <div>
          {{ subscribeInfo.deviceInfo.name }} - 型号：{{
            subscribeInfo.deviceInfo.product_id | productName
          }}
        </div>
        <div class="device_udid">
          {{ subscribeInfo.deviceInfo.udid }}
        </div>
        <el-button
          style="margin-top: 20px"
          type="primary"
          size="small"
          icon="el-icon-document-copy"
          @click="copy()"
          >{{ copied ? '已复制！' : '复制UDID' }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import D from '../../const'
export default {
  name: 'SubscribeDetail',
  props: ['subscribeInfo'],
  inject: ['D'],
  components: {},
  computed: {
    payMethod() {
      for (let statusInfo of this.subscribeInfo.status_list) {
        if (statusInfo.status == this.D.ORDER.STATUS.WAIT_FOR_PICK.ID) {
          return `(${statusInfo.info.method})`
        }
      }
      return ''
    },
    promoInfo() {
      if (this.subscribeInfo.promoInfo) {
        return this.subscribeInfo.promoInfo
      } else {
        return null
      }
    },
  },
  data() {
    return {
      D: D,
      copied: false,
    }
  },
  methods: {
    extra(status) {
      if (!status.info) {
        return ''
      }
      if (status.info.extra) {
        return `(${status.info.extra})`
      }
      if (status.info.reason) {
        return `(${status.info.reason})`
      }
      if (status.status == 'WAIT_FOR_PICK') {
        return `(支付成功，已派单给开发者，请耐心等待。如有疑问，可在iSign交流群直接联系开发者。iSign QQ 群：910071770)`
      }
      return ''
    },
    copy() {
      navigator.clipboard.writeText(this.subscribeInfo.deviceInfo.udid)
      this.copied = true
    },
  },
}
</script>

<style lang="less" scoped>
.order_detail {
  display: flex;
  flex-direction: row;
  .detail_wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px;
    .detail_item {
      margin-bottom: 20px;
      .info {
        display: flex;
        margin-bottom: 10px;
        .title {
          font-weight: 600;
          width: 100px;
        }
      }
    }
  }
  .status_list {
    padding: 20px 20px 0 20px;
    background-color: rgba(0, 0, 0, 0.03);
    overflow: scroll;
    height: 150px;
  }
  .udid_list {
    height: 80px;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 5px;
    box-sizing: border-box;
  }
  .order_list_wrap {
    height: 100%;
    overflow: scroll;
  }
}
.sub_title {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 10px;
}
.detail_content {
  font-size: 14px;
  max-width: 300px;
}

@media screen and (max-width: 550px) {
  .order_detail {
    display: flex;
    flex-direction: column;
    .detail_wrap {
      padding: 0px;
    }
    .status_list {
      height: auto;
    }
  }
}
</style>
