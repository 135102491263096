<template>
  <div class="order_detail_wrap">
    <SingleOrderDetail v-if="orderInfo" :orderInfo="orderInfo"></SingleOrderDetail>
    <UserOrderControls :orderInfo="orderInfo" :type="'SINGLE'" :isAdmin="true"/>
  </div>
</template>
<script>
import SingleOrderDetail from "../general/SingleOrderDetail";
import UserOrderControls from '../general/UserOrderControls';
import API from "../../API";
export default {
  name: "OrderDetailAdmin",
  props: ["order_id"],
  components: {
    SingleOrderDetail,
    UserOrderControls
  },
  data() {
    return {
      orderInfo: false,
    };
  },
  methods: {
    getOrderInfo() {
      this.$GET(API.order.adminGetOrderInfo, { order_id: this.order_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.orderInfo = res.data.data;
          console.log(this.orderInfo)
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getOrderInfo();
  },
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 550px) {
}
</style>