<template>
  <div v-if="orderInfo" class="order_detail">
    <div class="detail_wrap">
      <div class="detail_item">
        <div class="sub_title">IPA 文件</div>
        <div class="detail_content">
          {{ orderInfo.resource_info.file_name }}
          <span>({{ orderInfo.resource_id }})</span>
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-star-off"></i> 订单金额</div>
        <div class="detail_content">¥ {{ orderInfo.price }}</div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-files"></i> 当前状态</div>
        <div class="detail_content">
          {{ orderInfo.status | statusName }}
          <span>{{ payMethod }}</span>
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-time"></i> 创建时间</div>
        <div class="detail_content">
          {{ orderInfo.status_list[0].time | timeFilter }}
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-time"></i> 有效期至</div>
        <div class="detail_content">
          {{ orderInfo.developer_info.expire_time | timeFilter }}
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-info"></i> 订单号</div>
        <div class="detail_content">
          <a :href="`/#/dl?orderId=${orderInfo._id}`">{{ orderInfo._id }}</a>
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-info"></i> 开发者信息</div>
        <div class="detail_content">
          {{ orderInfo.developer_info.user_name }}
        </div>
        <div class="detail_content" style="font-weight: 600; color: red">
          {{ orderInfo.developer_info.desc }}
        </div>
      </div>
    </div>
    <div class="detail_wrap">
      <div class="detail_item">
        <div class="sub_title"><i class="el-icon-notebook-2"></i> 记录</div>
        <div class="status_list">
          <el-timeline :reverse="true">
            <el-timeline-item
              v-for="(activity, index) in orderInfo.status_list.slice(
                0,
                orderInfo.status_list.length - 1
              )"
              :key="index"
              :timestamp="activity.time | timeFilter"
            >
              {{ activity.status | statusName }} {{ extra(activity) }}
            </el-timeline-item>
            <el-timeline-item
              :timestamp="
                orderInfo.status_list[orderInfo.status_list.length - 1].time
                  | timeFilter
              "
              type="success"
            >
              {{ orderInfo.status | statusName }}
              {{
                extra(orderInfo.status_list[orderInfo.status_list.length - 1])
              }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title">
          <i class="el-icon-mobile-phone"></i> 设备列表
        </div>
        <div class="detail_content udid_list">
          <div
            v-for="(deviceInfo, index) in orderInfo.device_info_list"
            :key="index"
            style="white-space: nowrap"
          >
            {{ deviceInfo.name }} ({{ deviceInfo.udid }})
          </div>
        </div>
      </div>
      <div class="detail_item">
        <div class="sub_title">
          <i class="el-icon-mobile-phone"></i> 设备概览
        </div>
        <div class="detail_content">{{ deviceGlance }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleOrderDetail',
  props: ['orderInfo'],
  components: {},
  inject: ['D', 'productMap'],
  computed: {
    deviceGlance() {
      let devices = {}
      for (let deviceInfo of this.orderInfo.device_info_list) {
        let productId = deviceInfo.product_id
        let type = this.productMap[productId].type
        if (!devices[type]) {
          devices[type] = 1
        } else {
          devices[type] += 1
        }
      }
      let glance = ''
      for (let type of Object.keys(devices)) {
        glance += `${type}: ${devices[type]}台 `
      }
      return glance
    },
    payMethod() {
      for (let statusInfo of this.orderInfo.status_list) {
        if (statusInfo.status == this.D.ORDER.STATUS.WAIT_FOR_PICK.ID) {
          return `(${statusInfo.info.method})`
        }
      }
      return ''
    },
  },
  methods: {
    extra(status) {
      if (!status.info) {
        return ''
      }
      if (status.info.extra) {
        return `(${status.info.extra})`
      }
      return ''
    },
  },
}
</script>

<style lang="less" scoped>
.order_detail {
  display: flex;
  flex-direction: row;
  .detail_wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px;
    .detail_item {
      margin-bottom: 20px;
      max-width: 300px;
    }
  }
  .status_list {
    padding: 20px 20px 0 20px;
    background-color: rgba(0, 0, 0, 0.03);
    overflow: scroll;
    height: 150px;
  }
  .udid_list {
    height: 80px;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 5px;
    box-sizing: border-box;
  }
  .order_list_wrap {
    height: 100%;
    overflow: scroll;
  }
}
.sub_title {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 10px;
}
.detail_content {
  font-size: 14px;
}

@media screen and (max-width: 550px) {
  .order_detail {
    display: flex;
    flex-direction: column;
    .detail_wrap {
      padding: 0px;
    }
    .status_list {
      height: auto;
    }
  }
}
</style>
