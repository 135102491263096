const NO_AUTH = 0
const LOGIN = 1
const DEVELOPER = 2
const ADMIN = 3
const SUPER_ADMIN = 4
const APP = 5

const API = {
    user: {
        forget: {
            fullPath: '/api/user/forget',
            path: '/forget',
            authLevel: NO_AUTH,
            desc: '忘记密码',
            args: ["email", "captcha"],
            method: 'post',
            mailVerify: false
        },
        resetpassword: {
            fullPath: '/api/user/resetpassword',
            path: '/resetpassword',
            authLevel: NO_AUTH,
            desc: '重置密码',
            args: ['password', 'token'],
            method: 'post',
            mailVerify: false
        },
        changePassword: {
            fullPath: '/api/user/changePassword',
            path: '/changePassword',
            authLevel: LOGIN,
            desc: '修改密码',
            args: ['oldPassword', 'newPassword'],
            method: 'post',
            mailVerify: false
        },
        appleUdidPost: {
            fullPath: '/api/user/appleUdidPost',
            path: '/appleUdidPost',
            authLevel: NO_AUTH,
            desc: '用户安装描述文件后设备post的接口',
            args: [],
            method: 'post',
            mailVerify: false
        },
        register: {
            fullPath: "/api/user/register",
            path: "/register",
            authLevel: NO_AUTH,
            desc: '用户注册',
            args: ['user_name', 'password', 'captcha'],
            method: 'post',
            mailVerify: false
        },
        validation: {
            fullPath: "/api/user/validation",
            path: "/validation",
            authLevel: NO_AUTH,
            desc: '生成图片验证码',
            args: [],
            method: 'get',
            mailVerify: false
        },
        mailActivation: {
            fullPath: "/api/user/mailActivation",
            path: "/mailActivation",
            authLevel: LOGIN,
            desc: '激活邮箱',
            args: ["captcha"],
            method: 'post',
            mailVerify: false
        },
        login: {
            fullPath: "/api/user/login",
            path: "/login",
            authLevel: NO_AUTH,
            desc: '登陆接口',
            args: ['user_name', 'password'],
            method: 'post',
            mailVerify: false
        },
        bindEmail: {
            fullPath: "/api/user/bindEmail",
            path: "/bindEmail",
            authLevel: LOGIN,
            desc: '绑定邮箱',
            args: ['email', 'captcha'],
            method: 'post',
            mailVerify: false
        },
        userUpdateDevice: {
            fullPath: "/api/user/userUpdateDevice",
            path: "/userUpdateDevice",
            authLevel: LOGIN,
            desc: '新增设备',
            args: ['udid', 'name', 'product_id'],
            method: 'post',
            mailVerify: false
        },
        userGetDeviceList: {
            fullPath: "/api/user/userGetDeviceList",
            path: "/userGetDeviceList",
            authLevel: LOGIN,
            desc: '用户获取自己的设备列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        userDeleteDevice: {
            fullPath: "/api/user/userDeleteDevice",
            path: "/userDeleteDevice",
            authLevel: LOGIN,
            desc: '用户删除某个设备',
            args: ['device_id'],
            method: 'post',
            mailVerify: false
        },
        getUserInfo: {
            fullPath: "/api/user/getUserInfo",
            path: "/getUserInfo",
            authLevel: LOGIN,
            desc: '用户获取自己的信息',
            args: [],
            method: 'get',
            mailVerify: false
        },
        loginByAlipay: {
            fullPath: "/api/user/loginByAlipay",
            path: "/loginByAlipay",
            authLevel: NO_AUTH,
            desc: '支付宝登陆',
            args: [],
            method: 'get',
            mailVerify: false
        },
        list: {
            fullPath: "/api/user/list",
            path: "/list",
            authLevel: ADMIN,
            desc: '列出所有用户',
            args: ['page', 'size'],
            method: 'get',
            mailVerify: false
        },
        adminGetUserInfo: {
            fullPath: "/api/user/adminGetUserInfo",
            path: "/adminGetUserInfo",
            authLevel: ADMIN,
            desc: '管理员获取用户信息',
            args: ['user_id'],
            method: 'get',
            mailVerify: false
        },
        setUserType: {
            fullPath: "/api/user/setUserType",
            path: "/setUserType",
            authLevel: ADMIN,
            desc: '管理员设置用户类型',
            args: ['user_id', 'type'],
            method: 'post',
            mailVerify: false
        },
        setUserStatus: {
            fullPath: "/api/user/setUserStatus",
            path: "/setUserStatus",
            authLevel: ADMIN,
            desc: '管理员设置用户状态',
            args: ['user_id', 'status'],
            method: 'post',
            mailVerify: false
        },
        getDeveloperStatus: {
            fullPath: '/api/user/getDeveloperStatus',
            path: '/getDeveloperStatus',
            authLevel: LOGIN,
            desc: '用户查询自己的开发者状态',
            args: [],
            method: 'get',
            mailVerify: false
        },
        applyForDeveloper: {
            fullPath: '/api/user/applyForDeveloper',
            path: '/applyForDeveloper',
            authLevel: LOGIN,
            desc: '用户申请成为开发者',
            args: ['resource_id', 'img_url'],
            method: 'post',
            mailVerify: true
        },
        cancelApplyForDeveloper: {
            fullPath: '/api/user/cancelApplyForDeveloper',
            path: '/cancelApplyForDeveloper',
            authLevel: LOGIN,
            desc: '用户取消申请成为开发者',
            args: [],
            method: 'get',
            mailVerify: true
        },
        applyForDeveloperList: {
            fullPath: '/api/user/applyForDeveloperList',
            path: '/applyForDeveloperList',
            authLevel: ADMIN,
            desc: '管理员查看申请开发者列表',
            args: [],
            method: 'post',
            mailVerify: false
        },
        userGetDeveloperList: {
            fullPath: '/api/user/userGetDeveloperList',
            path: '/userGetDeveloperList',
            authLevel: LOGIN,
            desc: '用户查看可签开发者列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        adminConfirmDeveloperStatus: {
            fullPath: '/api/user/adminConfirmDeveloperStatus',
            path: '/adminConfirmDeveloperStatus',
            authLevel: ADMIN,
            desc: '管理员通过/拒绝开发者申请请求',
            args: ['user_id', 'confirm', 'msg', 'expire_time'],
            method: 'post',
            mailVerify: false
        },
        adminResetDeveloperDevices: {
            fullPath: '/api/user/adminResetDeveloperDevices',
            path: '/adminResetDeveloperDevices',
            authLevel: ADMIN,
            desc: '管理员重置开发者设备列表',
            args: ['user_id'],
            method: 'post',
            mailVerify: false
        },
        getMessageBox: {
            fullPath: '/api/user/getMessageBox',
            path: '/getMessageBox',
            authLevel: LOGIN,
            desc: '获取站内信',
            args: ['readed'],
            method: 'get',
            mailVerify: false
        },
        setMessageReaded: {
            fullPath: '/api/user/setMessageReaded',
            path: '/setMessageReaded',
            authLevel: LOGIN,
            desc: '全部已读',
            args: [],
            method: 'post',
            mailVerify: false
        },
        adminSendMessage: {
            fullPath: '/api/user/adminSendMessage',
            path: '/adminSendMessage',
            authLevel: ADMIN,
            desc: '管理员发送消息',
            args: ['reciever_id', 'title', 'content', 'url'],
            method: 'post',
            mailVerify: false
        },
        getWalletInfo: {
            fullPath: '/api/user/getWalletInfo',
            path: '/getWalletInfo',
            authLevel: DEVELOPER,
            desc: '开发者查看钱包',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getWalletChangeRecord: {
            fullPath: '/api/user/getWalletChangeRecord',
            path: '/getWalletChangeRecord',
            authLevel: DEVELOPER,
            desc: '开发者查看钱包变化',
            args: [],
            method: 'get',
            mailVerify: false
        },
        withdraw: {
            fullPath: '/api/user/withdraw',
            path: '/withdraw',
            authLevel: DEVELOPER,
            desc: '开发者提现',
            args: ['value', 'type'],
            method: 'post',
            mailVerify: true
        },
        getWithDrawList: {
            fullPath: '/api/user/getWithDrawList',
            path: '/getWithDrawList',
            authLevel: ADMIN,
            desc: '查看提现列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getWithdrawHistory: {
            fullPath: '/api/user/getWithdrawHistory',
            path: '/getWithdrawHistory',
            authLevel: ADMIN,
            desc: '提现历史',
            args: ['developerId'],
            method: 'get',
            mailVerify: false
        },
        adminSetWithdrawComplete: {
            fullPath: '/api/user/adminSetWithdrawComplete',
            path: '/adminSetWithdrawComplete',
            authLevel: SUPER_ADMIN,
            desc: '管理员设置提现完成',
            args: ['approve', 'user_id', 'msg'],
            method: 'post',
            mailVerify: false
        },
        updateDeveloperQRCode: {
            fullPath: '/api/user/updateDeveloperQRCode',
            path: '/updateDeveloperQRCode',
            authLevel: DEVELOPER,
            desc: '开发者上传收款码',
            args: ['img', 'type'],
            method: 'post',
            mailVerify: false
        },
        getQrCode: {
            fullPath: '/api/user/getQrCode',
            path: '/getQrCode',
            authLevel: DEVELOPER,
            desc: '开发者读取自己的二维码',
            args: ['type'],
            method: 'get',
            mailVerify: false
        },
        adminGetQrCode: {
            fullPath: '/api/user/adminGetQrCode',
            path: '/adminGetQrCode',
            authLevel: SUPER_ADMIN,
            desc: '管理员读取自己的二维码',
            args: ['user_id', 'type'],
            method: 'get',
            mailVerify: false
        },
        developerDeleteExsisVerifyImg: {
            fullPath: '/api/user/developerDeleteExsisVerifyImg',
            path: '/developerDeleteExsisVerifyImg',
            authLevel: LOGIN,
            desc: '开发者删除认证图片',
            args: [],
            method: 'post',
            mailVerify: false
        },
        developerGetDeveloperInfo: {
            fullPath: '/api/user/developerGetDeveloperInfo',
            path: '/developerGetDeveloperInfo',
            authLevel: DEVELOPER,
            desc: '开发者查看开发者信息',
            args: [],
            method: 'get',
            mailVerify: false
        },
        developerEditDeveloperInfo: {
            fullPath: '/api/user/developerEditDeveloperInfo',
            path: '/developerEditDeveloperInfo',
            authLevel: DEVELOPER,
            desc: '开发者修改开发者信息',
            args: ['user_name', 'status', 'iphone', 'ipad', 'ipod', 'apple_tv', 'mac', 'desc', 'accept_outter'],
            method: 'post',
            mailVerify: false
        },
        adminSetCanApplyDeveloper: {
            fullPath: '/api/user/adminSetCanApplyDeveloper',
            path: '/adminSetCanApplyDeveloper',
            authLevel: ADMIN,
            desc: '管理员设置允许新开发者申请',
            args: ["open"],
            method: 'post',
            mailVerify: false
        },
        adminSetDeveloperStatus: {
            fullPath: '/api/user/adminSetDeveloperStatus',
            path: '/adminSetDeveloperStatus',
            authLevel: ADMIN,
            desc: '管理员设置开发者状态',
            args: ["status", 'user_id'],
            method: 'post',
            mailVerify: false
        },
        getCanApplyDeveloper: {
            fullPath: '/api/user/getCanApplyDeveloper',
            path: '/getCanApplyDeveloper',
            authLevel: LOGIN,
            desc: '获取是否可以申请开发者',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getWaitForPick: {
            fullPath: '/api/user/getWaitForPick',
            path: '/getWaitForPick',
            authLevel: DEVELOPER,
            desc: '获取待签名列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        wechatLogin: {
            fullPath: '/api/user/wechatLogin',
            path: '/wechatLogin',
            authLevel: NO_AUTH,
            desc: '微信登录或绑定',
            args: ["token", "CODE"],
            method: 'post',
            mailVerify: false
        },
        linkAccount: {
            fullPath: '/api/user/linkAccount',
            path: '/linkAccount',
            authLevel: DEVELOPER,
            desc: '链接开发者账号',
            args: ['user_name', 'password'],
            method: 'post',
            mailVerify: false
        },
        unlinkAccount: {
            fullPath: '/api/user/unlinkAccount',
            path: '/unlinkAccount',
            authLevel: LOGIN,
            desc: '取消链接开发者账号',
            args: [],
            method: 'post',
            mailVerify: false
        },
        getLinkAccountInfo: {
            fullPath: '/api/user/getLinkAccountInfo',
            path: '/getLinkAccountInfo',
            authLevel: LOGIN,
            desc: '获取关联账号信息',
            args: [],
            method: 'get',
            mailVerify: false
        },
        switchAccount: {
            fullPath: '/api/user/switchAccount',
            path: '/switchAccount',
            authLevel: LOGIN,
            desc: '切换登录',
            args: ['user_id'],
            method: 'get',
            mailVerify: false
        },
        wechatBind: {
            fullPath: '/api/user/wechatBind',
            path: '/wechatBind',
            authLevel: NO_AUTH,
            desc: '绑定微信',
            args: ['user_name', 'password', 'code'],
            method: 'post',
            mailVerify: false
        }

    },
    order: {
        newOrder: {
            fullPath: '/api/order/newOrder',
            path: "/newOrder",
            authLevel: LOGIN,
            desc: '用户发布新订单',
            args: ['resource_id', 'device_list', 'developer_id'],
            method: 'post',
            mailVerify: true
        },
        cancelOrder: {
            fullPath: '/api/order/cancelOrder',
            path: "/cancelOrder",
            authLevel: LOGIN,
            desc: '用户取消订单',
            args: ['order_id', 'reason'],
            method: 'post',
            mailVerify: true
        },
        userApplyRefund: {
            fullPath: '/api/order/userApplyRefund',
            path: "/userApplyRefund",
            authLevel: LOGIN,
            desc: '用户申请退款',
            args: ['order_id', 'reason'],
            method: 'post',
            mailVerify: true
        },
        orderList: {
            fullPath: '/api/order/orderList',
            path: "/orderList",
            authLevel: LOGIN,
            desc: '用户自己的订单列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        orderCenter: {
            fullPath: '/api/order/orderCenter',
            path: "/orderCenter",
            authLevel: DEVELOPER,
            desc: '开发者查看接单中心列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        lockOrder: {
            fullPath: '/api/order/lockOrder',
            path: "/lockOrder",
            authLevel: DEVELOPER,
            desc: '开发者锁定订单',
            args: ['order_id'],
            method: 'post',
            mailVerify: true
        },
        signDone: {
            fullPath: '/api/order/signDone',
            path: "/signDone",
            authLevel: DEVELOPER,
            desc: '开发者标记签名完成',
            args: ['order_id', 'resource_id'],
            method: 'post',
            mailVerify: true
        },
        listAll: {
            fullPath: '/api/order/listAll',
            path: "/listAll",
            authLevel: ADMIN,
            desc: '管理后台的订单列表',
            args: ['page', 'size'],
            method: 'get',
            mailVerify: false
        }, //
        fakePay: {
            fullPath: '/api/order/fakePay',
            path: "/fakePay",
            authLevel: SUPER_ADMIN,
            desc: 'FAKE PAY',
            args: ['order_id'],
            method: 'post',
            mailVerify: false
        },
        deleteOrder: {
            fullPath: '/api/order/deleteOrder',
            path: "/deleteOrder",
            authLevel: ADMIN,
            desc: '管理员删除订单',
            args: ['order_id'],
            method: 'post',
            mailVerify: false
        },
        developerOrderList: {
            fullPath: '/api/order/developerOrderList',
            path: "/developerOrderList",
            authLevel: DEVELOPER,
            desc: '开发者负责的订单列表',
            args: ['type'],
            method: 'get',
            mailVerify: false
        },
        adminGetDeveloperOrderList: {
            fullPath: '/api/order/adminGetDeveloperOrderList',
            path: "/adminGetDeveloperOrderList",
            authLevel: ADMIN,
            desc: '管理员获取开发者负责的订单列表',
            args: ['user_id'],
            method: 'get',
            mailVerify: false
        },
        orderDetailUser: {
            fullPath: '/api/order/orderDetailUser',
            path: "/orderDetailUser",
            authLevel: LOGIN,
            desc: '用户的订单信息详情',
            args: ['order_id'],
            method: 'get',
            mailVerify: false
        }, //
        orderDetailDeveloper: {
            fullPath: '/api/order/orderDetailDeveloper',
            path: "/orderDetailDeveloper",
            authLevel: DEVELOPER,
            desc: '单次签名订单信息详情',
            args: ['order_id'],
            method: 'get',
            mailVerify: false
        },
        userConfirm: {
            fullPath: '/api/order/userConfirm',
            path: "/userConfirm",
            authLevel: LOGIN,
            desc: '用户确认收货',
            args: ['order_id'],
            method: 'post',
            mailVerify: true
        }, //        
        alipayNotify: {
            fullPath: '/api/order/alipayNotify',
            path: "/alipayNotify",
            authLevel: NO_AUTH,
            desc: '支付宝通知',
            args: [],
            method: 'all',
            mailVerify: false
        },
        wechatNotify: {
            fullPath: '/api/order/wechatNotify',
            path: "/wechatNotify",
            authLevel: NO_AUTH,
            desc: '微信支付通知',
            args: [],
            method: 'all',
            mailVerify: false
        },
        payOrder: {
            fullPath: '/api/order/payOrder',
            path: "/payOrder",
            authLevel: LOGIN,
            desc: '订单付款',
            args: ['order_id', 'method'],
            method: 'get',
            mailVerify: false
        },
        adminGetRefundList: {
            fullPath: '/api/order/adminGetRefundList',
            path: "/adminGetRefundList",
            authLevel: ADMIN,
            desc: '退款列表',
            args: ['page', 'size'],
            method: 'get',
            mailVerify: false
        },
        adminHandelRefund: {
            fullPath: '/api/order/adminHandelRefund',
            path: "/adminHandelRefund",
            authLevel: ADMIN,
            desc: '管理员处理退款',
            args: ['order_id', 'confirm', 'reason'],
            method: 'post',
            mailVerify: false
        },
        adminGetOrderInfo: {
            fullPath: '/api/order/adminGetOrderInfo',
            path: "/adminGetOrderInfo",
            authLevel: ADMIN,
            desc: '管理员获取单次签名订单详情',
            args: ['order_id'],
            method: 'get',
            mailVerify: false
        },
        userRequestResign: {
            fullPath: '/api/order/userRequestResign',
            path: "/userRequestResign",
            authLevel: LOGIN,
            desc: '用户请求重新签名',
            args: ['order_id'],
            method: 'post',
            mailVerify: false
        },
        userRequestRefund: {
            fullPath: '/api/order/userRequestRefund',
            path: "/userRequestRefund",
            authLevel: LOGIN,
            desc: '用户请求退款',
            args: ['order_id', 'reason'],
            method: 'post',
            mailVerify: false
        },
        userRequestCancelRefund: {
            fullPath: '/api/order/userRequestCancelRefund',
            path: "/userRequestCancelRefund",
            authLevel: LOGIN,
            desc: '用户请求取消退款',
            args: ['order_id'],
            method: 'post',
            mailVerify: false
        },
        developerConfirmNotSignable: {
            fullPath: '/api/order/developerConfirmNotSignable',
            path: "/developerConfirmNotSignable",
            authLevel: DEVELOPER,
            desc: '开发者确认无法签名',
            args: ['order_id', 'reason'],
            method: 'post',
            mailVerify: false
        },
        getOrderListWithResourceId: {
            fullPath: '/api/order/getOrderListWithResourceId',
            path: "/getOrderListWithResourceId",
            authLevel: LOGIN,
            desc: '通过资源id获取订单列表',
            args: ['resource_id'],
            method: 'get',
            mailVerify: false
        }
    },
    resource: {
        getDownloadLink: {
            fullPath: '/api/resource/getDownloadLink',
            path: "/getDownloadLink",
            authLevel: LOGIN,
            desc: '获得下载token',
            args: ['resource_id'],
            method: 'get',
            mailVerify: true
        },
        getDownloadPlist: {
            fullPath: '/api/resource/getDownloadPlist',
            path: "/getDownloadPlist/:resource_id",
            authLevel: LOGIN,
            desc: '生成在线安装用的plist',
            args: [],
            method: 'get',
            mailVerify: false
        },
        postNewUploadedFile: {
            fullPath: '/api/resource/postNewUploadedFile',
            path: "/postNewUploadedFile",
            authLevel: LOGIN,
            desc: '创建新文件',
            args: ['file_name', 'md5'],
            method: 'post',
            mailVerify: true
        },       
        updateResouceInfo: {
            fullPath: '/api/resource/updateResouceInfo',
            path: "/updateResouceInfo",
            authLevel: LOGIN,
            desc: '更新文件信息',
            args: ['resorceInfo'],
            method: 'post',
            mailVerify: false
        },
        getResourceListWithUserId: {
            fullPath: '/api/resource/getResourceListWithUserId',
            path: "/getResourceListWithUserId",
            authLevel: LOGIN,
            desc: '用户获取自己上传的文件列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getPublicResourceList: {
            fullPath: '/api/resource/getPublicResourceList',
            path: "/getPublicResourceList",
            authLevel: LOGIN,
            desc: '获取公开IPA列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getPublicResourceListAdmin: {
            fullPath: '/api/resource/getPublicResourceListAdmin',
            path: "/getPublicResourceListAdmin",
            authLevel: ADMIN,
            desc: '管理员查看公开文件列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        list: {
            fullPath: '/api/resource/list',
            path: "/list",
            authLevel: ADMIN,
            desc: '管理员列出所有文件',
            args: ['page', 'size'],
            method: 'get',
            mailVerify: false
        },
        setResourcePublic: {
            fullPath: '/api/resource/setResourcePublic',
            path: "/setResourcePublic",
            authLevel: LOGIN,
            desc: '设置文件是否公开',
            args: ['resource_id', 'is_public'],
            method: 'post',
            mailVerify: false
        },
        setResourcePublicAdmin: {
            fullPath: '/api/resource/setResourcePublicAdmin',
            path: "/setResourcePublicAdmin",
            authLevel: ADMIN,
            desc: '设置文件是否公开',
            args: ['resource_id', 'is_public'],
            method: 'post',
            mailVerify: false
        },
        uploadComplete: {
            fullPath: '/api/resource/uploadComplete',
            path: "/uploadComplete",
            authLevel: LOGIN,
            desc: '文件上传成功',
            args: ['resource_id', 'bid'],
            method: 'post',
            mailVerify: true
        },
        deleteResource: {
            fullPath: '/api/resource/deleteResource',
            path: "/deleteResource",
            authLevel: LOGIN,
            desc: '删除文件',
            args: ['resource_id'],
            method: 'post',
            mailVerify: false
        },
        adminDeleteResource: {
            fullPath: '/api/resource/adminDeleteResource',
            path: "/adminDeleteResource",
            authLevel: ADMIN,
            desc: '管理员删除文件',
            args: ['resource_id'],
            method: 'post',
            mailVerify: false
        },
        renameResource: {
            fullPath: '/api/resource/renameResource',
            path: "/renameResource",
            authLevel: LOGIN,
            desc: '文件重命名',
            args: ['resource_id', 'file_name'],
            method: 'post',
            mailVerify: false
        },
        developerAddNewVersion: {
            fullPath: '/api/resource/developerAddNewVersion',
            path: "/developerAddNewVersion",
            authLevel: DEVELOPER,
            desc: '文件重命名',
            args: ['version', 'bus_id'],
            method: 'post',
            mailVerify: false
        },
        getResourceInfo: {
            fullPath: '/api/resource/getResourceInfo',
            path: "/getResourceInfo",
            authLevel: LOGIN,
            desc: '获取文件信息',
            args: ['resource_id'],
            method: 'get',
            mailVerify: false
        }
    },
    general: {
        deleteAppIcon: {
            fullPath: '/api/general/deleteAppIcon',
            path: "/deleteAppIcon",
            authLevel: ADMIN,
            desc: '删除七牛上的AppIcon',
            args: ['app_id'],
            method: 'post',
            mailVerify: false
        },
        adminUpdateProduct: {
            fullPath: '/api/general/adminUpdateProduct',
            path: "/adminUpdateProduct",
            authLevel: ADMIN,
            desc: '更新到产品库列表',
            args: ['name', 'type', 'soc', 'memory'],
            method: 'post',
            mailVerify: false
        },
        adminDeleteProduct: {
            fullPath: '/api/general/adminDeleteProduct',
            path: "/adminDeleteProduct",
            authLevel: ADMIN,
            desc: '删除产品',
            args: ['product_id'],
            method: 'post',
            mailVerify: false
        },
        getProductList: {
            fullPath: '/api/general/getProductList',
            path: "/getProductList",
            authLevel: NO_AUTH,
            desc: '获取登记在库的所有产品的列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        adminGetAppList: {
            fullPath: '/api/general/adminGetAppList',
            path: "/adminGetAppList",
            authLevel: ADMIN,
            desc: '管理员获取App列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getAppList: {
            fullPath: '/api/general/getAppList',
            path: "/getAppList",
            authLevel: NO_AUTH,
            desc: '获取App列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        adminAddApp: {
            fullPath: '/api/general/adminAddApp',
            path: "/adminAddApp",
            authLevel: ADMIN,
            desc: '管理员添加App',
            args: ['name'],
            method: 'post',
            mailVerify: false
        },
        adminUpdateAppInfo: {
            fullPath: '/api/general/adminUpdateAppInfo',
            path: "/adminUpdateAppInfo",
            authLevel: ADMIN,
            desc: '管理员更新App信息',
            args: ['app_id', 'status', 'logo', 'name', 'desc', 'url', 'author', 'license', 'user_id', 'app_fee', 'cid'],
            method: 'post',
            mailVerify: false
        },
        adminDestributeApp: {
            fullPath: '/api/general/adminDestributeApp',
            path: "/adminDestributeApp",
            authLevel: ADMIN,
            desc: '管理员更新App信息',
            args: [],
            method: 'post',
            mailVerify: false
        },
        adminDeleteApp: {
            fullPath: '/api/general/adminDeleteApp',
            path: "/adminDeleteApp",
            authLevel: ADMIN,
            desc: '管理员删除App',
            args: ['app_id'],
            method: 'post',
            mailVerify: false
        },
        getAppInfo: {
            fullPath: '/api/general/getAppInfo',
            path: "/getAppInfo",
            authLevel: NO_AUTH,
            desc: '获取App信息',
            args: ['app_id'],
            method: 'get',
            mailVerify: false
        },
        getUploadImageToken: {
            fullPath: '/api/general/getUploadImageToken',
            path: "/getUploadImageToken",
            authLevel: LOGIN,
            desc: '获得七牛token',
            args: [],
            method: 'get',
            mailVerify: false
        },       
        getBaseInfo: {
            fullPath: '/api/general/getBaseInfo',
            path: "/getBaseInfo",
            authLevel: NO_AUTH,
            desc: '获取基本信息',
            args: [],
            method: 'get',
            mailVerify: false
        },
        adminUpdateAppDetail: {
            fullPath: '/api/general/adminUpdateAppDetail',
            path: "/adminUpdateAppDetail",
            authLevel: ADMIN,
            desc: '管理员更新App详细介绍',
            args: ['detail', 'app_id'],
            method: 'post',
            mailVerify: false
        },
        adminGetAppDetailContent: {
            fullPath: '/api/general/adminGetAppDetailContent',
            path: "/adminGetAppDetailContent",
            authLevel: ADMIN,
            desc: '管理员获取App详情文章',
            args: ['app_id'],
            method: 'get',
            mailVerify: false
        },
        adminCreateAppVersion: {
            fullPath: '/api/general/adminCreateAppVersion',
            path: "/adminCreateAppVersion",
            authLevel: ADMIN,
            desc: '管理员创建App的公开未签名版本',
            args: ['app_id','version', 'resource_id'],
            method: 'post',
            mailVerify: false
        },
        adminDeleteAppVersion: {
            fullPath: '/api/general/adminDeleteAppVersion',
            path: "/adminDeleteAppVersion",
            authLevel: ADMIN,
            desc: '管理员删除App的公开未签名版本',
            args: ['version_id'],
            method: 'post',
            mailVerify: false
        },
        adminSetVersionVisible: {
            fullPath: '/api/general/adminSetVersionVisible',
            path: "/adminSetVersionVisible",
            authLevel: ADMIN,
            desc: '设置版本可见',
            args: ['version_id', 'is_visible'],
            method: 'post',
            mailVerify: false
        },
        developerGetAppVersionList: {
            fullPath: '/api/general/developerGetAppVersionList',
            path: "/developerGetAppVersionList",
            authLevel: DEVELOPER,
            desc: '开发者获取APP版本列表',
            args: ['app_id'],
            method: 'get',
            mailVerify: false
        },
        orderInfo: {
            fullPath: '/api/general/orderInfo',
            path: "/orderInfo",
            authLevel: NO_AUTH,
            desc: '订单信息',
            args: ['order_id'],
            method: 'get',
            mailVerify: false
        },
        adminAddPromocode: {
            fullPath: '/api/general/adminAddPromocode',
            path: "/adminAddPromocode",
            authLevel: ADMIN,
            desc: '管理员增加优惠券',
            args: ['code', 'type', 'description', 'value', 'total', 'start', 'end', 'valid', 'device_type'],
            method: 'post',
            mailVerify: false
        },
        adminListPromocode: {
            fullPath: '/api/general/adminListPromocode',
            path: "/adminListPromocode",
            authLevel: ADMIN,
            desc: '列出优惠券',
            args: ['valid'],
            method: 'get',
            mailVerify: false
        },
        checkPromocode: {
            fullPath: '/api/general/checkPromocode',
            path: "/checkPromocode",
            authLevel: LOGIN,
            desc: '检查优惠券是否有效',
            args: ['promocode', 'device_id', 'developer_id', 'price'],
            method: 'get',
            mailVerify: false
        }
    },
    subscribe: {
        getInitSubScribeList: {
            fullPath: '/api/subscribe/getInitSubScribeList',
            path: "/getInitSubScribeList",
            authLevel: LOGIN,
            desc: '用户获取自己初次订阅的列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getSubscribeList: {
            fullPath: '/api/subscribe/getSubscribeList',
            path: "/getSubscribeList",
            authLevel: LOGIN,
            desc: '用户获取自己所有订阅的列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getBusSubscribeInitList: {
            fullPath: '/api/subscribe/getBusSubscribeInitList',
            path: "/getBusSubscribeInitList",
            authLevel: DEVELOPER,
            desc: '开发者获取车的订阅列表',
            args: ['bus_id'],
            method: 'get',
            mailVerify: false
        },
        developerCreateBus: {
            fullPath: '/api/subscribe/developerCreateBus',
            path: "/developerCreateBus",
            authLevel: DEVELOPER,
            desc: '用户获取自己订阅的列表',
            args: ['app_id', 'name', 'desc'],
            method: 'post',
            mailVerify: true
        },
        getDeveloperBusList: {
            fullPath: '/api/subscribe/getDeveloperBusList',
            path: "/getDeveloperBusList",
            authLevel: LOGIN,
            desc: '开发者获取自己车的列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        getAppBusList: {
            fullPath: '/api/subscribe/getAppBusList',
            path: "/getAppBusList",
            authLevel: NO_AUTH,
            desc: '用户获取app车的列表',
            args: ['app_id', 'user_id'],
            method: 'get',
            mailVerify: false
        },
        getBusPrice: {
            fullPath: '/api/subscribe/getBusPrice',
            path: "/getBusPrice",
            authLevel: LOGIN,
            desc: '用户获取车的价格',
            args: ['bus_id', 'device_id'],
            method: 'get',
            mailVerify: false
        },
        newSubscribe: {
            fullPath: '/api/subscribe/newSubscribe',
            path: "/newSubscribe",
            authLevel: LOGIN,
            desc: '用户上车',
            args: ['bus_id', 'device_id', 'version_id'],
            method: 'post',
            mailVerify: false
        },
        paySubscribe: {
            fullPath: '/api/subscribe/paySubscribe',
            path: "/paySubscribe",
            authLevel: LOGIN,
            desc: '用户付款上车订单',
            args: ['subscribe_id', 'method'],
            method: 'get',
            mailVerify: false
        },
        getSubscribeInfo: {
            fullPath: '/api/subscribe/getSubscribeInfo',
            path: "/getSubscribeInfo",
            authLevel: LOGIN,
            desc: '获取subscribe信息',
            args: ['subscribe_id'],
            method: 'get',
            mailVerify: false
        },
        getBusInfo: {
            fullPath: '/api/subscribe/getBusInfo',
            path: "/getBusInfo",
            authLevel: LOGIN,
            desc: '用户获取上车信息',
            args: ['bus_id'],
            method: 'get',
            mailVerify: false
        },
        adminGetAllSubscribeList: {
            fullPath: '/api/subscribe/adminGetAllSubscribeList',
            path: "/adminGetAllSubscribeList",
            authLevel: ADMIN,
            desc: '管理员获取所有订阅信息',
            args: ['page', 'size'],
            method: 'get',
            mailVerify: false
        },
        adminFakePaySubscribe: {
            fullPath: '/api/subscribe/adminFakePaySubscribe',
            path: "/adminFakePaySubscribe",
            authLevel: SUPER_ADMIN,
            desc: '管理员获取所有订阅信息',
            args: ['subscribe_id'],
            method: 'post',
            mailVerify: false
        },
     
        developerEditVersion: {
            fullPath: '/api/subscribe/developerEditVersion',
            path: "/developerEditVersion",
            authLevel: DEVELOPER,
            desc: '开发者绑定版本资源id',
            args: ['version_id', 'bus_id', 'resource_id'],
            method: 'post',
            mailVerify: true
        },
        developerGetBusAppVersionList: {
            fullPath: '/api/subscribe/developerGetBusAppVersionList',
            path: "/developerGetBusAppVersionList",
            authLevel: DEVELOPER,
            desc: '开发者获取版本列表',
            args: ['bus_id'],
            method: 'get',
            mailVerify: false
        },
        developerDeleteVersion: {
            fullPath: '/api/subscribe/developerDeleteVersion',
            path: "/developerDeleteVersion",
            authLevel: DEVELOPER,
            desc: '开发者移除版本',
            args: ['version_id'],
            method: 'post',
            mailVerify: false
        },
        userGetSubscribeList: {
            fullPath: '/api/subscribe/userGetSubscribeList',
            path: "/userGetSubscribeList",
            authLevel: LOGIN,
            desc: '用户获取以上车列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        userGetSubscribeVersionList: {
            fullPath: '/api/subscribe/userGetSubscribeVersionList',
            path: "/userGetSubscribeVersionList",
            authLevel: LOGIN,
            desc: '用户获取车票的版本列表',
            args: ['subscribe_id'],
            method: 'get',
            mailVerify: false
        },
        userGetBusVersionList: {
            fullPath: '/api/subscribe/userGetBusVersionList',
            path: "/userGetBusVersionList",
            authLevel: LOGIN,
            desc: '用户获取车票的版本列表',
            args: ['bus_id'],
            method: 'get',
            mailVerify: false
        },
        userGetOwnUpdateList: {
            fullPath: '/api/subscribe/userGetOwnUpdateList',
            path: "/userGetOwnUpdateList",
            authLevel: LOGIN,
            desc: '用户获取自己的上车更新记录',
            args: ['bus_id', 'device_id'],
            method: 'get',
            mailVerify: false
        },
        developerGetBusUpdateList: {
            fullPath: '/api/subscribe/developerGetBusUpdateList',
            path: "/developerGetBusUpdateList",
            authLevel: DEVELOPER,
            desc: '开发者获取车的更新列表',
            args: ['bus_id'],
            method: 'get',
            mailVerify: false
        },
        userConfirmSubscribe: {
            fullPath: '/api/subscribe/userConfirmSubscribe',
            path: "/userConfirmSubscribe",
            authLevel: LOGIN,
            desc: '用户上车确认收货',
            args: ['subscribe_id'],
            method: 'post',
            mailVerify: false
        },
        userCancelSubscribe: {
            fullPath: '/api/subscribe/userCancelSubscribe',
            path: "/userCancelSubscribe",
            authLevel: LOGIN,
            desc: '用户取消更新订单',
            args: ['subscribe_id'],
            method: 'post',
            mailVerify: false
        },
        userGetUpdateInfoWithExtraInfo: {
            fullPath: '/api/subscribe/userGetUpdateInfoWithExtraInfo',
            path: "/userGetUpdateInfoWithExtraInfo",
            authLevel: LOGIN,
            desc: '用户获取更新订单信息(带附加信息)',
            args: ['update_id'],
            method: 'get',
            mailVerify: false
        },
        adminGetUpdateList: {
            fullPath: '/api/subscribe/adminGetUpdateList',
            path: "/adminGetUpdateList",
            authLevel: ADMIN,
            desc: '管理员获取更新列表',
            args: ['page', 'size'],
            method: 'get',
            mailVerify: false
        },
        adminFakePayUpdate: {
            fullPath: '/api/subscribe/adminFakePayUpdate',
            path: "/adminFakePayUpdate",
            authLevel: ADMIN,
            desc: '管理员模拟支付更新',
            args: ['update_id'],
            method: 'post',
            mailVerify: false
        },
        adminGetSubscribeRefundList: {
            fullPath: '/api/subscribe/adminGetSubscribeRefundList',
            path: "/adminGetSubscribeRefundList",
            authLevel: ADMIN,
            desc: '管理员获取订阅退款列表',
            args: ['page', 'size'],
            method: 'get',
            mailVerify: false
        },
        adminSearchSubscribe: {
            fullPath: '/api/subscribe/adminSearchSubscribe',
            path: "/adminSearchSubscribe",
            authLevel: ADMIN,
            desc: '管理员搜索退款',
            args: ['order_id'],
            method: 'get',
            mailVerify: false
        },
        developerGetNewSubscribeList: {
            fullPath: '/api/subscribe/developerGetNewSubscribeList',
            path: "/developerGetNewSubscribeList",
            authLevel: DEVELOPER,
            desc: '开发者获取车的新订单列表',
            args: ['bus_id'],
            method: 'get',
            mailVerify: false
        },
        developerLockSubscribe: {
            fullPath: '/api/subscribe/developerLockSubscribe',
            path: "/developerLockSubscribe",
            authLevel: DEVELOPER,
            desc: '开发者接单（上车）',
            args: ['subscribe_id'],
            method: 'post',
            mailVerify: false
        },
        developerGetSubscribeList: {
            fullPath: '/api/subscribe/developerGetSubscribeList',
            path: "/developerGetSubscribeList",
            authLevel: DEVELOPER,
            desc: '开发者获取上车订单列表',
            args: ['bus_id', 'page', 'size'],
            method: 'get',
            mailVerify: false
        },
        userRequestRefund: {
            fullPath: '/api/subscribe/userRequestRefund',
            path: "/userRequestRefund",
            authLevel: LOGIN,
            desc: '用户申请退款',
            args: ['subscribe_id', 'reason'],
            method: 'post',
            mailVerify: false
        },
        userRequestCancelRefund: {
            fullPath: '/api/subscribe/userRequestCancelRefund',
            path: "/userRequestCancelRefund",
            authLevel: LOGIN,
            desc: '用户申请取消退款',
            args: ['subscribe_id'],
            method: 'post',
            mailVerify: false
        },
        developerRejectOrder: {
            fullPath: '/api/subscribe/developerRejectOrder',
            path: "/developerRejectOrder",
            authLevel: DEVELOPER,
            desc: '开发者申请退款',
            args: ['subscribe_id'],
            method: 'post',
            mailVerify: false
        },
        userRequestResign: {
            fullPath: '/api/subscribe/userRequestResign',
            path: "/userRequestResign",
            authLevel: LOGIN,
            desc: '用户申请重新签名',
            args: ['subscribe_id', 'reason'],
            method: 'post',
            mailVerify: false
        },
        adminHandelRefund: {
            fullPath: '/api/subscribe/adminHandelRefund',
            path: "/adminHandelRefund",
            authLevel: ADMIN,
            desc: '管理员处理退款',
            args: ['subscribe_id', 'confirm', 'reason'],
            method: 'post',
            mailVerify: false
        },
        refund: {
            fullPath: '/api/subscribe/refund',
            path: "/refund",
            authLevel: ADMIN,
            desc: '通过退款',
            args: ['subscribe_id'],
            method: 'post',
            mailVerify: false
        },
        updateBid: {
            fullPath: '/api/subscribe/updateBid',
            path: "/updateBid",
            authLevel: DEVELOPER,
            desc: '更新bid',
            args: ['bid', 'bus_id'],
            method: 'post',
            mailVerify: false
        },
        developerPickAll: {
            fullPath: '/api/subscribe/developerPickAll',
            path: "/developerPickAll",
            authLevel: DEVELOPER,
            desc: '一键接单',
            args: [],
            method: 'get',
            mailVerify: false
        },
        developerDownloadAllUdid: {
            fullPath: '/api/subscribe/developerDownloadAllUdid',
            path: "/developerDownloadAllUdid",
            authLevel: DEVELOPER,
            desc: '下载所有UDID',
            args: [],
            method: 'get',
            mailVerify: false
        },
    },
    outter: {
        adminAddApp: {
            fullPath: '/api/outter/adminAddApp',
            path: "/adminAddApp",
            authLevel: ADMIN,
            desc: '新增外部app',
            args: ['name'],
            method: 'post',
            mailVerify: false
        },
        adminGetAppList: {
            fullPath: '/api/outter/adminGetAppList',
            path: "/adminGetAppList",
            authLevel: ADMIN,
            desc: '外部app列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        adminGetAppOrderList: {
            fullPath: '/api/outter/adminGetAppOrderList',
            path: "/adminGetAppOrderList",
            authLevel: ADMIN,
            desc: '获取app的订单列表',
            args: ['page', 'size', 'appId', 'filter'],
            method: 'get',
            mailVerify: false
        },
        adminDeleteApp: {
            fullPath: '/api/outter/adminDeleteApp',
            path: "/adminDeleteApp",
            authLevel: ADMIN,
            desc: '外部app列表',
            args: ['appId'],
            method: 'post',
            mailVerify: false
        },
        adminGetAppInfo: {
            fullPath: '/api/outter/adminGetAppInfo',
            path: "/adminGetAppInfo",
            authLevel: ADMIN,
            desc: '外部app详情',
            args: ['appId'],
            method: 'get',
            mailVerify: false
        },
        adminUpdateAppInfo: {
            fullPath: '/api/outter/adminUpdateAppInfo',
            path: "/adminUpdateAppInfo",
            authLevel: ADMIN,
            desc: '更新外部app信息',
            args: ['appId', 'appName', 'appSecret', 'online'],
            method: 'post',
            mailVerify: false
        },
        adminGetDeveloperList: {
            fullPath: '/api/outter/adminGetDeveloperList',
            path: "/adminGetDeveloperList",
            authLevel: ADMIN,
            desc: '获取开发者列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        adminTransferOrder: {
            fullPath: '/api/outter/adminTransferOrder',
            path: "/adminTransferOrder",
            authLevel: ADMIN,
            desc: '转单',
            args: ['orderId', 'developerId'],
            method: 'post',
            mailVerify: false
        },
        adminPayOutterOrder: {
            fullPath: '/api/outter/adminPayOutterOrder',
            path: "/adminPayOutterOrder",
            authLevel: ADMIN,
            desc: '结算',
            args: ['appId'],
            method: 'post',
            mailVerify: false
        },
        adminGetAppStats: {
            fullPath: '/api/outter/adminGetAppStats',
            path: "/adminGetAppStats",
            authLevel: ADMIN,
            desc: '结算',
            args: ['appId'],
            method: 'get',
            mailVerify: false
        },
        adminRefund: {
            fullPath: '/api/outter/adminRefund',
            path: "/adminRefund",
            authLevel: ADMIN,
            desc: '退款',
            args: ['orderId'],
            method: 'post',
            mailVerify: false
        },
        auth: {
            fullPath: '/api/outter/auth',
            path: "/auth",
            authLevel: NO_AUTH,
            desc: '外部签名身份验证',
            args: ['appId', "appSecret"],
            method: 'post',
            mailVerify: false
        },
        alloc: {
            fullPath: '/api/outter/alloc',
            path: "/alloc",
            authLevel: APP,
            desc: '外部签名请求',
            args: ['deviceType', 'udid'],
            method: 'post',
            mailVerify: false
        },
        sign: {
            fullPath: '/api/outter/sign',
            path: "/sign",
            authLevel: APP,
            desc: '外部签名请求',
            args: ['outterId', 'orderId'],  //outterId 是外部传给我的，orderId是isign的id
            method: 'post',
            mailVerify: false
        },
        status: {
            fullPath: '/api/outter/status',
            path: "/status",
            authLevel: APP,
            desc: '外部签名请求状态',
            args: ['orderId'],
            method: 'get',
            mailVerify: false
        },
        developerGetOutterOrderList: {
            fullPath: '/api/outter/developerGetOutterOrderList',
            path: "/developerGetOutterOrderList",
            authLevel: DEVELOPER,
            desc: '开发者获取外部签名请求订单列表',
            args: ['page', 'size'],
            method: 'get',
            mailVerify: false
        },
        developerGetOutterAppList: {
            fullPath: '/api/outter/developerGetOutterAppList',
            path: "/developerGetOutterAppList",
            authLevel: DEVELOPER,
            desc: '开发者获取外部签名App列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        developerGetUdidList: {
            fullPath: '/api/outter/developerGetUdidList',
            path: "/developerGetUdidList",
            authLevel: DEVELOPER,
            desc: '开发者获取外部签名udid列表',
            args: [],
            method: 'get',
            mailVerify: false
        },
        developerSigned: {
            fullPath: '/api/outter/developerSigned',
            path: "/developerSigned",
            authLevel: DEVELOPER,
            desc: '开发者签名完成',
            args: ['resourceId', 'appId'],
            method: 'post',
            mailVerify: false
        },
        genBill: {
            fullPath: '/api/outter/genBill',
            path: "/genBill",
            authLevel: ADMIN,
            desc: '生成账单',
            args: ['appId', "billStart", "billEnd"],
            method: 'post',
            mailVerify: false
        }
    },
    chat: {
        submit: {
            fullPath: '/api/chat/submit',
            path: "/submit",
            authLevel: LOGIN,
            desc: '提交',
            args: ['text', 'config'],
            method: 'post',
            mailVerify: false
        }
    }
}

module.exports = API;