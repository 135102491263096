<template>
  <div class="app_bus_list_wrap">
    <div v-if="app_fee != 0" class="app_fee_wrap">
      <div v-if="!is_buyed">
        此 App 非开源免费。由开发者提供，费用包含开发者签名费与支付给 App
        作者的费用： ¥{{ app_fee }}。<br />
        您的订单“确认收货”后，其他设备安装仅需支付签名费。
      </div>
      <div v-if="is_buyed">您拥有此 App。</div>
    </div>
    <div class="tool_wrap">
      <div class="fee_rule_container">
        <div style="font-weight: 600; font-size: 16px; margin-bottom: 5px">
          说明
        </div>
        新设备首次签名：¥10 + 有效期计费（¥0.1/天）<br />
        开发者已签过的设备装其他 App：¥10 <br />
        已签 App 升级/降级：¥3 <br />
        平均接单时间仅统计 12:00 -- 21:00 内的订单
      </div>
      <div class="fee_rule_container">
        <div style="font-weight: 600; font-size: 16px">筛选工具</div>
        <el-radio class="filter" v-model="filter" label="all"
          >所有开发者</el-radio
        >
        <br />
        <el-radio class="filter" v-model="filter" label="iphone"
          >仅列出有 iPhone 名额的开发者</el-radio
        >
        <br />
        <el-radio class="filter" v-model="filter" label="ipad"
          >仅列出有 iPad 名额的开发者</el-radio
        >
      </div>
    </div>
    <div class="bus_list_wrap">
      <div v-for="(item, index) in filtedList" :key="index" class="bus_wrap">
        <div>
          <div class="bus_info">
            <span class="bus_info_item"
              ><i class="el-icon-user"></i> 开发者</span
            >
            <span>{{ item.user_name }}</span>
          </div>

          <div class="bus_info">
            <span class="bus_info_item"
              ><i class="el-icon-time"></i> 签名有效期至</span
            >
            <span
              >{{ item.expire_time | dayFilter }} (剩余{{
                item.expire_time | remainDays
              }}天)</span
            >
          </div>

          <div class="bus_info">
            <span class="bus_info_item"
              ><i class="el-icon-odometer"></i> 平均接单速度</span
            >
            <span>{{ item.avgPickTime | durationFilter }}</span>
          </div>
          <div class="bus_info">
            <span class="bus_info_item"
              ><i class="el-icon-time"></i> 上次接单时间</span
            >
            <span>{{ item.lastSigned | durationToNow }}</span>
          </div>
          <div
            style="width: 100%; height: 1px; background: #ddd; margin: 10px 0px"
          ></div>
          <div class="bus_info">
            <span class="bus_info_item"
              ><i class="el-icon-chat-dot-round"></i> 开发者留言</span
            >
            <div style="min-height: 60px">{{ item.desc }}</div>
          </div>
        </div>
        <el-button
          style="width: 100%; margin-top: 10px"
          type="success"
          size="small"
          @click="getOnBus(item)"
          >签名安装</el-button
        >
      </div>
    </div>
    <el-dialog
      title="签名安装"
      :visible.sync="getOnBusDialogVisible"
      custom-class="get_on_bus_dialog"
      :modal="true"
      @closed="clearData"
    >
      <div v-if="getOnBusDialogVisible" class="on_bus_wrap">
        <div class="title">开发者剩余可签的设备</div>
        <div class="seat_info_wrap">
          <div
            v-for="(seatKey, index) in Object.keys(bus.seatInfo)"
            :key="index"
            class="seat_info"
          >
            <div class="device_type">{{ deviceMap[seatKey] }}</div>
            <el-progress
              :width="60"
              type="dashboard"
              :percentage="bus.seatInfo[seatKey]"
              :color="seatColors"
            ></el-progress>
          </div>
        </div>
        <div class="title"><i class="el-icon-mobile-phone"></i> 设备与版本</div>
        <div class="my_device_wrap">
          <el-select
            v-model="device_id"
            placeholder="请选择设备"
            class="select"
          >
            <el-option
              v-for="item in device_list"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            >
            </el-option>
          </el-select>
          <el-select
            style="margin-left: 20px"
            v-model="version_id"
            placeholder="请选择安装版本"
            class="select"
          >
            <el-option
              v-for="item in bus_version_list"
              :key="item._id"
              :label="item.version"
              :value="item._id"
            >
            </el-option>
          </el-select>
          <div v-if="device_id" style="margin-top: 10px;">
            为了避免对开发者和您造成损失，请再次确认您的设备是否选择正确。
            <div style="font-weight: 600; color: red;">
              一旦开发者完成签名，操作无法撤销。
            </div>
          </div>
          <div class="title"><i class="el-icon-s-ticket"></i> 优惠券</div>
          <div class="promocode-wrap">
            <el-input
              v-model="promocode"
              placeholder="可在此处填入优惠券代码"
            ></el-input
            ><el-button
              type="success"
              size="mini"
              @click="getPrice()"
              :disabled="
                promocode == null ||
                promocode == '' ||
                device_id == '' ||
                !priceInfo
              "
              >使用</el-button
            >
          </div>
          <div v-if="promoInfo">
            <div style="margin-top: 10px; color: green; font-weight: 600">
              有效的优惠券！已为您更新价格
            </div>
            <div class="promo-info">
              <div class="info">优惠券类型</div>
              <div class="content">
                {{ D.ORDER.PROMOCODE[promoInfo.type].DESC }}
              </div>
            </div>
            <div class="promo-info">
              <div class="info">可用设备</div>
              <div class="content">
                <span
                  style="margin-right: 10px"
                  v-for="(device, index) in promoInfo.device_type"
                  :key="index"
                  >{{ device }}</span
                >
              </div>
            </div>
            <div class="promo-info">
              <div class="info">优惠券详情</div>
              <div class="content">{{ promoInfo.description }}</div>
            </div>
          </div>
        </div>
        <div v-if="priceInfo" class="price_info_wrap">
          <div class="title">费用明细</div>
          <div class="developer_sign_fee">
            <div>
              开发者签名费用：<span style="color: red; font-weight: 600"
                >¥{{ priceInfo.sign_fee }}</span
              >
            </div>
            <div>
              签名有效期计费：
              <span
                style="color: red; font-weight: 600"
                v-if="priceInfo.account_fee == 0"
              >
                ¥0
              </span>
              <span
                style="color: red; font-weight: 600"
                v-if="priceInfo.account_fee != 0"
              >
                ¥{{ priceInfo.day_fee }}/天 * {{ priceInfo.remainDays }}天 = ¥{{
                  priceInfo.account_fee
                }}
              </span>
            </div>
            <div>
              App 费用：<span style="color: red; font-weight: 600"
                >¥{{ priceInfo.app_fee }}</span
              >
            </div>
            <div v-if="promoInfo">
              小计：
              <span style="color: red; font-weight: 600">{{ oldPrice }}</span>
            </div>
            <div v-if="promoInfo">
              优惠：
              <span style="color: green; font-weight: 600"
                >-¥{{ discount }}</span
              >
            </div>
            <div>
              <br />
              合计：<span style="color: red; font-weight: 600"
                >¥{{ priceInfo.price }}</span
              >
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          某些 App 对设备有
          <b style="color: red">安装要求</b
          >。下单前，您应仔细阅读应用详情内的"安装要求"和<a
            style="text-decoration: none; font-weight: 600"
            href="https://support.isign.ren/index.php/archives/6/"
            >《用户协议》</a
          >。
        </div>
        <el-checkbox style="margin-top: 20px" v-model="isRead"
          >我已阅读且同意。</el-checkbox
        >
        <div class="price_button_wrap">
          <el-button @click="createOrder" type="success" :disabled="!isRead"
            >创建订单</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="支付"
      :visible.sync="payDialogVisible"
      custom-class="pay_dialog"
      :modal="true"
    >
      <PayOrder
        v-if="payDialogVisible"
        :type="D.ORDER.TYPE.SUBSCRIBE.ID"
        :info_id="subscribe_id"
      ></PayOrder>
    </el-dialog>
  </div>
</template>

<script>
import API from '../../API'
import PayOrder from '../general/Pay/PayOrder'
import D from '../../const'
export default {
  name: 'AppBusList',
  props: ['app_id', 'app_fee'],
  inject: ['baseInfo', 'D', 'userInfo'],
  components: {
    PayOrder,
  },
  computed: {
    filtedList() {
      if (this.filter == 'all') {
        return this.bus_list
      }
      let list = []
      for (let busInfo of this.bus_list) {
        if (busInfo.seatInfo[this.filter] > 0) {
          list.push(busInfo)
        }
      }
      list.sort((a, b) => {
        return a.avgPickTime - b.avgPickTime
      })
      return list
    },
  },
  data() {
    return {
      D: D,
      isRead: false,
      getOnBusDialogVisible: false,
      payDialogVisible: false,
      price: '请选择签名设备',
      priceInfo: false,
      bus: false,
      subscribe_id: '',
      version_id: '',
      bus_list: [],
      bus_version_list: [],
      filter: 'all',
      promocode: '',
      promoInfo: null,
      discount: 0,
      oldPrice: null,
      deviceMap: {
        iphone: 'iPhone',
        ipad: 'iPad',
        ipod: 'iPod',
        mac: 'Mac',
        apple_tv: 'Apple TV',
      },
      is_buyed: false,
      device_list: [],
      device_id: '',
      seatColors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 },
      ],
    }
  },
  watch: {
    device_id() {
      this.getPrice()
    },
  },
  methods: {
    clearData() {
      this.priceInfo = false
      this.version_id = ''
      this.device_id = ''
    },
    checkPromocode() {
      this.$GET(API.general.checkPromocode, {
        promocode: this.promocode,
        device_id: this.device_id,
        developer_id: this.bus.busInfo.user_id,
        price: this.priceInfo.price,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          let { promoInfo, newPrice, discount } = res.data
          this.promoInfo = promoInfo
          this.discount = discount
          this.oldPrice = this.priceInfo.price
          this.priceInfo.price = newPrice
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
          this.promoInfo = null
          this.promocode = ''
        })
    },
    createOrder() {
      if (this.bus.busInfo._id == '' || this.device_id == '') {
        this.$message({
          type: 'info',
          message: '请选择设备',
        })
        return
      }
      if (this.version_id == '') {
        this.$message({
          type: 'info',
          message: '请选择安装版本',
        })
        return
      }
      this.$POST(API.subscribe.newSubscribe, {
        bus_id: this.bus.busInfo._id,
        device_id: this.device_id,
        version_id: this.version_id,
        promocode: this.promocode,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.getOnBusDialogVisible = false
          if (res.data.price == 0) {
            alert(
              '本次订单您无需支付。iSign 已派单给开发者，请在“个人中心>已购App”查看。'
            )
            return
          }
          this.payDialogVisible = true
          this.subscribe_id = res.data.subscribe_id
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error.message,
          })
        })
    },
    getBusList() {
      let user_id = this.userInfo.user_id
      if (user_id == '') {
        user_id = '-1'
      }
      this.$GET(API.subscribe.getAppBusList, {
        app_id: this.app_id,
        user_id: user_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.bus_list = res.data.list
          this.bus_list.sort((a, b) => {
            return a.avgPickTime - b.avgPickTime
          })
          this.is_buyed = res.data.is_buyed
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getBusVersionList() {
      this.$GET(API.subscribe.userGetBusVersionList, {
        bus_id: this.bus.busInfo._id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.bus_version_list = res.data.list
          if (this.bus_version_list.length > 0) {
            this.bus_version_list[0].version += ' -（最新版）'
          }
          this.getOnBusDialogVisible = true
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getOnBus(bus) {
      this.$GET(API.user.userGetDeviceList, {})
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          let list = res.data.list
          list.push({
            _id: '-1',
            udid: '-1',
            name: '添加设备',
          })
          this.device_list = res.data.list
          this.bus = bus
          this.getBusVersionList()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getPrice() {
      if (this.device_id == '-1') {
        this.$router.push('/user?page=MyDevices&subwindow=addDevice')
        return
      }
      if (this.device_id == '' || this.bus.busInfo._id == '') {
        return
      }
      this.$GET(API.subscribe.getBusPrice, {
        bus_id: this.bus.busInfo._id,
        device_id: this.device_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.priceInfo = res.data.data
          if (this.promocode != '') {
            this.checkPromocode()
          }
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
  },
  mounted() {
    if (this.app_id) {
      this.getBusList()
    }
  },
}
</script>

<style lang="less" scoped>
.tool_wrap {
  display: flex;
  flex-direction: row;
  .fee_rule_container {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    width: 320px;
    margin-left: 10px;
    margin-right: 10px;
    .filter {
      margin-top: 5px;
    }
  }
}

.app_bus_list_wrap {
  padding: 0px 20px 0px 20px;
}

.section_title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  height: 16px;
}
.app_fee_wrap {
  font-size: 16px;
}
.bus_list_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  .bus_wrap {
    margin: 10px;
    width: 320px;
    min-height: 70px;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5);

    font-size: 14px;
    .bus_info {
      line-height: 20px;
      .bus_info_item {
        font-weight: 600;
        width: 120px;
        display: inline-block;
      }
      .desc {
        margin-top: 10px;
        font-size: 14px;
        color: #666666;
      }
    }
  }
}

.on_bus_wrap {
  .title {
    font-size: 16px;
    margin-top: 20px;
  }
  .seat_info_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .seat_info {
      text-align: center;
      margin: 10px;
      width: 80px;
      height: 90px;
      border-radius: 20px;
      border: 1px solid #dddddd;
      padding: 10px;
      .device_type {
        margin-bottom: 10px;
        font-weight: 600;
      }
    }
  }
  .price_button_wrap {
    margin-top: 20px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
  .developer_sign_fee {
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
}

.promocode-wrap {
  display: flex;
  .el-input {
    max-width: 300px;
    margin-right: 20px;
  }
}

.promo-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .info {
    font-weight: 600;
    width: 80px;
  }
  .content {
    flex: 1;
  }
}

@media screen and (max-width: 550px) {
  .tool_wrap {
    flex-direction: column;
    .fee_rule_container {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      border-radius: 10px;
      width: calc(100vw - 100px);
      margin-left: 10px;
      margin-bottom: 20px;
    }
  }

  .section_title {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    height: 16px;
  }
  .app_fee_wrap {
    font-size: 12px;
  }
  .bus_list_wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .on_bus_wrap {
    .title {
      font-size: 16px;
      margin-top: 20px;
    }
    .seat_info_wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .seat_info {
        text-align: center;
        margin: 5px;
        width: 60px;
        height: 70px;
        border-radius: 20px;
        border: 1px solid #dddddd;
        padding: 10px;
        .device_type {
          margin-bottom: 5px;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
    .price_button_wrap {
      margin-top: 20px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      .el-button {
        width: 100%;
      }
    }
    .developer_sign_fee {
      margin-top: 20px;
      padding: 10px;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
    }
    .select {
      width: 100%;
      margin-left: 0px !important;
    }
  }
}
</style>
