<template>
  <div class="outter_order_wrap">
    <div class="title-1">外部签名订单</div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="签名" name="sign">
        <el-button
          class="download_udidlist_button"
          type="text"
          @click="downloadUdid"
          ><i class="el-icon-download"></i> 下载 UDID 列表</el-button
        >
        <el-table style="margin-top: 10px" :data="appList" max-height="450px">
          <el-table-column label="App" width="100px">
            <template slot-scope="scope">
              {{ scope.row.appName }}
            </template>
          </el-table-column>
          <el-table-column label="待签名" width="60px">
            <template slot-scope="scope">
              {{ scope.row.SIGNING }}
            </template>
          </el-table-column>
          <el-table-column label="已签名" width="60px">
            <template slot-scope="scope">
              {{ scope.row.SIGNED }}
            </template>
          </el-table-column>
          <el-table-column label="已完成" width="60px">
            <template slot-scope="scope">
              {{ scope.row.DONE }}
            </template>
          </el-table-column>
          <el-table-column label="等待结算" width="80px">
            <template slot-scope="scope">
              ¥ {{ scope.row.unpaid.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="
                  $downloadIpa(scope.row.resourceId, `${scope.row.appName}.ipa`)
                "
                >下载原始IPA</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="uploadIPA(scope.row._id)"
                >上传已签IPA</el-button
              >
              <a
                v-if="scope.row.signedResourceId"
                :href="`itms-services://?action=download-manifest&url=https://www.isign.ren/plist/${scope.row.signedResourceId}.plist`"
              >
                <el-button style="margin-left: 10px" type="success" size="mini">
                  安装</el-button
                >
              </a>
            </template>
          </el-table-column>
        </el-table>
        <div class="outter_description_wrap">
          <div class="description">
            <b>外部签名订单说明</b><br />
            外部签名订单是来自 iSign
            合作方的站外签名订单。与应用商店订单和定制签名订单需要手动接单不同，iSign
            根据合作方的要求，自动派发订单给满足条件的开发者。如果您希望接受外部签名订单，请在<b>“个人中心>我的开发者信息>外部订单接单设置”</b>处设置为<b>“接受外部订单”</b>。
            <br /><br />
            <b>其他事项</b><br />
            - 若您未在12小时内完成签名，iSign 将重新分配开发者。<br />
            - 完成后的订单会按月结算到您的个人账户。
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="订单列表" name="orders">
        <el-table style="margin-top: 10px" :data="orderList" max-height="450px">
          <el-table-column label="App" width="80px">
            <template slot-scope="scope">
              <div>{{ scope.row.appInfo.appName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="时间" width="120px">
            <template slot-scope="scope">
              <div>{{ scope.row.time | timeFilter }}</div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80px">
            <template slot-scope="scope">
              <div>{{ STATUS_DICT[scope.row.status] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="设备" width="80px">
            <template slot-scope="scope">
              <div>{{ scope.row.deviceType }}</div>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="80px">
            <template slot-scope="scope">
              <div>{{ (scope.row.price / 100).toFixed(2) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="udid" width="100px">
            <template slot-scope="scope">
              <div>{{ scope.row.udid }}</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
          :current-page.sync="page"
          @current-change="getOrderList"
          style="margin-top: 20px;"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="上传IPA"
      :visible.sync="uploadDialogVisible"
      custom-class="upload_dialog"
      :modal="false"
    >
      <AddIPA
        v-if="uploadDialogVisible"
        @uploadComplete="uploadComplete"
      ></AddIPA>
    </el-dialog>
  </div>
</template>
<script>
import API from "../../API";
import AddIPA from "../general/AddIPA.vue";
export default {
  name: "OutterOrder",
  components: {
    AddIPA,
  },
  data() {
    return {
      page: 1,
      total: 0,
      size: 20,
      STATUS_DICT: {
        PENDING: "等待付款",
        SIGNING: "签名中",
        SIGNED: "已签名",
        DONE: "已完成",
      },
      activeTab: "sign",
      orderList: [],
      appList: [],
      uploadDialogVisible: false,
      uploadingAppId: "",
    };
  },
  methods: {
    downloadUdid() {
      this.$GET(API.outter.developerGetUdidList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          let rawList = res.data.list;
          let udidDict = {};
          for (let udidInfo of rawList) {
            if (!udidDict[udidInfo.udid]) {
              udidDict[udidInfo.udid] = true;
            }
          }
          let udidList = Object.keys(udidDict);
          var element = document.createElement("a");
          var message = "Device ID	Device Name	Device Platform\n";
          for (let udid of udidList) {
            let platform = "ios";
            message += `${udid}	${udid}	${platform}\n`;
          }
          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(message)
          );
          element.setAttribute("download", "UDID列表");
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    uploadIPA(appId) {
      this.uploadingAppId = appId;
      this.uploadDialogVisible = true;
    },
    uploadComplete(resourceId) {
      this.$POST(API.outter.developerSigned, {
        resourceId: resourceId,
        appId: this.uploadingAppId,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.uploadDialogVisible = false;
          this.getAppList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getAppList() {
      this.$GET(API.outter.developerGetOutterAppList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.appList = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getOrderList() {
      this.$GET(API.outter.developerGetOutterOrderList, {page: this.page, size: this.size})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.orderList = res.data.list;
          this.total = res.data.total
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getOrderList();
    this.getAppList();
  },
};
</script>
<style lang="less" scoped>
.outter_order_wrap {
  padding: 20px;
  width: 600px;
  height: 600px;
  box-sizing: border-box;
  .outter_description_wrap {
    margin-top: 20px;
    .description {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
@media screen and (max-width: 550px) {
  .outter_order_wrap {
    width: 100%;
  }
}
</style>