<template>
  <div class="full">
    <div class="infos">
      <div style="font-weight: 500; font-size: 20px;">重置密码</div>
      <div style="margin-top: 20px">密码</div>
      <el-input v-model="password" type="password"></el-input>
      <div style="margin-top: 20px">确认密码</div>
      <el-input v-model="password_confirm" type="password"></el-input>
      <div v-if="wrong" style="color: red">两次输入密码不一致</div>
      <div class="controls_wrap">
        <el-button
          type="primary"
          style="width: 100%"
          @click="reset"
          :disabled="wrong"
          >重置密码</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import API from "../API";
export default {
  name: "ResetPassword",
  data() {
    return {
      visible: true,
      password: "",
      password_confirm: "",
    };
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
    wrong() {
      return this.password != this.password_confirm;
    },
  },
  methods: {
    reset() {
      this.$POST(API.user.resetpassword, {
        token: this.token,
        password: this.password,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "重置密码成功！",
          });
          let _this = this;
          setTimeout(() => {
            _this.$window.location = "/"
          }, 1000);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.controls_wrap {
  margin-top: 20px;
}
.full {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px);
  width: 98vw;
  .infos {
    width: 300px;
    background: white;
    border-radius: 10px;
    padding: 20px;
  }
}
@media screen and (max-width: 550px) {
}
</style>