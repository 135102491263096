<template>
  <div class="sub-wrap">
    <div class="verifying-text">正在审核中..</div>
    <div class="verifying-time">预计24小时内审核完毕。</div>
    <el-button size="small" @click="cancel">撤销本次申请</el-button>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "DeveloperVerifying",
  methods: {
    cancel() {
      this.$POST(API.user.cancelApplyForDeveloper, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "撤销成功",
          });
          let that = this;
          setTimeout(() => {
            that.$window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style lang='less' scoped>
.sub-wrap {
  width: 100%;
  .verifying-text {
    margin-top: 20px;
    font-weight: 900;
    font-size: 14px;
  }
  .verifying-time {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
  }
}
</style>