<template>
  <div class="add_ipa_wrap">
    <el-upload
      ref="uploader"
      :drag="size != 'small'"
      :on-change="handleChange"
      action=""
      :auto-upload="false"
      :multiple="false"
      :limit="1"
      class="upload_wrap"
    >
      <div v-if="size != 'small'">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </div>
      <div v-if="size == 'small'">
        <el-button size="small" type="text">点击上传</el-button>
      </div>
    </el-upload>

    <el-progress
      :stroke-width="26"
      :text-inside="true"
      :percentage="percentage"
      style="width: 100%; margin-top: 10px"
    ></el-progress>
  </div>
</template>

<script>
/*
上传的步骤：
【1】计算文件md5
【2】调用 API.resource.postNewUploadedFile 获取新建的 resource_id 和 uploadToken
【3】以 resource_id.ipa，如果【2】有返回ref_id，则使用ref_id作为七牛的 key 上传到cdn
【4】全部过程完成后会 $emit('uploadComplete', resource_id) 回调给父组件
*/

import API from "../../API";
import SparkMD5 from "spark-md5";
export default {
  name: "AddIPA",
  props: ["size", "bid"],
  data() {
    return {
      file_name: "",
      ipa_file: {},
      uploadToken: "",
      resource_id: "",
      ref_id: "",
      md5: "",
      percentage: 0,
    };
  },
  methods: {
    clear() {
      this.ipa_file = {};
      this.file_name = "";
      this.uploadToken = "";
      this.resource_id = "";
      this.uploadKey = "";
      this.md5 = "";
      this.ref_id = "-1";
      this.$refs.uploader.clearFiles();
    },
    userUpdate() {
      this.$POST(API.resource.postNewUploadedFile, {
        file_name: this.file_name,
        md5: this.md5,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.resource_id = res.data.resource_id;
          this.ref_id = res.data.ref_id;
          if (this.ref_id != "-1") {
            this.uploadKey = `${this.ref_id}.ipa`;
          } else {
            this.uploadKey = `${this.resource_id}.ipa`;
          }
          this.uploadToken = res.data.upload_token;
          if (res.data.uploaded) {
            this.percentage = 100;
            this.updateResourceUploadStatus();
          } else {
            this.startUpload();
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
          this.clear();
        });
    },
    getUploadKey() {
      this.userUpdate();
    },
    handleChange(file) {
      if (file.name.indexOf("ipa") < 0) {
        this.$message({
          type: "error",
          message: "只能上传ipa文件",
        });
        this.clear();
        return;
      }
      this.ipa_file = file;
      let that = this;
      const blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      const fileReader = new FileReader();
      const chunkSize = 2097152;
      const chunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;
      const spark = new SparkMD5.ArrayBuffer();
      fileReader.onload = function(e) {
        spark.append(e.target.result);
        currentChunk++;
        if (currentChunk < chunks) {
          loadNext();
        } else {
          const sparkEnd = spark.end();
          //计算后的结果
          that.md5 = sparkEnd;
          if (that.file_name == "") {
            that.file_name = file.name;
          }
          that.getUploadKey();
        }
      };
      fileReader.onerror = function() {
        console.warn("FileReader error.");
      };
      function loadNext() {
        const start = currentChunk * chunkSize;
        const end =
          start + chunkSize >= file.size ? file.size : start + chunkSize;
        // 注意这里的 fileRaw
        fileReader.readAsArrayBuffer(blobSlice.call(file.raw, start, end));
      }
      loadNext();
    },
    startUpload() {
      let that = this;
      const observable = this.$qiniu.upload(
        this.ipa_file.raw,
        this.uploadKey,
        this.uploadToken
      );
      observable.subscribe({
        next(res) {
          that.percentage = +res.total.percent.toFixed(2);
        },
        error(error) {
          that.$message({
            type: "error",
            message: error.message,
          });
          that.clear();
        },
        complete() {
          that.updateResourceUploadStatus();
        },
      }); // 上传开始
    },
    updateResourceUploadStatus() {
      let bid = this.bid;
      if (!this.bid) {
        bid = "ren.isign.www";
      }
      this.$POST(API.resource.uploadComplete, {
        resource_id: this.resource_id,
        bid: bid,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.percentage = 100;
          this.$emit("uploadComplete", this.resource_id);
          this.clear();
          this.$message({
            type: "success",
            message: "文件上传成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
          this.clear();
        });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.add_ipa_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ipa_description_wrap {
    margin-bottom: 20px;
    text-align: center;
    width: 80%;
  }
  .ipa_name_input {
    input {
      text-align: center !important;
    }
  }
}

@media screen and (max-width: 550px) {
  .upload_wrap {
    transform: scale(0.7, 0.7);
  }
}
</style>
