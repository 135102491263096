<template>
  <div class="my_wallet_wrap">
    <el-button
      class="qrcode_button"
      type="primary"
      size="small"
      @click="qrcodeDialogVisible = true"
    >
      <i class="el-icon-setting"></i> 收款码</el-button
    >
    <div class="title-1">我的钱包</div>
    <div class="hint">提现时，iSign 将收取 30% 服务费维持运营。</div>
    <div v-if="walletInfo" class="wallet-wrap">
      <div class="wallet-item-wrap">
        <div class="item-title">余额</div>
        <div class="item-content">{{ walletInfo.value.toFixed(2) }}</div>
      </div>
      <div class="wallet-item-wrap">
        <div class="item-title">提现中</div>
        <div class="item-content">{{ walletInfo.withdrawing.toFixed(2) }}</div>
      </div>
      <div class="withdraw_button_group">
        <el-button
        class="option_item"
          type="primary"
          size="small"
          @click="openWithdrawDialog('alipay')"
          >提现到支付宝</el-button
        >
        <el-button
        class="option_item"
          type="success"
          size="small"
          @click="openWithdrawDialog('wechat')"
          >提现到 微信</el-button
        >
      </div>
    </div>

    <el-table :data="record_list" :max-height="tableHeight">
      <el-table-column label="时间" width="140">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 5px">{{
            scope.row.time | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="90">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.type | recordType
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="余额变化" width="100">
        <template slot-scope="scope">
          <span
            :style="{
              color: valueChangeColor(scope.row),
            }"
          >
            {{ prefixOfValueChange(scope.row) }}
            {{ scope.row.value_change.toFixed(2) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="关联订单号" width="200">
        <template slot-scope="scope">
          <i class="el-icon-document"></i>
          <span style="margin-left: 5px">{{ scope.row.info_id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.type == $D.RECORD.TYPE.WITHDRAW_COMPLETE.ID"
            size="mini"
            type="normal"
            @click="checkPhoto(scope.row.desc)"
            ><i class="el-icon-tickets"></i
          ></el-button>
          <span v-if="scope.row.type != $D.RECORD.TYPE.WITHDRAW_COMPLETE.ID">{{
            scope.row.desc
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="count"
      :page-size="size"
      :current-page="page"
      @current-change="pageChanged"
      style="margin-top: 20px; margin-bottom: 20px"
    >
    </el-pagination>
    <el-dialog
      title="收款码"
      :visible.sync="qrcodeDialogVisible"
      custom-class="qrcode_dialog"
    >
      <WalletQR></WalletQR>
    </el-dialog>
    <el-dialog
      title="转账记录"
      :visible.sync="withdrawRecordVisible"
      custom-class="check_withdraw_dialog"
    >
      <img width="100%" :src="withdrawRecord" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import WalletQR from "./WalletQR";
export default {
  name: "MyWallet",
  components: { WalletQR },
  data() {
    return {
      count: 0,
      size: 20,
      page: 1,
      record_list: [],
      walletInfo: false,
      qrcodeDialogVisible: false,
      withdrawRecordVisible: false,
      withdrawRecord: "",
    };
  },
  methods: {
    pageChanged(page) {
      this.page = page;
      this.getRecords();
    },
    withdraw(type, value) {
      this.$POST(API.user.withdraw, { type: type, value: value })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "已发起提现申请",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    openWithdrawDialog(type) {
      this.$prompt("请输入金额", `提现到${type == 'alipay' ? "支付宝" : "微信"}`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
        inputErrorMessage: "金额输入不正确",
        customClass: "rename_dialog",
      })
        .then(({ value }) => {
          this.withdraw(type, value);
        })
        .catch(() => {});
    },
    checkPhoto(img) {
      this.withdrawRecordVisible = true;
      this.withdrawRecord = img;
    },
    getWalletInfo() {
      this.$GET(API.user.getWalletInfo, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.walletInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    getRecords() {
      this.$GET(API.user.getWalletChangeRecord, {
        page: this.page,
        size: this.size,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.record_list = res.data.data.list;
          this.count = res.data.data.count;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    valueChangeColor(row) {
      if (row.type == this.$D.RECORD.TYPE.WITHDRAW.ID) {
        return "#606266";
      }
      if (row.type == this.$D.RECORD.TYPE.WITHDRAW_COMPLETE.ID) {
        return "green";
      }
      if (row.type == this.$D.RECORD.TYPE.WITHDRAW_FAILED.ID) {
        return "#606266";
      }
      if (row.value_change > 0) {
        return "red";
      } else {
        return "green";
      }
    },
    prefixOfValueChange(row) {
      if (row.type == this.$D.RECORD.TYPE.WITHDRAW.ID) {
        return "";
      }
      if (row.type == this.$D.RECORD.TYPE.WITHDRAW_COMPLETE.ID) {
        return "-";
      }
      if (row.type == this.$D.RECORD.TYPE.WITHDRAW_FAILED.ID) {
        return "";
      }
      if (row.value_change > 0) {
        return "+";
      } else {
        return "-";
      }
    },
  },
  computed: {
    withdrawingValue() {
      return 0;
    },
    tableHeight() {
      let width = document.body.clientWidth;
      if (width > 550) {
        return 380;
      } else {
        return document.body.clientHeight - 315;
      }
    },
  },
  mounted() {
    this.getWalletInfo();
    this.getRecords();
  },
};
</script>

<style lang="less" scoped>
.my_wallet_wrap {
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  height: 600px;
  width: 600px;
  .qrcode_button {
    position: absolute;
    top: 25px;
    right: 20px;
  }
  .hint {
    color: red;
    font-size: 14px;
  }
  //   background-color: #000;

  .wallet-wrap {
    margin-top: 20px;
    margin-bottom: 10px;
    .wallet-item-wrap {
      display: inline-block;
      width: 70px;
      margin-right: 10px;
      .item-title {
        font-size: 14px;
      }
      .item-content {
        color: #02790e;
        font-size: 20px;
        border-bottom: 1px;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-style: solid;
        border-color: #d7d7d7;
        line-height: 40px;
      }
    }
  }
  .withdraw_button_group {
    position: absolute;
    right: 20px;
    top: 130px;
  }
}
@media screen and (max-width: 550px) {
  .my_wallet_wrap {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    .withdraw_button_group {
    position: inherit;
    .option_item {
      width: 100%;
      margin: 5px 0 5px 0;
    }
  }
  }
}
</style>