<template>
  <div class="developer_info_wrap">
    <el-button
      style="position: absolute; right: 20px; top: 25px"
      type="primary"
      size="small"
      :disabled="developerInfo.status == 'DISABLED'"
      @click="updateInfo"
    >
      更新信息</el-button
    >
    <div class="title-1">开发者信息</div>
    <div style="color: red" v-if="developerInfo.status == 'DISABLED'">您的开发者账号被临时禁用，请联系站长。</div>
    <div v-if="developerInfo" class="info_container">
      <div class="info_wrap">
        <div class="info_title">昵称</div>
        <div class="info_content_wrap">
          <el-input type="text" v-model="developerInfo.user_name" :disabled="developerInfo.status == 'DISABLED'"></el-input>
        </div>
      </div>
      <div class="info_wrap">
        <div class="info_title">接单设置</div>
        <div class="info_content_wrap">
          <div class="left_item under_line">
            <div class="info">
              <el-radio
                v-model="developerInfo.status"
                :label="D.USER.DEVELOPER_STATUS.NORMAL.ID"
                :disabled="developerInfo.status == 'DISABLED'"
                >正常接单中</el-radio
              >
              <el-radio
                v-model="developerInfo.status"
                :label="D.USER.DEVELOPER_STATUS.REST.ID"
                :disabled="developerInfo.status == 'DISABLED'"
                >休息中（暂停接单）</el-radio
              >
            </div>
          </div>
        </div>
      </div>
      <div class="info_wrap">
        <div class="info_title">外部订单接单设置</div>
        <div class="info_content_wrap">
          <div class="left_item under_line">
            <div class="info">
              <el-radio
                v-model="developerInfo.accept_outter"
                :label="true"
                :disabled="developerInfo.status == 'DISABLED'"
                >接受外部订单</el-radio
              >
              <el-radio
                v-model="developerInfo.accept_outter"
                :label="false"
                :disabled="developerInfo.status == 'DISABLED'"
                >不接受</el-radio
              >
            </div>
          </div>
        </div>
      </div>
      <div class="info_wrap">
        <div class="info_title">开发者留言</div>
        <div class="info_content_wrap">
          <el-input
            type="text"
            v-model="developerInfo.desc"
            placeholder="可以写一般什么时候接单"
            :disabled="developerInfo.status == 'DISABLED'"
          ></el-input>
        </div>
      </div>      
        <div style="width: 100%;font-size: 14px;text-align:center;position:relative;top:-10px;">剩余可签设备数量</div>           
      <div class="device_num_wrap">          
        <div class="device_group" v-for="(key, index) in deviceKey" :key="key">
          <div class="device_title">{{ deviceName[index] }}</div>
          <el-progress
            :width="70"
            type="dashboard"
            :percentage="developerInfo[key]"
            :color="seatColors"
          ></el-progress>
          <div>
            <el-button-group>
              <el-button
                icon="el-icon-minus"
                @click="decrease(key)"
                size="mini"
                :disabled="developerInfo.status == 'DISABLED'"
              ></el-button>
              <el-button
                icon="el-icon-plus"
                @click="increase(key)"
                size="mini"
                :disabled="developerInfo.status == 'DISABLED'"
              ></el-button>
            </el-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "../../API";
export default {
  name: "DeveloperInfo",
  inject: ["D"],
  data() {
    return {
      developerInfo: false,
      deviceKey: ["iphone", "ipad", "ipod", "apple_tv", "mac"],
      deviceName: ["iPhone", "iPad", "iPod", "Apple TV", "Mac"],
      seatColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
    };
  },
  methods: {
    increase(key) {
      if (this.developerInfo[key] < 100) {
        this.developerInfo[key] += 1;
      }
    },
    decrease(key) {
      if (this.developerInfo[key] > 0) {
        this.developerInfo[key] -= 1;
      }
    },
    getDeveloperInfo() {
      this.$GET(API.user.developerGetDeveloperInfo, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.developerInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    updateInfo() {
        if (this.developerInfo.desc == '') {
            this.developerInfo.desc = ' ';
        }
      this.$POST(API.user.developerEditDeveloperInfo, {
        user_name: this.developerInfo.user_name,
        status: this.developerInfo.status,
        iphone: this.developerInfo.iphone,
        ipad: this.developerInfo.ipad,
        ipod: this.developerInfo.ipod,
        apple_tv: this.developerInfo.apple_tv,
        mac: this.developerInfo.mac,
        desc: this.developerInfo.desc,
        accept_outter: this.developerInfo.accept_outter
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "更新信息成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getDeveloperInfo();
  },
};
</script>
<style lang="less" scoped>
.developer_info_wrap {
  position: relative;
  padding: 20px;
  width: 600px;
  height: 600px;
  box-sizing: border-box;
  .info_container {
    margin-top: 10px;
  }
  .device_num_wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .device_group {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px;
      .device_title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .developer_info_wrap {
    width: 100%;
    box-sizing: border-box;
  }
}
</style>