<template>
  <div class="DL_wrap">
    <div class="order_info_wrap">
      <div v-if="orderInfo" class="order_info_header">
        <div class="title">{{ orderInfo.resource_info.file_name }}</div>
        <div
          v-if="signed"
          class="signed_tag"
        >
          已签名
        </div>
        <div v-else class="not_signed_tag">
          未签名
        </div>
      </div>
      <div style="margin-bottom: 20px;">本页二维码</div>
        <div id="qrcode" ref="qrcode"></div>
      <div v-if="!isSafari" class="open_in_safari_wrap">
        <div class="not_mobile_safari_tag">
          请使用iOS自带浏览器Safari打开本页面。
        </div>
      </div>
      <div v-else style="margin-top: 20px;">
        <a
        v-if="orderInfo && signed"
        :href="`itms-services://?action=download-manifest&url=https://www.isign.ren/plist/${this.orderInfo.signed_resource_id}.plist`"
      >
        <el-button style="width: 100%" type="success" size="normal"
          ><i class="el-icon-check"></i> 本机安装</el-button
        >
      </a>
      </div>

    </div>
  </div>
</template>
<script>
import API from "../API";
import QRCode from "qrcodejs2";
export default {
  name: "DL",
  computed: {
    signed() {
      return this.orderInfo.status == 'SIGNED' || this.orderInfo.status == 'DONE'
    },
    isSafari() {
      let userAgent = navigator.userAgent;
      if (
        userAgent.indexOf("MicroMessenger") >= 0 ||
        userAgent.indexOf("QQ") >= 0 ||
        userAgent.indexOf("Mobile") < 0
      ) {
        return false;
      }
      return true;
    },
    orderId() {
      return this.$route.query.orderId;
    },
  },
  data() {
    return {
      orderInfo: false,
    };
  },
  methods: {
    getOrderInfo() {
      this.$GET(API.general.orderInfo, { order_id: this.orderId })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.orderInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrcode, {
        text: document.location.href, //页面地址 ,如果页面需要参数传递请注意哈希模式#
        width: 100,
        height: 100,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      console.log(qrcode);
    },
  },
  mounted() {
    this.getOrderInfo();
    this.creatQrCode();
  },
};
</script>
<style lang="less" scoped>
.DL_wrap {
    height: 100vh;
    width: 100vw;
  .order_info_wrap {
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    .order_info_header {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;
      .title {
        font-size: 20px;
        padding: 20px;
      }
      .signed_tag {
        padding: 5px;
        text-align: center;
        color: white;
        width: 60px;
        font-size: 16px;
        border-radius: 10px;
        background: green;
      }
      .not_signed_tag {
        padding: 5px;
        text-align: center;
        color: white;
        width: 60px;
        font-size: 16px;
        border-radius: 10px;
        background: orange;
      }
    }
    .open_in_safari_wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
      .not_mobile_safari_tag {
        margin-bottom: 20px;
        background: red;
        color: white;
        border-radius: 10px;
        padding: 10px;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
}
</style>