<template>
  <div class="wrap">
    <div class="title-1">IPA列表</div>
    <el-table :data="list" style="width: 850px" max-height="400px">
      <el-table-column label="文件名" width="150">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.file_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ID" width="190">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row._id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上传时间" width="150">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.upload_ts | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="normal"
            @click="$downloadIpa(scope.row._id, scope.row.file_name)"
            >下载IPA</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="handleSetPublic(scope.row._id)"
            >设为公开</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row._id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="count"
      :page-size="size"
      :current-page="page"
      @current-change="pageChanged"
    >
    </el-pagination>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "IPAList",
  data() {
    return {
      list: [],
      count: 0,
      size: 20,
      page: 1,
    };
  },
  computed: {
    pageCount() {
      return this.count / this.size;
    },
  },
  methods: {
    pageChanged(page) {
      this.page = page;
      this.update(this.page, this.size);           
    },
    update(page, size) {
      this.$GET(API.resource.list, {
        page: page,
        size: size,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.data.list;
          this.count = res.data.data.count;
        })
        .catch((error) => {
          alert(error);
        });
    },   
    handleSetPublic(resource_id) {
      this.$POST(API.resource.setResourcePublic, {
        resource_id: resource_id,
        is_public: true,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "设置成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    handleDelete(resource_id) {
      this.$POST(API.resource.adminDeleteResource, {
        resource_id: resource_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.update(this.page, this.size);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
  mounted() {
    this.update(0, this.size);
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;
  
}
</style>