<template>
  <div class="footer">
    <div class="footer-content">
      <div style="font-size: 16px;font-weight:900;">友情链接</div>
      <div class="friend-link-content">
        <a href="https://blog.isign.ren">站长博客</a>
        <a href="https://blog.iucky.cn">秉松博客</a>
        <a href="https://classsoft.net">ClassSoft</a>
        <a href="https://blog.insnhgd.xyz">InSnh-Gd</a>
        <a href="https://blog.lanpinggai.top">蓝屏博客</a>
      </div>
      <div class="site-info">
        联系站长：service@isign.ren
        <br/>
        iSign用户群：910071770
        <br /><a href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备2022016934号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="less">
.footer {
  width: 100%;
  height: 140px;
  background-color: rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.8);
  font-size: 8px;
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    margin-right: 20px;
  }

  .footer-content {
    text-align: left;
    margin: 0 auto 0 auto;
    padding-top: 5px;
    padding-left: 20px;
    font-size: 14px;    

    .friend-link-content {
      height: 40px;
      padding-top: 10px;
      text-align: left;
      box-sizing: border-box;
      a {
        font-size: 14px;
      }
    }

    .site-info {
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>