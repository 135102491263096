<template>
  <div v-if="userInfo._id" class="user_detail_wrap">
    <div class="section_wrap" style="width: 470px">
      <div class="detail_item">
        <div class="title">ID</div>
        <div class="content">{{ userInfo._id }}</div>
      </div>
      <div class="detail_item">
        <div class="title">用户名</div>
        <div class="content">{{ userInfo.user_name }}</div>
      </div>
      <div class="detail_item">
        <div class="title">电子邮件</div>
        <div class="content">
          {{ userInfo.email }} ({{ userInfo.email_status }})
        </div>
      </div>
      <div class="detail_item">
        <div class="title">电话号码</div>
        <div class="content">
          {{ userInfo.phone == "" ? "未填写" : userInfo.phone }}
        </div>
      </div>
      <div class="detail_item">
        <div class="title">用户类型</div>
        <div class="content">
          {{ userInfo.type }}
          <div class="options_wrap">
            <div style="margin-bottom: 5px">设置用户类型</div>
            <el-radio
              v-model="userInfo.type"
              label="NORMAL_USER"
              @change="setUserType"
              >一般</el-radio
            >
            <el-radio
              v-model="userInfo.type"
              label="DEVELOPER"
              @change="setUserType"
              >开发者</el-radio
            >
            <el-radio
              v-model="userInfo.type"
              label="ADMIN"
              @change="setUserType"
              >管理员</el-radio
            >
            <el-radio
              v-model="userInfo.type"
              label="SUPER_ADMIN"
              @change="setUserType"
              >超管</el-radio
            >
          </div>
        </div>
      </div>
      <div class="detail_item">
        <div class="title">账号状态</div>
        <div class="content">
          {{ userInfo.status }}
          <div class="options_wrap">
            <div style="margin-bottom: 5px">设置账号状态</div>
            <el-radio
              v-model="userInfo.status"
              label="NORMAL"
              @change="setUserStatus"
              >正常</el-radio
            >
            <el-radio
              v-model="userInfo.status"
              label="ORDER_LIMIT"
              @change="setUserStatus"
              >限制接单/发单</el-radio
            >
            <el-radio
              v-model="userInfo.status"
              label="BANNED"
              @change="setUserStatus"
              >账号封停</el-radio
            >
          </div>
        </div>
      </div>
    </div>
    <div class="section_wrap">
      <div class="detail_item">
        <div class="title">账号余额</div>
        <div class="content">{{ userInfo.walletInfo.value }}</div>
      </div>
      <div class="detail_item">
        <div class="title">发布订单</div>
        <div class="content">
          {{ userInfo.created_order }} 条
          <el-button style="margin-left: 10px" type="text">查看</el-button>
        </div>
      </div>
      <div class="detail_item">
        <div class="title">接单</div>
        <div class="content">
          {{ userInfo.developer_order }} 条
          <el-button style="margin-left: 10px" type="text">查看</el-button>
        </div>
      </div>
      <div class="detail_item">
        <div class="title">上传IPA</div>
        <div class="content">
          {{ userInfo.resource }} 条
          <el-button style="margin-left: 10px" type="text">查看</el-button>
        </div>
      </div>
      <div class="detail_item">
        <div class="title">设备列表</div>
        <div class="content udid_list">
          <div v-for="(deviceInfo, index) in userInfo.udid_list" :key="index">
            {{ deviceInfo.name }} ({{ deviceInfo.udid }})
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "UserDetail",
  props: ["userInfo"],
  methods: {
    setUserType() {
      this.$POST(API.user.setUserType, {
        user_id: this.userInfo._id,
        type: this.userInfo.type,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "设置成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    setUserStatus() {
      this.$POST(API.user.setUserStatus, {
        user_id: this.userInfo._id,
        status: this.userInfo.status,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "设置成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.user_detail_wrap {
  display: flex;
  flex-direction: row;
  .section_wrap {
    display: flex;
    flex-direction: column;
    .detail_item {
      margin-bottom: 20px;
      .title {
        font-size: 16px;
        font-weight: 900;
      }
      .content {
        font-size: 14px;
        .options_wrap {
          background-color: rgba(0, 0, 0, 0.03);
          padding: 10px;
          width: 80%;
        }
      }
    }
  }
}
.udid_list {
  width: 430px;
  height: 150px;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 5px;
  box-sizing: border-box;
}
</style>