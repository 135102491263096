<template>
  <div class="sub-wrap">
    <div
      v-if="devStatus.status == D.USER.DEVELOPER_STATUS.FAILED.ID"
      style="margin-bottom: 20px"
    >
      <div class="title-1">管理员拒绝了您的开发者申请</div>
      <div class="msg">
        申请不通过，原因是：{{
          devStatus.msg
        }}。请再次确认审核规则并重新提交IPA包。
      </div>
    </div>
    <div v-if="!canApply" style="font-size: 20px;margin-bottom: 20px;color: red;">
      暂时停止新开发者申请。
    </div>
    <el-button @click="uploadVisible = true" type="primary" :disabled="!canApply"
      >上传认证IPA <i v-if="resource_id != ''" class="el-icon-success"></i
    ></el-button>
    <el-button @click="openUploadImgDialog" type="primary" :disabled="!canApply"
      >上传截图 <i v-if="img_url != ''" class="el-icon-success"></i
    ></el-button>
    <el-button
      v-if="img_url != '' && resource_id != ''"
      type="success"
      @click="apply"
      :disabled="!canApply"
      >提交申请</el-button
    >
    <el-dialog
      title="上传认证IPA"
      :visible.sync="uploadVisible"
      custom-class="upload_dialog"
      @exit="uploadVisible = false"
    >
      <AddIPA @uploadComplete="fileUploadComplete"></AddIPA>
    </el-dialog>
    <el-dialog
      title="上传截图"
      :visible.sync="uploadScreenShotVisible"
      custom-class="upload_screenshot_dialog"
      @exit="uploadVisible = false"
    >
      <div style="height: 500px">
        <ImageUploader
          :url="img_url"
          :width="300"
          :uploadKey="`developer/${userInfo.user_id}.jpg`"
          @percentageUpdated="screenShotUploadStatus"
        ></ImageUploader>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import AddIPA from "../general/AddIPA";
import ImageUploader from "../general/ImageUploader";
export default {
  name: "DeveloperApply",
  props: ["devStatus"],
  inject: ["userInfo", "D"],
  components: { AddIPA, ImageUploader },
  data() {
    return {
      uploadVisible: false,
      uploadScreenShotVisible: false,
      resource_id: "",
      percentage: 0,
      canApply: false,
    };
  },
  mounted() {
    this.getCanApply()
  },
  computed: {
    img_url() {
      if (
        this.devStatus.status == this.D.USER.DEVELOPER_STATUS.FAILED.ID &&
        this.percentage != 1
      ) {
        return "";
      }
      if (
        this.devStatus.status == this.D.USER.DEVELOPER_STATUS.NO_DATA.ID &&
        this.percentage != 1
      ) {
        return "";
      } else {
        return `https://cdn.isign.ren/developer/${this.userInfo.user_id}.jpg?t=234`;
      }
    },
  },
  methods: {
    getCanApply() {
      this.$GET(API.user.getCanApplyDeveloper, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.canApply = res.data.open;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    openUploadImgDialog() {
      this.$POST(API.user.developerDeleteExsisVerifyImg, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.uploadScreenShotVisible = true;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    screenShotUploadStatus(percentage) {
      this.percentage = percentage;
    },
    fileUploadComplete(resource_id) {
      this.resource_id = resource_id;
      let _this = this;
      setTimeout(() => {
        _this.uploadVisible = false;
      }, 500);
    },
    apply() {
      this.uploadVisible = false;
      this.$POST(API.user.applyForDeveloper, {
        resource_id: this.resource_id,
        img_url: this.img_url,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "申请提交成功",
          });
          let that = this;
          setTimeout(() => {
            that.$window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style lang='less' scoped>
.sub-wrap {
  width: 100%;
  padding-top: 20px;
}

.title {
  font-size: 20px;
  color: red;
  font-weight: 900;
}

.msg {
  width: 400px;
  margin-top: 10px;
  font-size: 14px;
}
</style>