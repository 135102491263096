<template>
  <div v-if="subscribeInfo" class="order_detail_wrap">
    <SubscribeDetail :subscribeInfo="subscribeInfo"></SubscribeDetail>
    <UserOrderControls :subscribeInfo="subscribeInfo" @refresh="getSubscribeInfo" :type="D.ORDER.TYPE.SUBSCRIBE.ID"/>
  </div>
</template>

<script>
import API from "../../API";
import SubscribeDetail from "../general/SubscribeDetail";
import UserOrderControls from '../general/UserOrderControls';
export default {
  name: "SubscribeDetailUser",
  props: ["subscribe_id"],
  components: {SubscribeDetail,UserOrderControls },
  inject: ["D"],
  data() {
    return {      
      subscribeInfo: false,
    };
  },
  methods: {    
    getSubscribeInfo() {
      this.$GET(API.subscribe.getSubscribeInfo, {
        subscribe_id: this.subscribe_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.subscribeInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },    
  },

  computed: {},
  mounted() {
    this.getSubscribeInfo();
  },
};
</script>

<style lang="less" scoped>
.confirm_order_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirm_button_wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.cancel_reason_option_wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cancel_option_wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.refund_reason_wrap {
  margin-top: 20px;
}

@media screen and (max-width: 550px) {
  
}
</style>