import { render, staticRenderFns } from "./SubscribeDetailDeveloper.vue?vue&type=template&id=5403ed52&scoped=true&"
import script from "./SubscribeDetailDeveloper.vue?vue&type=script&lang=js&"
export * from "./SubscribeDetailDeveloper.vue?vue&type=script&lang=js&"
import style0 from "./SubscribeDetailDeveloper.vue?vue&type=style&index=0&id=5403ed52&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5403ed52",
  null
  
)

export default component.exports