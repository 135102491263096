<template>
  <div class="my_app_wrap">
    <div class="table_content">
    <el-table
      :data="init_subscribe_list"
      v-loading="loading"
      @row-click="showDetail"
      :max-height="tableHeight"
    >
      <el-table-column label="" width="60px">
        <template slot-scope="scope">
          <img
            class="logo"
            :src="appMap[scope.row.bus_info.app_id].logo"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column label="App" width="80px">
        <template slot-scope="scope">
          {{ appMap[scope.row.bus_info.app_id].name }}
        </template>
      </el-table-column>
      <el-table-column label="签名开发者" width="100px">
        <template slot-scope="scope">
          {{ scope.row.developer_name }}
        </template>
      </el-table-column>
      <el-table-column label="设备" width="150px">
        <template slot-scope="scope">
          {{ scope.row.device_id | deviceName }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100px">
        <template slot-scope="scope">
          {{ scope.row.status | statusName }}
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px;">已过期的App不会显示在此处。</div>
    </div>
  </div>
</template>
<script>
import API from "../../API";
export default {
  name: "MyApp",
  inject: ["appMap"],
  components: {},
  data() {
    return {
      init_subscribe_list: [],
      subscribe_list: [],
      subscribeInfo: false,
      subscribeDetailVisible: false,
      activeName: "subscribe",
      subscribe_id: "",
      loading: true
    };
  },
  computed: {
    detailTitle() {
      if (this.subscribeInfo) {
        return this.subscribeInfo.bus_info.name;
      }
      return "";
    },
    tableHeight() {
      let width = document.body.clientWidth
      if (width > 550) {
        return 515
      } else {
        return document.body.clientHeight - 315
      }
    }
  },
  methods: {
    tabChanged() {
      if (this.activeName == "subscribe") {
        this.getInitSubscribeList();
      } else {
        this.getAllSubscribeList();
      }
    },
    showSubscribeInfo(row) {
      this.subscribe_id = row._id;
      this.subscribeDetailVisible = true;
    },
    getInitSubscribeList() {
      this.$GET(API.subscribe.getInitSubScribeList, {})
        .then((res) => {
          this.loading = false;
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.init_subscribe_list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getAllSubscribeList() {
      this.$GET(API.subscribe.getSubscribeList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.subscribe_list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    gotoAppList() {
      this.$router.push("appstore");
    },
    showDetail(row) {
      this.$router.push(`/ticket?subscribe_id=${row._id}`);
    },
  },
  mounted() {
    this.getInitSubscribeList();
  },
};
</script>
<style lang="less" scoped>
.my_app_wrap {
  .logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 550px) {
  .my_app_wrap {
    width: 100%;
  }
}
</style>