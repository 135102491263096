<template>
  <div v-loading="loading" class="ipa_detail_wrap window_container">
    <div v-if="resourceInfo" class="window_wrap ipa_content_wrap">
      <DetailNavBar :content="''" />
      <div class="title-1">{{ resourceInfo.file_name }}</div>
      <div class="ipa_info_wrap">
        <div class="info_wrap">
          <div class="info_title">文件上传日期</div>
          <div class="left_item under_line">
            <div class="info">{{ resourceInfo.upload_ts | timeFilter }}</div>
          </div>
        </div>
        <div class="info_wrap">
          <div class="info_title">文件失效日期</div>
          <div class="left_item under_line">
            <div class="info">{{ resourceInfo.expire_ts | timeFilter }}</div>
          </div>
        </div>
        <div class="info_wrap">
          <div class="info_title">上传状态</div>
          <div class="left_item under_line">
            <div class="info">
              {{ resourceInfo.uploaded ? "已上传" : "未上传" }}
            </div>
          </div>
        </div>
      </div>
      <div class="ipa_info_wrap">
        <div class="info_wrap">
          <div class="info_title">MD5</div>
          <div class="left_item under_line">
            <div class="info">{{ resourceInfo.md5 }}</div>
          </div>
        </div>
        <div class="info_wrap">
          <div class="info_title">签名订单数</div>
          <div class="left_item under_line">
            <div class="info">{{ order_list.length }}</div>
          </div>
        </div>
        <div class="info_wrap">
          <div class="info_title">公开此 IPA</div>
          <div class="left_item">
            <el-switch
              v-model="resourceInfo.is_public"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="updatePublicStatus"
              :disabled="userInfo.user_id != resourceInfo.user_id"
            >
            </el-switch>
            <span v-if="resourceInfo.is_public" style="margin-left: 10px"
              >管理员可能移除不合适的IPA</span
            >
          </div>
        </div>
      </div>
      <div class="option_panel">
        <el-button
        v-if="userInfo.user_id == resourceInfo.user_id"
          type="danger"
          class="option_item"
          @click="deleteConfirmDialogVisible = true"
          >删除</el-button
        >
        <el-button
          type="primary"
          class="option_item"
          @click="createOrderDialogVisible = true"
          >签名</el-button
        >
      </div>
      <div style="margin-top: 10px" class="title-2">订单列表</div>
      <el-table
        style="margin-top: 10px"
        :data="order_list"
        max-height="450px"
        @row-click="openOrderDetail"
      >
        <el-table-column label="状态" width="90px">
          <template slot-scope="scope">
            {{ scope.row.status | statusName }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="120px">
          <template slot-scope="scope">
            {{ scope.row.status_list[0].time | timeFilter }}
          </template>
        </el-table-column>
        <el-table-column label="设备" width="200px">
          <template slot-scope="scope">
            <div v-for="(id, index) in scope.row.device_list" :key="index">
              {{ id | deviceName }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="发布签名请求"
      :visible.sync="createOrderDialogVisible"
      custom-class="create_order_dialog"
      @closed="getOrderList"
      :modal="true"
    >
      <CreateOrder v-if="createOrderDialogVisible" :resource_id="resource_id" />
    </el-dialog>
    <el-dialog
      title="确认删除？"
      :visible.sync="deleteConfirmDialogVisible"
      custom-class="delete_confirm_dialog"
      :modal="true"
    >
      <div>此操作将永久删除该文件与相关订单, 是否继续?</div>
      <div class="option_panel">
        <el-button
          class="option_item"
          type="danger"
          size="small"
          @click="deleteFile"
          >删除</el-button
        >
        <el-button
          class="option_item"
          type="normal"
          size="small"
          @click="deleteConfirmDialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="详情"
      :visible.sync="orderDetailVisible"
      custom-class="order_detail_dialog"
      :modal-append-to-body="false"
      @closed="getOrderList"
    >
      <OrderDetailUser
        v-if="orderDetailVisible"
        :order_id="order_id"
        @exit="orderDetailClosed"
      />
    </el-dialog>
  </div>
</template>
<script>
import API from "../API";
import DetailNavBar from "../components/general/DetailNavBar";
import CreateOrder from "../components/PersonalCenter/CreateOrder/CreateOrder";
import OrderDetailUser from "../components/PersonalCenter/OrderDetailUser";
export default {
  name: "IPADetail",
  inject: ["userInfo"],
  components: {
    DetailNavBar,
    CreateOrder,
    OrderDetailUser,
  },
  computed: {
    resource_id() {
      return this.$route.query.resource_id;
    },
  },
  data() {
    return {
      loading: true,
      resourceInfo: false,
      createOrderDialogVisible: false,
      deleteConfirmDialogVisible: false,
      order_list: [],
      order_id: "",
      orderDetailVisible: false,
    };
  },
  methods: {
    orderDetailClosed() {
      this.getOrderList();
      this.orderDetailVisible = false;
    },
    openOrderDetail(row) {
      this.order_id = row._id;
      this.orderDetailVisible = true;
    },
    getResourceInfo() {
      this.loading = true;
      this.$GET(API.resource.getResourceInfo, { resource_id: this.resource_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.resourceInfo = res.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
          setTimeout(() => {
            document.location.href = "/";
          }, 2000);
          this.loading = false;
        });
    },
    getOrderList() {
      this.$GET(API.order.getOrderListWithResourceId, {
        resource_id: this.resource_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.order_list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    deleteFile() {
      this.$POST(API.resource.deleteResource, { resource_id: this.resource_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "删除成功",
          });
          let _this = this;
          setTimeout(() => {
            _this.$router.back();
          }, 1000);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    updatePublicStatus() {
      this.$POST(API.resource.setResourcePublic, {
        resource_id: this.resource_id,
        is_public: this.resourceInfo.is_public,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          let msg = this.resourceInfo.is_public ? "已设为公开" : "已取消公开"
          this.$message({
          type: 'success',
           message: msg,
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getResourceInfo();
    this.getOrderList();
  },
};
</script>
<style lang="less" scoped>
.ipa_detail_wrap {
  .ipa_content_wrap {
    padding: 20px;
    .ipa_info_wrap {
      width: 700px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .info_wrap {
        width: 220px;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .ipa_detail_wrap {
    .ipa_content_wrap {
      padding: 20px;
      .ipa_info_wrap {
        display: flex;
        flex-direction: column;
        .info_wrap {
          width: 100%;
        }
      }
    }
  }
}
</style>