<template>
  <div v-if="orderInfo || subscribeInfo" class="order_detail_wrap">
    <div class="option_panel">
      <el-button
        class="option_item"
        type="success"
        size="normal"
        v-if="buttonVisible(D.ORDER.STATUS.SIGNING.ID)"
        @click="handlePick"
        ><i class="el-icon-check"></i> 接单</el-button
      >

      <el-button
        class="option_item"
        type="primary"
        size="normal"
        @click="$downloadIpa(orderInfo.resource_id, orderInfo.resource_info.file_name)"
        v-if="type == 'SINGLE'"
        ><i class="el-icon-download"></i> 下载原始IPA</el-button
      >

      <el-button
        class="option_item"
        v-if="buttonVisible(D.ORDER.STATUS.SIGNED.ID) && type == 'SINGLE'"
        type="primary"
        size="normal"
        @click="uploadIPAVisible = true"
        ><i class="el-icon-upload2"></i> 上传已签名IPA</el-button
      >
      <el-button
        class="option_item"
        type="primary"
        size="normal"
        v-if="buttonVisible('DOWNLOAD_SIGNED')"
        @click="handleDownload"
        ><i class="el-icon-document-checked"></i> 下载已签名IPA</el-button
      >
      <el-button
        type="normal"
        size="normal"
        class="option_item"
        @click="handleDownloadUdid"
        v-if="type=='SINGLE'"
        ><i class="el-icon-setting"></i> 下载UDID列表</el-button
      >

      <a
        :href="installPlistPath"
        class="option_item"
        v-if="buttonVisible('INSTALL')"
      >
        <el-button style="width: 100%" type="success" size="normal"
          ><i class="el-icon-check"></i> 本机安装</el-button
        >
      </a>

      <el-button
        type="danger"
        size="normal"
        v-if="buttonVisible(D.ORDER.STATUS.NOT_SIGNABLE.ID) && type == 'SINGLE'"
        class="option_item"
        @click="handleNotSignable"
        ><i class="el-icon-close"></i> IPA无法签名</el-button
      >
    </div>
    <el-dialog
      :append-to-body="true"
      title="上传IPA"
      :visible.sync="uploadIPAVisible"
      custom-class="upload_dialog"
    >
      <AddIPA
        v-if="uploadIPAVisible"
        style="width: 100%"
        @uploadComplete="uploadComplete"
      ></AddIPA>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import AddIPA from "../general/AddIPA";
export default {
  name: "DeveloperOrderControls",
  props: ["orderInfo", "subscribeInfo", "type"],
  components: { AddIPA },
  inject: ["D", "productMap"],
  data() {
    return {
      uploadIPAVisible: false,
    };
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    buttonVisible(next) {
      let status = "";
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        status = this.orderInfo.status;
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        status = this.subscribeInfo.status;
      } else {
        return false;
      }
      let list = this.D.ORDER.STATUS_ALLOW_LIST[status];
      if (!list || list.length == 0) {
        return false;
      }
      for (let allow of list) {
        if (allow == next) {
          return true;
        }
      }
      return false;
    },
    //general
    handlePick() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.pickOrder();
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.pickSubscribe();
      }
    },
    handleDownload() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.$downloadIpa(this.orderInfo.signed_resource_id);
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        this.$downloadIpa(this.subscribeInfo.signed_resource_id, `[isign]${this.subscribeInfo.version_info.version}.ipa`);
      }
    },
    handleDownloadUdid() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        this.downloadOrderUdid();
      }
    },
    handleNotSignable() {
      this.$POST(API.order.developerConfirmNotSignable, {
        order_id: this.orderInfo._id,
        reason: "无法签名",
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
          this.$emit('refresh')
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    //subscribe
    pickSubscribe() {
      this.$POST(API.subscribe.developerLockSubscribe, {
        subscribe_id: this.subscribeInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "接单成功",
          });
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },

    //order
    pickOrder() {
      this.$POST(API.order.lockOrder, { order_id: this.orderInfo._id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$emit("refresh");
          this.$message({
            type: "success",
            message: "已接单",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    uploadComplete(resource_id) {
      this.$POST(API.order.signDone, {
        resource_id: resource_id,
        order_id: this.orderInfo._id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "您又帮助了一位朋友，感谢您！",
          });
          this.uploadIPAVisible = false;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    downloadOrderUdid() {
      var element = document.createElement("a");
      var message = "Device ID	Device Name	Device Platform\n";
      for (let deviceInfo of this.orderInfo.device_info_list) {
        let platform = "ios";
        let type = this.productMap[deviceInfo.product_id].type;
        if (type == "Mac") {
          platform = "mac";
        }
        message += `${deviceInfo.udid}	${deviceInfo.udid}	${platform}\n`;
      }
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(message)
      );
      element.setAttribute("download", "UDID列表");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },

  computed: {
    installPlistPath() {
      if (this.type == this.D.ORDER.TYPE.SINGLE.ID) {
        return `itms-services://?action=download-manifest&url=https://www.isign.ren/plist/${this.orderInfo.signed_resource_id}.plist`;
      } else if (this.type == this.D.ORDER.TYPE.SUBSCRIBE.ID) {
        return `itms-services://?action=download-manifest&url=https://www.isign.ren/plist/${this.subscribeInfo.signed_resource_id}.plist`;
      }
      return "";
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.confirm_order_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirm_button_wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.cancel_reason_option_wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cancel_option_wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.refund_reason_wrap {
  margin-top: 20px;
}

@media screen and (max-width: 550px) {
}
</style>