<template>
  <div class="wrap">
    <div class="title-1">开发者申请列表</div>
    <el-switch
      v-model="canApply"
      active-text="允许新申请"
      inactive-text="不允许新申请"
      style="margin-bottom: 20px"
      @change="setCanApply"
    ></el-switch>
    <el-table :data="list" style="width: 850px" max-height="400px">
      <el-table-column label="用户名" width="100">
        <template slot-scope="scope">
          <i class="el-icon-user"></i>
          <span style="margin-left: 5px">{{ scope.row.user_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.apply_time | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="过期时间" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.expire_time | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            @click="openDetailDialog(scope.row)"
            >查看</el-button
          >
          <el-button
            @click="setDeveloperStatus(scope.row.user_id, 'NORMAL')"
            type="success"
            size="mini"
            >启用</el-button
          >
          <el-button
            @click="setDeveloperStatus(scope.row.user_id, 'DISABLED')"
            type="danger"
            size="mini"
            >禁用</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="申请详情"
      :visible.sync="confirmDeveloperApplyVisible"
      custom-class="confirm_developer_apply_dialog"
    >
      <div class="detail_wrap">
        <img style="width: 300px" :src="img_url" alt="没有上传图片" />
        <div>
          <div>
            <div style="margin-bottom: 10px; font-weight: 900">
              是否通过开发者申请
            </div>
            <el-radio v-model="confrimStr" label="true">通过</el-radio>
            <el-radio v-model="confrimStr" label="false">拒绝</el-radio>
          </div>
          <div v-if="confirm == true">
            <div style="margin-top: 20px; font-weight: 900">
              开发者会员过期时间
            </div>
            <el-date-picker
              style="width: 200px"
              prefix-icon="x"
              v-model="expire_time"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <div v-if="!confirm">
            <el-input
              style="width: 200px"
              type="textarea"
              :rows="4"
              placeholder="请输入拒绝理由"
              v-model="msg"
            >
            </el-input>
          </div>
          <div style="margin-top: 20px">
            <el-button type="success" @click="submit">提交</el-button>
            <el-button
              type="primary"
              @click="$downloadIpa(resource_id, user_name)"
              >下载IPA</el-button
            >
            <el-button type="danger" @click="resetDeveloperDevices"
              >重置设备列表</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "DeveloperApply",
  data() {
    return {
      list: [],
      confirmDeveloperApplyVisible: false,
      img_url: "",
      expire_time: 0,
      msg: "",
      confrimStr: "true",
      resource_id: "",
      user_id: "",
      user_name: "",
      canApply: false,
    };
  },
  computed: {
    confirm() {
      if (this.confrimStr == "true") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    setDeveloperStatus(user_id, status) {
      this.$POST(API.user.adminSetDeveloperStatus, {
        user_id: user_id,
        status: status,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.getApplyList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    setCanApply() {
      this.$POST(API.user.adminSetCanApplyDeveloper, { open: this.canApply })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getCanApply() {
      this.$GET(API.user.getCanApplyDeveloper, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.canApply = res.data.open;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    openDetailDialog(row) {
      this.resource_id = row.resource_id;
      this.img_url = row.img_url;
      this.user_id = row.user_id;
      this.user_name = row.user_name;
      if (row.expire_time == -1) {
        this.expire_time = Date.parse(new Date());
      } else {
        this.expire_time = row.expire_time;
      }

      this.img_url = row.img_url + "?t=123";
      this.confirmDeveloperApplyVisible = true;
    },
    resetDeveloperDevices() {
      this.$POST(API.user.adminResetDeveloperDevices, { user_id: this.user_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    getApplyList() {
      this.$GET(API.user.applyForDeveloperList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
          this.$message({
            type: "success",
            message: "刷新成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    submit() {
      console.log(this.expire_time);
      if (this.confirm) {
        this.msg = "通过";
      } else {
        this.expire_time = 0;
      }
      this.$POST(API.user.adminConfirmDeveloperStatus, {
        user_id: this.user_id,
        confirm: this.confirm,
        msg: this.msg,
        expire_time: Date.parse(this.expire_time), //elment出来的时间是Date类型，需要转换成时间戳
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "ok",
          });
          this.getApplyList();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
  mounted() {
    this.getApplyList();
    this.getCanApply();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;

  .detail_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
