<template>
  <div class="register_wrap">
    <div>绑定的邮箱</div>
    <el-input v-model="email"></el-input>
    <div style="margin-top: 20px">验证码</div>
    <el-input v-model="captcha"></el-input>
    <img
      class="captcha"
      :src="captchaImg"
      @click="getCaptcha()"
      alt="验证码加载中.."
    />
    <div class="controls_wrap">
      <el-button type="primary" style="width: 100%" @click="forget" :disabled="disableButton"
        >重置密码</el-button
      >
    </div>
  </div>
</template>
<script>
import API from "../../API";
export default {
  name: "Forget",
  data() {
    return {
      email: "",
      captcha: "",
      captchaImg: "",
      disableButton: false,
    };
  },
  methods: {
    getCaptcha() {
      this.$GET(API.user.validation, {})
        .then((res) => {
          if (res.data.status == "ok") {
            this.captchaImg = res.data.base64Img;
          } else {
            throw new Error(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    forget() {
      this.disableButton = true;
      this.$POST(API.user.forget, {
        captcha: this.captcha,
        email: this.email,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.disableButton = false;
          this.$message({
            type: "success",
            message: "重置密码邮件已发送到您的邮箱，请查收。",
          });
        })
        .catch((error) => {
          this.disableButton = false;
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    this.getCaptcha();
  },
};
</script>
<style lang="less" scoped>
img {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 550px) {
}
</style>