<template>
  <div class="window_container">
    <div v-if="subscribeInfo" class="ticket_detail_wrap window_wrap">
      <div class="base_info">
        <AppInfo :app_id="subscribeInfo.bus_info.app_id"></AppInfo>
        <div class="device_info_wrap">
          <div class="title">设备信息</div>
          {{ subscribeInfo.device_id | deviceName }}
          <div>({{ subscribeInfo.device_id | deviceUdid }})</div>
          <div class="expire_time">
            签名有效期至： {{ subscribeInfo.bus_info.expire_time | dayFilter }}
          </div>
        </div>
      </div>
      <div class="detail_info_wrap">
        <DetailNavBar content="详情" />
        <el-tabs v-model="activeTab" @tab-click="tabChanged">
          <el-tab-pane label="订单列表" name="update">
            <b style="color: green">请点击订单列表中的项目查看订单详情。</b>
            <el-table
              style="margin-top: 10px"
              :data="update_list"
              max-height="450px"
              @row-click="showSubscribeDetail"
            >
              <el-table-column label="版本" width="100px">
                <template slot-scope="scope">
                  {{ scope.row.version_info.version }}
                </template>
              </el-table-column>
              <el-table-column label="创建日期" width="85px">
                <template slot-scope="scope">
                  {{ scope.row.status_list[0].time | dayFilter }}
                </template>
              </el-table-column>
              <el-table-column label="状态" width="70px">
                <template slot-scope="scope">
                  {{ scope.row.status | statusName }}
                </template>
              </el-table-column>
              <el-table-column label="类型" width="75px">
                <template slot-scope="scope">
                  {{ scope.row.is_init ? '首次安装' : '版本更新' }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="所有版本" name="all">
            <el-table
              style="margin-top: 10px"
              :data="version_list"
              max-height="450px"
            >
              <el-table-column label="版本" width="100px">
                <template slot-scope="scope">
                  {{ scope.row.version }}
                </template>
              </el-table-column>
              <el-table-column label="发布日期" width="90px">
                <template slot-scope="scope">
                  {{ scope.row.time | dayFilter }}
                </template>
              </el-table-column>
              <el-table-column label="" width="100px">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="success"
                    @click="buyVersion(scope.row._id)"
                    >签名</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog
        title="订单详情"
        :visible.sync="detail_dialog_visible"
        custom-class="order_detail_dialog"
        :modal="true"
      >
        <SubscribeDetailUser
          v-if="detail_dialog_visible"
          :subscribe_id="selected_id"
        ></SubscribeDetailUser>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import AppInfo from '../components/general/AppInfo'
import SubscribeDetailUser from '../components/PersonalCenter/SubscribeDetailUser'
import API from '../API'
import DetailNavBar from '../components/general/DetailNavBar'

export default {
  name: 'TicketDetail',
  inject: ['D'],
  components: {
    AppInfo,
    SubscribeDetailUser,
    DetailNavBar,
  },
  data() {
    return {
      subscribeInfo: false,
      update_list: [],
      version_list: [],
      activeTab: 'update',
      payVisible: false,
      detail: false,
      detail_dialog_visible: false,
      selected_id: '',
    }
  },
  computed: {
    subscribe_id() {
      return this.$route.query.subscribe_id
    },
  },
  methods: {
    installPlistPath(resource_id) {
      return `itms-services://?action=download-manifest&amp;url=https://www.isign.ren/plist/${resource_id}.plist`
    },
    tabChanged() {
      switch (this.activeTab) {
        case 'update':
          this.getOwnUpdateList()
          break
        case 'all':
          this.getVersionList()
          break
        default:
          break
      }
    },
    showSubscribeDetail(row) {
      this.selected_id = row._id
      this.detail_dialog_visible = true
    },

    getOwnUpdateList() {
      this.$GET(API.subscribe.userGetOwnUpdateList, {
        bus_id: this.subscribeInfo.bus_id,
        device_id: this.subscribeInfo.device_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.update_list = res.data.list
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getVersionList() {
      this.$GET(API.subscribe.userGetBusVersionList, {
        bus_id: this.subscribeInfo.bus_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.version_list = res.data.list
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    buyVersion(version_id) {
      this.$POST(API.subscribe.newSubscribe, {
        bus_id: this.subscribeInfo.bus_id,
        device_id: this.subscribeInfo.device_id,
        version_id: version_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.activeTab = 'update'
          this.selected_id = res.data.subscribe_id
          this.detail_dialog_visible = true
        })
        .catch((error) => {
          this.activeTab = 'update'
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
    getSubscribeInfo() {
      this.$GET(API.subscribe.getSubscribeInfo, {
        subscribe_id: this.subscribe_id,
      })
        .then((res) => {
          if (res.data.status != 'ok') {
            throw new Error(res.data.msg)
          }
          this.subscribeInfo = res.data.data
          this.getOwnUpdateList()
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          })
        })
    },
  },
  mounted() {
    this.getSubscribeInfo()
  },
}
</script>
<style lang="less" scoped>
.ticket_detail_wrap {
  display: flex;
  flex-direction: row;
  .base_info {
    background-color: white;
    width: 250px;
  }
  .detail_info_wrap {
    padding: 20px;
    min-height: 50vh;
  }
  .device_info_wrap {
    border-top: 1px solid #dddddd;
    margin-bottom: 10px;
    padding: 20px;
    .title {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
@media screen and (max-width: 550px) {
  .ticket_detail_wrap {
    flex-direction: column;
    .base_info {
      background-color: white;
      width: 100%;
    }
  }
}
</style>
