<template>
  <div class="wrap">
    <div class="title-1">IPA列表</div>
    <el-table :data="list" style="width: 850px" max-height="400px">
      <el-table-column label="文件名" width="100">
        <template slot-scope="scope">
          <i class="el-icon-user"></i>
          <span style="margin-left: 5px">{{ scope.row.file_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上传者ID" width="190">
        <template slot-scope="scope">
          <i class="el-icon-user"></i>
          <span style="margin-left: 5px">{{ scope.row.user_id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上传时间" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.upload_ts | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="normal"
            @click="$downloadIpa(scope.row._id)"
            >下载IPA</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleRemove(scope.row._id)"
            >下架</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "PublicIPA",
  data() {
    return {
      list: [],
    };
  },
  computed: {
    pageCount() {
      return this.count / this.size;
    },
  },
  methods: {
    update() {
      this.$GET(API.resource.getPublicResourceListAdmin, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
          this.$message({
            type: "success",
            message: "列表刷新成功",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },   
    handleRemove(resource_id) {
      this.$POST(API.resource.setResourcePublicAdmin, {
        is_public: false,
        resource_id: resource_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "下架成功",
          });
          this.update();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
  },
  mounted() {
    this.update(0, this.size);
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;
  
}
</style>