<template>
  <div class="my_info_wrap">
    <div class="title-1">{{ userInfo.user_name }}</div>

    <div class="info_wrap">
      <div class="info_title">账号状态</div>
      <div class="info_content_wrap">
        <div class="left_item under_line">
          <div class="info">{{ userStatus }}</div>
        </div>
        <div class="right_item"></div>
      </div>
    </div>

    <div class="info_wrap">
      <div class="info_title">账号类型</div>
      <div class="info_content_wrap">
        <div class="left_item under_line">
          <div class="info">{{ userType }}</div>
        </div>
        <div class="right_item">
          <el-link
            type="primary"
            @click="
              $router.push({ path: '/user', query: { page: 'Developer' } })
            "
            >开发者申请</el-link
          >
        </div>
      </div>
    </div>

    <div class="info_wrap">
      <div class="info_title">电子邮箱</div>
      <div class="info_content_wrap">
        <div class="left_item under_line">
          <div class="info">{{ userEmail }}</div>
        </div>
        <div class="right_item">
          <el-link type="primary" @click="bindEmailVisible = true"
            >修改或绑定</el-link
          >
        </div>
      </div>
    </div>

    <div class="info_wrap">
      <div class="info_title">密码</div>
      <div class="info_content_wrap">
        <div class="left_item under_line">
          <div class="info">* * * * * * * *</div>
        </div>
        <div class="right_item">
          <el-link type="primary" @click="showChangePasswordDialog = true"
            >修改密码</el-link
          >
        </div>
      </div>
    </div>

    <div class="info_wrap">
      <div class="info_title">绑定微信</div>
      <div class="info_content_wrap">
        <div class="left_item under_line">
          <div class="info">
            {{ userInfo.isWechatBind ? "已绑定" : "未绑定" }}
          </div>
        </div>
        <div class="right_item">
          <el-link type="primary" @click="showIsignWechat = true"
            >查看公众号</el-link
          >
        </div>
      </div>
    </div>

    <el-button type="danger" style="margin-top: 20px" @click="logout"
      >退出登录</el-button
    >

    <el-dialog title="iSign 签名网公众号" :visible.sync="showIsignWechat" custom-class="normal_dialog" :modal="false">
      <div>
        <img style="width: 100%;" src="/images/isign_wechat.jpg" alt="">
        <div style="text-align: center;">
          微信扫码或搜索公众号<div style="font-size: 16px; color: red;">iSign签名网</div>
          绑定微信后可接收订单相关通知。
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="绑定邮箱"
      :visible.sync="bindEmailVisible"
      custom-class="email_dialog"
      @exit="bindEmailVisible = false"
    >
      <BindEmail></BindEmail>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="showChangePasswordDialog"
      custom-class="change_password_dialog"
      :modal="true"
    >
      <div class="info_wrap">
        <div class="info_title">当前密码</div>
        <div class="info_content_wrap">
          <el-input v-model="oldPassword" type="password"></el-input>
        </div>
      </div>
      <div class="info_wrap">
        <div class="info_title">新的密码</div>
        <div class="info_content_wrap">
          <el-input v-model="newPassword" type="password"></el-input>
        </div>
      </div>
      <div class="info_wrap">
        <div class="info_title">
          确认密码
          <span
            v-if="newPassword != newPasswordAgain"
            style="color: red; float: right"
            >两次输入密码不一致</span
          >
        </div>
        <div class="info_content_wrap">
          <el-input v-model="newPasswordAgain" type="password"></el-input>
        </div>
      </div>
      <div class="option_panel">
        <el-button class="option_item" type="normal" @click="clearPassword"
          >取消</el-button
        >
        <el-button
          class="option_item"
          type="primary"
          :disabled="newPassword != newPasswordAgain"
          @click="changePassword"
          >确认修改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import D from "../../const.js";
import BindEmail from "./BindEmail";
export default {
  components: {
    BindEmail,
  },
  inject: ["userInfo"],
  data() {
    return {
      bindEmailVisible: false,
      loadComplete: false,
      showChangePasswordDialog: false,
      oldPassword: "",
      newPassword: "",
      newPasswordAgain: "",
      showIsignWechat: false,
    };
  },
  computed: {
    userType() {
      if (!this.userInfo.type) {
        return "";
      }
      return D.USER.TYPE[this.userInfo.type].DESC;
    },
    userStatus() {
      if (!this.userInfo.status) {
        return "";
      }
      return D.USER.STATUS[this.userInfo.status].DESC;
    },
    userEmail() {
      if (this.userInfo.email == "") {
        return "未绑定邮箱";
      }
      if (this.userInfo.email_status == D.USER.EMAIL_STATUS.NOT_VERIFIED.ID) {
        return `${this.userInfo.email} (未验证)`;
      } else {
        return this.userInfo.email;
      }
    },
  },
  methods: {
    bindWechat() {
      document.location =
        "https://open.weixin.qq.com/connect/qrconnect?appid=wx8afb3683c14f97c6&redirect_uri=https%3A%2F%2Fwww.isign.ren%2F%23%2Fwechat_login&response_type=code&scope=snsapi_login";
    },
    clearPassword() {
      this.showChangePasswordDialog = false;
      this.newPassword = "";
      this.newPasswordAgain = "";
      this.oldPassword = "";
    },
    changePassword() {
      this.$POST(API.user.changePassword, {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "修改密码成功！请重新登录。",
          });
          let _this = this;
          setTimeout(() => {
            _this.logout();
          }, 1000);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    logout() {
      this.$cookies.remove("token");
      this.$window.location.href = "/";
    },
  },
  mounted() {
    console.log(this.userInfo);
  },
};
</script>

<style lang="less" scoped>
.my_info_wrap {
  box-sizing: border-box;
  padding: 20px;
  text-align: left;
  width: 600px;
  height: 600px;
}

@media screen and (max-width: 550px) {
  .my_info_wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
