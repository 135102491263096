<template>
  <div class="wrap">
    <el-button
      class="all_readed_button"
      @click="setReaded"
      type="primary"
      round
      size="small"
      >全部标记已读</el-button
    >
    <div class="title-1">站内信</div>
    <div class="message-wrap" @scroll="scrolling">
      <div class="unread-wrap">
        <div class="msg-box" v-for="(msg, index) in unread" :key="index">
          <div class="msg-title">{{ msg.title }}</div>
          <div class="msg-content">{{ msg.content }}</div>
          <div class="msg-time">
            <div class="url"></div>
            <div>{{ msg.time | timeFilter }}</div>
          </div>
        </div>
        <div v-if="unread.length == 0">暂无未读消息</div>
      </div>
      <div class="seperate_line_wrap">
        <div class="seperate_line"></div>
        <div class="info">以上是未读消息</div>
        <div class="seperate_line"></div>
      </div>
      <div class="history-wrap">
        <div class="msg-box" v-for="(msg, index) in history" :key="index">
          <div class="msg-title">{{ msg.title }}</div>
          <div class="msg-content">{{ msg.content }}</div>
          <div class="msg-time">
            <div class="url" @click="toUrl(msg.url)">跳转</div>
            <div>{{ msg.time | timeFilter }}</div>
          </div>
        </div>
        <div v-if="nomore">已加载全部消息</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "Message",
  data() {
    return {
      unread: [],
      history: [],
      page: 0,
      size: 5,
      loading: true,
      nomore: false,
    };
  },
  methods: {
    toUrl(url) {
      if (url != "-1") {
        this.$window.location.href = url;
      }
    },
    getMessage(type) {
      if (type == "all") {
        this.$GET(API.user.getMessageBox, {
          readed: false,
          page: 0,
          size: this.size,
        })
          .then((res) => {
            if (res.data.status != "ok") {
              throw new Error(res.data.msg);
            }
            this.unread = res.data.list;
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      }
      if (type == "all" || type == "readed") {
        if (this.nomore) {
          return;
        }
        this.$GET(API.user.getMessageBox, {
          readed: true,
          page: this.page,
          size: this.size,
        })
          .then((res) => {
            if (res.data.status != "ok") {
              throw new Error(res.data.msg);
            }
            if (res.data.list.length == 0) {
              this.nomore = true;
            } else {
              this.history = this.history.concat(res.data.list);
            }
            this.loading = false;
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
            this.loading = false;
          });
      }
    },
    setReaded() {
      this.$POST(API.user.setMessageReaded, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.$window.location.reload();
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    scrolling(e) {
      let scrollTop = e.target.scrollTop,
        scrollHeight = e.target.scrollHeight,
        clientHeight = e.target.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.page++;
        this.getMessage("readed");
      }
    },
  },
  mounted() {
    this.getMessage("all");
  },
};
</script>

<style lang='less' scoped>

.seperate_line_wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  .info {
    width: 320px;
    margin: 0 20px 0 20px;
    text-align: center;
  }
  .seperate_line {
    width: 100%;
    height: 1px;
    background: #AAAAAA;
  }
}

.wrap {
  width: 600px;
  height: 600px;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  //   background-color: #000;
  .all_readed_button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  

  .message-wrap {
    overflow-y: scroll;
    height: 500px;
    padding: 20px 0 20px 2px;
    .msg-box {
      width: 99%;
      box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.04);
      padding: 20px 20px 10px 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.7);
      .msg-title {
        font-size: 14px;
        font-weight: 900;
        margin-bottom: 5px;
      }
      .msg-content {
        font-size: 14px;
        color: #333333;
      }
      .msg-time {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        color: #666666; 
        .url {
          cursor: pointer;
          color: #025db8;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .wrap {
    width: 100%;
    .message-wrap {
    height: 100vh;
  }
  }
  
}

</style>