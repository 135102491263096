<template>
  <div class="personal_center_wrap window_container">
    <div class="personal_center window_wrap" ref="content">
      <div class="content_wrap" :style="{ left: mobileContentLeft }">
        <div class="mobile_content_nav">
          <el-button class="back_button" type="text" @click="goback">
            <i class="el-icon-arrow-left"></i> 返回</el-button
          >
        </div>
        <MyInfo ref="contentPage" v-if="page == 'MyInfo'" />
        <MyDevices ref="contentPage" v-if="page == 'MyDevices'" />
        <!-- <MyIPA ref="contentPage" v-if="page == 'MyIPA'" /> -->
        <MyPublish ref="contentPage" v-if="page == 'MyPublish'" />
        <Developer ref="contentPage" v-if="page == 'Developer'" />
        <MyOrder ref="contentPage" v-if="page == 'MyOrder'" />
        <MyWallet ref="contentPage" v-if="page == 'MyWallet'" />
        <Message ref="contentPage" v-if="page == 'Message'" />
        <MyBus ref="contentPage" v-if="page == 'MyBus'" />
        <PaidApps ref="contentPage" v-if="page == 'PaidApps'" />
        <DeveloperInfo ref="contentPage" v-if="page == 'DeveloperInfo'" />
        <OutterOrder ref="contentPage" v-if="page == 'OutterOrder'" />
        <LinkAccount ref="contentPage" v-if="page == 'LinkAccount'" />
      </div>
      <CustomNavGuide
        class="navi"
        :pageConfig="pageConfig"
        :page="page"
        @pageChanged="pageChanged"
        :style="{ left: mobileNavLeft }"
      ></CustomNavGuide>
    </div>
  </div>
</template>

<script>
import CustomNavGuide from '../components/general/CustomNavGuide'
import MyInfo from '../components/PersonalCenter/MyInfo'
import MyDevices from '../components/PersonalCenter/MyDevices'
// import MyIPA from "../components/PersonalCenter/MyIPA";
import MyPublish from '../components/PersonalCenter/MyPublish'
import Developer from '../components/PersonalCenter/Developer'
import MyOrder from '../components/PersonalCenter/MyOrder'
import MyWallet from '../components/PersonalCenter/MyWallet'
import Message from '../components/PersonalCenter/Message'
import MyBus from '../components/PersonalCenter/MyBus/MyBus'
import PaidApps from '../components/PersonalCenter/PaidApps'
import DeveloperInfo from '../components/PersonalCenter/DeveloperInfo'
import OutterOrder from '../components/PersonalCenter/OutterOrder.vue'
import LinkAccount from '../components/PersonalCenter/LinkAccount.vue'
import D from '../const'

export default {
  components: {
    CustomNavGuide,
    MyInfo,
    MyDevices,
    // MyIPA,
    MyPublish,
    Developer,
    MyOrder,
    MyWallet,
    Message,
    MyBus,
    PaidApps,
    DeveloperInfo,
    OutterOrder,
    LinkAccount,
  },
  inject: ['userInfo', 'baseInfo', 'refreshUserInfo'],
  data() {
    return {
      page: 'root',
      mobileNavLeft: '0px',
      mobileContentLeft: '20vw',
      contentHeight: 500,
      pageConfig: [
        {
          title: '通用',
          items: [
            {
              title: '我的资料',
              key: 'MyInfo',
              msg: 0,
            },
            {
              title: '我的设备',
              key: 'MyDevices',
              msg: 0,
            },
            // {
            //   title: "定制签名",
            //   key: "MyIPA",
            //   msg: 0,
            // },
            {
              title: '已购 App',
              key: 'PaidApps',
              msg: 0,
            },
          ],
        },
        {
          title: '开发者',
          items: [
            {
              title: '开发者申请',
              key: 'Developer',
              msg: 0,
            },
          ],
        },
        {
          title: '其他',
          items: [
            {
              title: '站内信',
              key: 'Message',
              msg: this.userInfo.unread_count,
            },
          ],
        },
      ],
    } 
  },
  methods: {
    setupDeveloper() {
      console.log(this.userInfo)
      if (this.userInfo.type != D.USER.TYPE.NORMAL_USER.ID) {
        this.pageConfig[1].items.push(
          {
            title: '我的开发者信息',
            key: 'DeveloperInfo',
            msg: 0,
          },
          {
            title: '应用商店订单',
            key: 'MyBus',
            msg: this.userInfo.subscribe,
          },
          {
            title: '外部签名订单',
            key: 'OutterOrder',
            msg: this.userInfo.outterCount,
          },
          {
            title: '定制签名订单',
            key: 'MyOrder',
            msg: this.userInfo.order,
          },
          {
            title: '我的钱包',
            key: 'MyWallet',
            msg: 0,
          },
          {
            title: '关联账号',
            key: 'LinkAccount',
            msg: 0,
          }
        )
      }
    },
    pageChanged(data) {
      this.$router.push({ path: '/user', query: { page: data.key } })
    },
    goback() {
      this.$router.push({ path: '/user', query: { page: 'root' } })
    },
    updateContentSize() {
      let currentPage = this.$route.query.page
      let _this = this
      if (currentPage == 'root') {
        setTimeout(() => {
          _this.page = currentPage
        }, 500)
      } else {
        this.page = currentPage
      }

      if (this.$route.query.page == 'root') {
        this.mobileNavLeft = '0vw'
        this.mobileContentLeft = '-40vw'
      } else {
        this.mobileNavLeft = '-100vw'
        this.mobileContentLeft = '-100vw'
      }
    },
  },
  watch: {
    $route() {
      this.updateContentSize()
    },
  },
  mounted() {
    this.refreshUserInfo()
    this.setupDeveloper()
    this.updateContentSize()
    let _this = this
    window.onresize = function () {
      _this.contentHeight = _this.$refs.content.offsetHeight
    }
  },
}
</script>

<style lang="less" scoped>
.personal_center_wrap {
  .personal_center {
    flex-direction: row-reverse;
    display: flex;
    .mobile_content_nav {
      display: none;
    }
    .navi {
      display: block;
      width: 180px;
    }
    .content_wrap {
      transition: width 0.3s ease-in-out;
    }
  }
}

@media screen and (max-width: 550px) {
  .personal_center_wrap {
    overflow: hidden;
    .personal_center {
      position: relative;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0px;
      box-sizing: border-box;
      width: 200vw;
      .mobile_content_nav {
        display: block;
        height: 40px;
        background-color: white;
        padding-left: 10px;
      }
      .navi {
        width: 100vw;
        transition: left 0.3s ease-in-out;
        position: relative;
        min-height: calc(100vh - 140px);
      }
      .content_wrap {
        width: 100vw !important;
        left: 100vw;
        transition: left 0.3s ease-in-out;
        position: relative;
      }
    }
  }
}
</style>
