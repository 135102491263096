<template>
  <div class="wrap">
    <div class="title-1">提现列表</div>
    <el-table :data="list" style="width: 650px" max-height="350px">
      <el-table-column label="用户名" width="90">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.user_info.user_name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="邮箱" width="150">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.user_info.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="钱包余额" width="90">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.wallet_info.value }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请金额" width="90">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.wallet_info.withdrawing
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提现金额" width="90">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.wallet_info.withdrawing * 0.7
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="" width="90">
        <template slot-scope="scope">
          <el-button type="primary" @click="showDetail(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="提现详情"
      :visible.sync="withdrawDetailVisible"
      custom-class="withdraw_detail_dialog"
    >
      <WithdrawDetail
        v-if="withdrawDetailVisible"
        :user_id="user_id"
      ></WithdrawDetail>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import WithdrawDetail from "./WithdrawDetail";
export default {
  name: "WithdrawList",
  components: { WithdrawDetail },
  data() {
    return {
      withdrawDetailVisible: false,
      list: [],
      walletInfo: {},
      user_id: '',
    };
  },
  methods: {
    getWithdrawList() {
      this.$GET(API.user.getWithDrawList, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    showDetail(row) {
      this.user_id = row.user_info._id;
      this.withdrawDetailVisible = true;
    }
  },
  mounted() {
    this.getWithdrawList();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;
  
}
</style>