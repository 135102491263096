<template>
  <div class="wrap">
    <div class="title-1">订单列表</div>
    <span>总订单数：{{ count }}</span>
    <el-table :data="list" style="width: 850px" height="calc(100% - 150px)">
      <el-table-column label="用户名" width="200">
        <template slot-scope="scope">
          <i class="el-icon-user"></i>
          <span style="margin-left: 5px"
            >{{ scope.row.user_name }} <br />
            ({{ scope.row.user_id }})</span
          >
        </template>
      </el-table-column>
      <el-table-column label="当前状态" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.create_time | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="IPA" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{ scope.row.file_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="getOrderDetail(scope.row)"
            >查看详情</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="handleFakePay(scope.row)"
            >Fake Pay</el-button
          >
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="count"
      :page-size="size"
      :current-page="page"
      @current-change="pageChanged"
    >
    </el-pagination>
    <!-- <el-dialog title="详情" :visible.sync="showDetail" >
      
    </el-dialog> -->
    <el-dialog title="详情" :visible.sync="showDetail" custom-class="order_detail_dialog" :modal="false">
      <OrderDetailAdmin :order_id="order_id" v-if="showDetail" />
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import OrderDetailAdmin from "../AdminPage/OrderDetailAdmin";
export default {
  name: "OrderList",
  components: { OrderDetailAdmin },
  data() {
    return {
      order_id: false,
      showDetail: false,
      list: [],
      count: 0,
      size: 20,
      page: 1,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.count / this.size);
    },
  },
  methods: {
    pageChanged(page) {
      this.page = page;
      this.update(this.page, this.size);
    },
    update(page, size) {
      this.$GET(API.order.listAll, {
        page: page,
        size: size,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.list = res.data.data.list;
          this.count = res.data.data.count;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getOrderDetail(row) {
      this.order_id = row.order_id;
      this.showDetail = true;
    },
    handleFakePay(row) {
      this.$POST(API.order.fakePay, {
        order_id: row.order_id,
      })
        .then((res) => {
          if (res.data.status != "ok") {
            console.log(res.data);
            throw new Error(res.data.msg);
          }
          this.$message("ok");
          this.update(this.page, this.size);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    handleDelete(row) {
      let order_id = row.order_id;
      this.$POST(API.order.delete, {
        order_id: order_id,
      })
        .then((res) => {
          if (res.data.status == "ok") {
            this.$message("删除成功");
            this.update(this.page, this.size);
          } else {
            throw new Error("无法删除订单");
          }
        })
        .catch((error) => {
          this.$message({
            message: error.message,
            type: "error",
          });
        });
    },
  },
  mounted() {
    this.update(0, this.size);
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;
}
</style>