<template>
  <div class="app_store_wrap">
    <div class="app_store_content">
      <div class="app_info_wrap">
        <div class="app_logo_name_wrap">
          <img class="logo" :src="appInfo.logo" alt="" />
          <div class="name_desc_wrap">
            <div class="name">{{ appInfo.name }}</div>
            <div class="desc">{{ appInfo.desc }}</div>
          </div>
        </div>
        <div class="extra_info_wrap">
          <div class="extra_info">
            <div class="title">作者</div>
            <div class="content">{{ appInfo.author }}</div>
          </div>
          <div class="extra_info">
            <div class="title">许可证</div>
            <div class="content">{{ appInfo.license }}</div>
          </div>
          <div class="extra_info">
            <div class="title">官网</div>
            <div class="content">
              <a class="official_site_link" :href="appInfo.url">访问官网</a>
            </div>
          </div>
          <div class="extra_info">
            <div class="title">评分</div>
            <div class="content">
              <el-rate v-model="appInfo.rate" :colors="rateColors"> </el-rate>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeTabName">
          <el-tab-pane label="应用详情" name="app_detail"></el-tab-pane>
          <el-tab-pane label="签名安装" name="bus_list"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="app_content_wrap">
        <iframe
          v-if="activeTabName == 'app_detail'"
          class="app_detail_iframe"
          :src="`https://support.isign.ren/index.php/archives/${appInfo.cid}/`"
          frameborder="0"
          ref="iframe"
          :style="{
            height: iframeHeight,
          }"
        ></iframe>
        <AppBusList
          v-if="activeTabName == 'bus_list'"
          :app_id="appInfo._id"
          :app_fee="appInfo.app_fee"
          style="min-height: 70vh"
        ></AppBusList>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../API";
import AppBusList from "../components/AppStore/AppBusList";
export default {
  name: "AppDetail",
  components: {
    AppBusList,
  },
  data() {
    return {
      appInfo: {},
      rateColors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      activeTabName: "app_detail",
      iframeHeight: "800px",
    };
  },
  methods: {
    installButtonClicked() {
      this.activeTabName = "bus_list";
    },
    getAppInfo() {
      let app_id = this.$route.query.id;
      this.$GET(API.general.getAppInfo, { app_id: app_id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.appInfo = res.data.data;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0)
    this.getAppInfo();
    let _this = this;
    window.addEventListener("message", (event) => {
      _this.iframeHeight = event.data + 'px'
    });
  },
};
</script>

<style lang="less" scoped>
.el-tab-pane {
  display: none;
}
.app_store_wrap {
  padding-top: 50px;
  .app_store_content {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: hidden;
    box-sizing: border-box;
    .app_info_wrap {
      flex: 1;
      padding: 20px;
      .official_site_link {
        text-decoration: none;
        color: blue;
      }
      .app_logo_name_wrap {
        display: flex;
        align-items: flex-start;
        .logo {
          width: 120px;
          border-radius: 20px;
        }
        .name_desc_wrap {
          margin-left: 20px;
          // width: 300px;
          .name {
            font-size: 26px;
            font-weight: 600;
            color: black;
          }
          .desc {
            margin-top: 10px;
            font-size: 16px;
            color: #666666;
          }
        }
        .install_button {
          height: 30px;
          margin-left: 20px;
        }
      }
      .extra_info_wrap {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        display: flex;
        align-items: center;
        .extra_info {
          width: 200px;
          height: 70%;
          padding: 10px 20px 20px 20px;
          border-right: 1px solid #dddddd;
          box-sizing: border-box;
          .title {
            color: #49a3ff;
            font-size: 14px;
          }
          .content {
            color: #666666;
            font-size: 16px;
            margin-top: 5px;
            text-decoration: none;
          }
        }
      }
    }
    .app_content_wrap {
      flex: 3;
      padding: 0;
      .app_detail_iframe {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .app_store_wrap {
    .app_store_content {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0px;
      overflow: hidden;
      box-sizing: border-box;
      .app_info_wrap {
        flex: 1;
        .app_logo_name_wrap {
          display: flex;
          .logo {
            width: 80px;
            height: 80px;
            border-radius: 20px;
          }
          .name_desc_wrap {
            margin-left: 20px;
            .name {
              font-size: 20px;
              font-weight: 600;
              color: black;
            }
            .desc {
              margin-top: 5px;
              font-size: 14px;
              color: #666666;
            }
          }
          .install_button {
            height: 30px;
            margin-left: 10px;
          }
        }
        .extra_info_wrap {
          width: 100%;
          height: 150px;
          margin-top: 20px;
          border-top: 1px solid #cccccc;
          border-bottom: 1px solid #cccccc;
          overflow: scroll;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          .extra_info {
            width: 50%;
            height: 50px;
            padding: 0;
            border-right: 0px solid #dddddd;
            box-sizing: border-box;
            .title {
              color: #49a3ff;
              font-size: 14px;
            }
            .content {
              color: #666666;
              font-size: 16px;
              margin-top: 5px;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
</style>