<template>
  <div class="wrap">
    <div v-if="img == ''" class="plus_wrap">
      <i class="el-icon-plus"></i>
    </div>
    <img v-if="img != ''" style="width: 100%; height: 100%" :src="img" alt="" />
    <input
      type="file"
      ref="input"
      @change="toBase64()"
      accept="image/jpeg,image/png,image/jpg"
      class="upload_input"
    />
  </div>
</template>

<script>
import API from "../../API";
export default {
  name: "ImageUploader",
  props: ["uploadKey", "width", "url"],
  inject: ["qiniuUploader"],
  data() {
    return {
      imgBase64: "",
      imgFile: {},
    };
  },
  computed: {
    img() {
      if (this.imgBase64 != "") {
        return this.imgBase64;
      }
      return this.url;
    },
  },
  methods: {
    startUpload(uploadToken) {
      this.qiniuUploader(
        this.uploadKey,
        uploadToken,
        this.imgFile,
        (percentage, error) => {
          if (error) {
            this.$message({
              type: "error",
              message: error,
            });
            return;
          }
          this.$emit("percentageUpdated", percentage);
        }
      );
    },
    getUploadToken() {
      this.$GET(API.general.getUploadImageToken, {})
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.startUpload(res.data.token);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
    toBase64() {
      let _this = this;
      let file = this.$refs.input.files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        let img = new Image();
        img.src = e.target.result;
        setTimeout(() => {
          let ratio = img.width / img.height;
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");
          // 设置宽高度为等同于要压缩图片的尺寸
          let targetWidth = +_this.width,
            targetHeight = +_this.width / ratio;
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          context.clearRect(0, 0, targetWidth, targetHeight);
          //将img绘制到画布上
          context.drawImage(img, 0, 0, targetWidth, targetHeight);
          //   console.log(targetWidth, targetHeight);
          let base64 = canvas.toDataURL("image/jpeg");
          _this.imgBase64 = base64;
          let imgFile = _this.dataURLtoFile(base64, "test2");
          console.log(imgFile);
          _this.imgFile = imgFile;
          _this.getUploadToken();
        }, 1000);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    dataURLtoFile(dataUrl, fileName) {
      var arr = dataUrl.split(","),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: 'image/jpeg' });
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  border: 1px #dddddd solid;
  .plus_wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 50px;
    }
  }
  .qr_code_info {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    color: #888888;
  }
  img {
    width: 80%;
    display: block;
    margin: auto;
  }
  .upload_input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
  }
}
</style>