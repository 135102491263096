<template>
  <div class="my_order_wrap">
    <div class="title-1">定制签名订单</div>
    <div style="margin-bottom: 10px">
      请注意：IPA
      是用户自由上传的，不一定都能重新签名，请先在自己的设备上验证是否可签。
    </div>
    <el-table :data="order_list" :max-height="tableHeight">
      <el-table-column label="IPA名称" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.resource_info.file_name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="90">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.status | statusName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="120">
        <template slot-scope="scope">
          <span style="margin-left: 5px">{{
            scope.row.time | timeFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="付款金额" width="70">
        <template slot-scope="scope">
          <span style="margin-left: 5px">¥ {{ scope.row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="" width="150">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="openDetailDialog(scope.row)"
            >查看</el-button
          >
          <el-button
            v-if="scope.row.status == D.ORDER.STATUS.WAIT_FOR_PICK.ID"
            size="mini"
            type="success"
            @click="pick(scope.row)"
            >接单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="详情"
      :visible.sync="detailDialogVisible"
      custom-class="order_detail_dialog"
      @close="getDeveloperOrderList"
    >
      <OrderDetailDeveloper
        v-if="detailDialogVisible"
        :order_id="order_id"
      ></OrderDetailDeveloper>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../API";
import OrderDetailDeveloper from "./OrderDetailDeveloper";
export default {
  name: "MyOrder",
  components: { OrderDetailDeveloper },
  inject: ["D"],
  data() {
    return {
      detailDialogVisible: false,
      order_list: [],
      order_id: "",
    };
  },
  computed: {
    tableHeight() {
      let width = document.body.clientWidth;
      if (width > 550) {
        return 515;
      } else {
        return document.body.clientHeight - 315;
      }
    },
  },
  methods: {
    getDeveloperOrderList() {
      this.$GET(API.order.developerOrderList, {
        type: "all",
      })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.order_list = res.data.list;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error.message,
          });
        });
    },
    openDetailDialog(row) {
      this.order_id = row._id;
      this.detailDialogVisible = true;
    },
    pick(row) {
      this.$POST(API.order.lockOrder, { order_id: row._id })
        .then((res) => {
          if (res.data.status != "ok") {
            throw new Error(res.data.msg);
          }
          this.getDeveloperOrderList();
          this.$message({
            type: "success",
            message: "已接单",
          });
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
        });
    },
  },

  mounted() {
    this.getDeveloperOrderList();
  },
};
</script>

<style lang="less" scoped>
.my_order_wrap {
  width: 600px;
  height: 600px;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  //   background-color: #000;

  .udid_item {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
}
@media screen and (max-width: 550px) {
  .my_order_wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>