<template>
  <div v-if="subscribeInfo" class="order_detail_wrap">
    <SubscribeDetail :subscribeInfo="subscribeInfo"></SubscribeDetail>
    <UserOrderControls :subscribeInfo="subscribeInfo" :type="D.ORDER.TYPE.SUBSCRIBE.ID" :isAdmin="true"/>
  </div>
</template>

<script>
import SubscribeDetail from "../general/SubscribeDetail";
import UserOrderControls from '../general/UserOrderControls';
export default {
  name: "SubscribeDetailAdmin",
  props: ["subscribeInfo"],
  components: {SubscribeDetail,UserOrderControls },
  inject: ["D"],
};
</script>

<style lang="less" scoped>
.confirm_order_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirm_button_wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.cancel_reason_option_wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cancel_option_wrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.refund_reason_wrap {
  margin-top: 20px;
}

@media screen and (max-width: 550px) {
  
}
</style>